import { ConversationModel } from '../../../../models/ConversationModel';

type SotrtedConversations = {
  todays: ConversationModel[];
  older: ConversationModel[];
};

const compareDatesByDay = (firstDate: Date, secondDate: Date) => {
  let firstDateApprox = new Date(firstDate);
  firstDateApprox.setHours(0, 0, 0, 0);

  let secondDateAprox = new Date(secondDate);
  secondDateAprox.setHours(0, 0, 0, 0);

  return +firstDateApprox === +secondDateAprox;
};

export const sortConversations = (
  conversations: ConversationModel[]
): SotrtedConversations => {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const weekAgo = new Date();
  weekAgo.setDate(weekAgo.getDate() - 7);

  let todays: ConversationModel[] = [];
  let older: ConversationModel[] = [];

  conversations.forEach((conversation) => {
    if (compareDatesByDay(conversation.lastAppended, today)) {
      todays.push(conversation);
    } else {
      older.push(conversation);
    }
  });

  return {
    todays: todays,
    older: older,
  };
};
