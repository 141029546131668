import { useEffect, useState } from 'react';
import './RulingPanel.css';
import '../../../../styles/highlight.css';
import { RulingModel } from '../../../../models/RulingModel';
import useTextHighlight from '../../../../hooks/useTextHighlight';
import { Box, styled } from '@mui/material';
import { auth } from '../../../../configs/firebaseConfig';
import LoadingWrapper from 'common/loading-wrapper/LoadingWrapper';
import { useTranslation } from 'react-i18next';
import { getRulingText } from '../../../../api/api-interface';

//ruling url examle: https://www.saos.org.pl/api/judgments/491500

type Props = {
  ruling: RulingModel;
  onHtmlTextSet: (htmlText: string) => void;
  htmlText: string | null;
  scrollToFragment: boolean;
};
const RulingPanel = ({
  ruling,
  onHtmlTextSet,
  htmlText,
  scrollToFragment,
}: Props) => {
  const { blockedText } = useTextHighlight();
  // const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [hasScrolled, setHasScrolled] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);


  const StyledBox = styled(Box)(({ theme }) => ({
    userSelect: blockedText ? 'none' : 'text',
    // Disable hyperlinks styling and functionality
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
      cursor: 'text',
      pointerEvents: 'none'
    }
  }));

  useEffect(() => {
    // Fetch only when the parent explicitly passes null, indicating no data yet for this index.
    if (htmlText === null) {
      console.log('useEffect [RulingPanel]: htmlText is null, calling fetchData for ruling:', ruling.url); // DEBUG
      fetchData();
    } else {
      // Log why we are *not* fetching
      const valueType = htmlText === '' ? "'' (empty string)" : (typeof htmlText);
      console.log(`useEffect [RulingPanel]: htmlText is NOT null (${valueType}), skipping fetch for ruling:`, ruling.url); // DEBUG
    }
    // Dependency: Fetch when the specific ruling instance changes OR when the parent signals data is needed (htmlText becomes null).
    // Note: Adding htmlText here means the effect re-runs when data arrives, but the `if` prevents infinite loops.
  }, [ruling, htmlText]);

  const fetchData = async () => {
    setLoading(true);
    console.log('fetchData triggered for ruling:', ruling.url); // DEBUG
    try {
      const token = await auth?.currentUser?.getIdToken();
      // Fetch the raw chunk containing the highlight markers
      const rulingTextRaw = await getRulingText(token || '', ruling.url, ruling.startIndex, ruling.endIndex);

      console.log('Fetched rulingTextRaw:', rulingTextRaw?.substring(0, 200) + '...'); // DEBUG (Log start of raw text)

      if (rulingTextRaw && rulingTextRaw.length > 0) {
        // --- Index Calculation ---
        const rawStartIndex = rulingTextRaw.indexOf('<RUL>');
        const rawEndIndex = rulingTextRaw.indexOf('</RUL>');
        console.log('Raw marker indices:', { rawStartIndex, rawEndIndex }); // DEBUG

        let textStartIndex = 0;
        let textEndIndex = -1; // Default

        let contentHtml = rulingTextRaw;

        if (rawStartIndex !== -1 && rawEndIndex !== -1 && rawEndIndex > rawStartIndex) {
            const highlightMarkerStart = rawStartIndex + 5; // Position after <RUL>
            const highlightMarkerEnd = rawEndIndex;       // Position before </RUL>
            console.log('Raw highlight positions:', { highlightMarkerStart, highlightMarkerEnd }); // DEBUG

            // Remove markers *after* finding positions but *before* conversion
            contentHtml = rulingTextRaw.replace(/<RUL>|<\/RUL>/g, '');
            console.log('contentHtml (after marker removal, start):', contentHtml?.substring(0, 200) + '...'); // DEBUG

            // --- Use V2 converter ---
            textStartIndex = convertRawOffsetToTextOffset_V2(contentHtml, highlightMarkerStart);
            textEndIndex = convertRawOffsetToTextOffset_V2(contentHtml, highlightMarkerEnd);

            console.log('Converted Text indices (V2):', { textStartIndex, textEndIndex }); // DEBUG

        } else {
           console.warn("Highlight markers <RUL>...</RUL> not found or invalid in fetched text.");
           contentHtml = rulingTextRaw.replace(/<RUL>|<\/RUL>/g, ''); // Clean potential partial markers
           textStartIndex = 0;
           textEndIndex = 0; // Ensure no highlight attempt
        }

        // --- HTML Cleaning (Apply to contentHtml) ---
        contentHtml = contentHtml.replace(/<\/?br\s*\/?>/gi, '<br />');
        contentHtml = contentHtml.replace(/(?:<br \/>\s*){2,}/gi, '<br />');
        console.log('contentHtml (after cleaning, start):', contentHtml?.substring(0, 200) + '...'); // DEBUG


        // --- Highlighting ---
        let finalHtml = contentHtml; // Default to cleaned HTML
        if (textEndIndex > textStartIndex) {
             console.log('Attempting highlight...'); // DEBUG
             finalHtml = highlightText(
                 contentHtml,
                 textStartIndex,
                 textEndIndex
             );
             console.log('Highlighting result (start):', finalHtml?.substring(0, 200) + '...'); // DEBUG
        } else {
             console.log('Skipping highlight (invalid indices).'); // DEBUG
        }

        console.log(`Calling onHtmlTextSet with ${finalHtml ? 'content' : 'EMPTY STRING'}. Length: ${finalHtml?.length}`); // DEBUG
        onHtmlTextSet(finalHtml || ''); // Ensure empty string if null/undefined

      } else {
         console.warn('getRulingText returned empty or falsy data.'); // DEBUG
         onHtmlTextSet(''); // Set empty if no text fetched
      }
    } catch (error) {
      console.error('Error fetching or processing ruling text:', error); // DEBUG
      // Provide user feedback - ensure this creates visible HTML
      const errorHtml = `<p style="color: red; padding: 10px;">${t('dashboard:rulingPanel.error', 'Error loading ruling content.')}</p>`;
      console.log('Setting error HTML:', errorHtml); // DEBUG
      onHtmlTextSet(errorHtml);
    } finally {
      setLoading(false);
      console.log('fetchData finished.'); // DEBUG
    }
  };



  // Helper function: Find the nearest scrollable parent
  function getScrollParent(node: HTMLElement | null): HTMLElement | Window | null {
    if (!node) return null;
    const style = window.getComputedStyle(node);
    const overflowY = style.overflowY;
    if ((overflowY === 'auto' || overflowY === 'scroll') && node.scrollHeight > node.clientHeight) {
      return node;
    }
    return getScrollParent(node.parentElement);
  }

  // Helper function: Calculate cumulative offset from document top
  function getCumulativeOffset(el: HTMLElement): number {
    let top: number = 0;
    let currentElement: HTMLElement | null = el;
    while (currentElement) {
      top += currentElement.offsetTop || 0;
      currentElement = currentElement.offsetParent as HTMLElement | null;
    }
    return top;
  }

  useEffect(() => {
    if (!loading && htmlText) {
      const scrollToElement = () => {
        const element = document.getElementById('overlay-span');
        if (element instanceof HTMLElement) {
          const container: HTMLElement | Window | null = getScrollParent(element) || window;
          const offset: number = 114;

          if (container === window) {
            const targetPosition: number = getCumulativeOffset(element) - offset;
            window.scrollTo({ top: targetPosition, behavior: 'smooth' });
          } else if (container instanceof HTMLElement) {
            const containerOffset: number = getCumulativeOffset(container);
            const elementOffset: number = getCumulativeOffset(element);
            const relativeOffset: number = elementOffset - containerOffset;
            const targetScrollTop: number = relativeOffset - offset;
            container.scrollTo({ top: targetScrollTop, behavior: 'smooth' });
          }
        }
      };
      requestAnimationFrame(() => requestAnimationFrame(scrollToElement));
    }
  }, [loading, htmlText]);


  /**
   * Converts a raw character offset within an HTML string to a
   * text content offset (counting only visible characters).
   * @param html The HTML string.
   * @param rawOffset The character offset in the raw HTML string.
   * @returns The corresponding text content offset.
   */
  function convertRawOffsetToTextOffset(html: string, rawOffset: number): number {
    let textCount = 0;
    let inTag = false;
    for (let i = 0; i < html.length && i < rawOffset; i++) {
      const char = html[i];

      if (char === '<') {
        // Basic tag detection, might need refinement for comments, CDATA, etc.
        // Check if it's likely a tag start and not just '<' character
        const nextNonSpace = html.slice(i + 1).trimStart();
        if (nextNonSpace.length > 0 && /^[a-zA-Z\/!]/.test(nextNonSpace[0])) {
          inTag = true;
        }
      }

      if (!inTag) {
        // Only count if it's not whitespace outside a tag OR if it's any character inside a tag
        // We increment text count only for characters *outside* tags.
        textCount++;
      }

      if (char === '>' && inTag) {
        // Basic tag end detection
        inTag = false;
      }

      // More precise tag detection logic might be needed for complex HTML
      // but this covers simple cases like <tag> and <tag attr="value">

    }
      // If the rawOffset itself is inside a tag, textCount will be the count
      // of text characters *before* that tag started.
      return textCount;
  }

  // --- More Robust Tag Skipping Logic (Alternative for convertRawOffsetToTextOffset) ---
  /**
   * Converts a raw character offset within an HTML string to a
   * text content offset (counting only visible characters).
   * More robust tag skipping.
   * @param html The HTML string.
   * @param rawOffset The character offset in the raw HTML string.
   * @returns The corresponding text content offset.
   */
  function convertRawOffsetToTextOffset_V2(html: string, rawOffset: number): number {
    let textCount = 0;
    let i = 0;
    while (i < html.length && i < rawOffset) {
      if (html[i] === '<') {
        // Find the closing '>'
        const tagEnd = html.indexOf('>', i);
        if (tagEnd !== -1 && tagEnd < rawOffset) {
          // Skip the entire tag if it ends before our rawOffset
          i = tagEnd + 1;
          continue;
        } else if (tagEnd !== -1 && tagEnd >= rawOffset) {
          // The rawOffset is inside this tag, stop counting text
          i = rawOffset; // Exit loop
          continue;
        } else {
          // Malformed HTML? Treat '<' as text if no '>' found
          textCount++;
          i++;
        }
      } else {
        // It's a text character
        textCount++;
        i++;
      }
    }
    return textCount;
  }


   /**
    * Highlights text within an HTML string based on text content indices.
    * @param html The HTML string to highlight within.
    * @param textInd1 The starting text content index (inclusive).
    * @param textInd2 The ending text content index (exclusive).
    * @returns HTML string with the specified text range wrapped in highlight spans.
    
    The indices (start_index, end_index) generated and stored by your Python code represent character offsets within the original, raw HTML string (including tags).
    IMPORTANT: It means, that indices can point to positions inside tags.

    The indices (ind1, ind2) expected by your JavaScript highlightText function represent character offsets within the rendered text content (excluding tags).
  */
  function highlightText(html: string, textInd1: number, textInd2: number): string {
    // 1. Validate indices
    if (textInd1 < 0 || textInd2 <= textInd1) {
      console.warn('Invalid text indices for highlighting:', { textInd1, textInd2 });
      return html; // Return original if indices are invalid or length is zero
    }

    let textCount = 0;
    let inTag = false;
    let result = '';
    let spanOpen = false;
    let firstSpanAdded = false; // Track if the ID has been added

    const openSpanTag = (isFirst: boolean): string => {
        const idAttribute = isFirst ? ' id="overlay-span"' : '';
        const className = isFirst ? 'highlight-overlay-anchor' : 'highlight-overlay';
        return `<span${idAttribute} class="${className}">`;
    };
    const closeSpanTag = '</span>';

    for (let i = 0; i < html.length; i++) {
      const char = html[i];

      // --- Tag Detection ---
      if (char === '<') {
        // Basic check: is it followed by a plausible tag character?
        // This avoids treating stray '<' as tags. Add more checks if needed (e.g., for comments <!-- -->)
        const nextChar = html[i+1];
        if (nextChar && (nextChar === '/' || nextChar === '!' || /[a-zA-Z]/.test(nextChar))) {
            if (spanOpen) {
                result += closeSpanTag; // Close span before tag starts
                spanOpen = false;
            }
            inTag = true;
            result += char;
            continue; // Move to next character
        }
        // If not a tag, treat '<' as regular text
      }

      if (inTag && char === '>') {
          inTag = false;
          result += char;
          // Check if we should RE-OPEN the span *after* the tag closes
          if (textCount >= textInd1 && textCount < textInd2 && !spanOpen) {
              result += openSpanTag(!firstSpanAdded);
              if (!firstSpanAdded) firstSpanAdded = true; // Mark ID as added
              spanOpen = true;
          }
          continue; // Move to next character
      }

      // --- Process Character (if not inside a tag) ---
      if (!inTag) {
        // Check if we need to START highlighting
        if (textCount === textInd1 && !spanOpen) {
          result += openSpanTag(!firstSpanAdded);
          if (!firstSpanAdded) firstSpanAdded = true; // Mark ID as added
          spanOpen = true;
        }

        // Check if we need to END highlighting
        if (textCount === textInd2 && spanOpen) {
          result += closeSpanTag;
          spanOpen = false;
        }

        // Add the actual character
        result += char;

        // Increment text count ONLY for non-tag characters
        textCount++;

      } else {
        // Inside a tag, just append the character
        result += char;
      }
    } // End of loop

    // Ensure span is closed if it was still open at the end
    if (spanOpen) {
      result += closeSpanTag;
    }

    return result;
  }

  return (
    <div className="ruling-panel">
      <LoadingWrapper
        isLoading={loading && !htmlText && htmlText !== ''} // Show loading only if truly null/undefined during load
        loadingHeight="300px"
        description={t('dashboard:rulingPanel.loading', 'Ładowanie orzeczenia...')}
      >
        <StyledBox>
          <Box
            sx={{
              marginBottom: '10px',
            }}
          >
            {/* <h3>{ruling.title + ' - ' + t('dashboard:rulingPanel.fullText', 'Pełny tekst orzeczenia')}</h3> */}
          </Box>
          {/* Check explicit states */}
          {!loading && htmlText === '' && (
            <p style={{ padding: '10px', color: 'grey' }}>
              {t('dashboard:rulingPanel.noContent', 'Brak treści do wyświetlenia.')}
            </p>
          )}
          {htmlText && htmlText !== '' && (
            <div dangerouslySetInnerHTML={{ __html: htmlText }} />
          )}
        </StyledBox>
      </LoadingWrapper>
    </div>
  );
};
export default RulingPanel;







