import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useState, useEffect } from 'react';
import fetchDefaultFirebasePrice from 'module-settings/functions/fetchFirebaseDefaults';
import { collection, getDocs, or, query, where } from 'firebase/firestore';
import { firestore } from '../../../configs/firebaseConfig';
import { ROLE_ADMIN, ROLE_LEXERA } from '../../../constants/roles';

export interface FormUser {
  email: string;
  firm: string;
  description: string;
  price: number;
  trialDays: number;
}

interface UserOption {
  id: string;
  email: string;
}

type Props = {
  onClick: (user: FormUser) => void;
  loading: boolean;
  userRole: number | null;
};

const UserWithPriceForm = ({ onClick, loading, userRole }: Props) => {
  const [createdUser, setCreatedUser] = useState<FormUser>({
    email: '',
    firm: '',
    description: '',
    price: 0,
    trialDays: 0,
  });
  const [userOptions, setUserOptions] = useState<UserOption[]>([]);
  const [allUserOptions, setAllUserOptions] = useState<UserOption[]>([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [useDropdown, setUseDropdown] = useState(true);

  useEffect(() => {
    fetchDefaultFirebasePrice().then((defaultParams) => {
      setCreatedUser({
        ...createdUser,
        price: defaultParams.price,
        trialDays: defaultParams.days,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch users based on role
  useEffect(() => {
    if (userRole === ROLE_LEXERA) {
      fetchLexeraUsers();
    } else if (userRole === ROLE_ADMIN) {
      fetchAllUsers();
    }
  }, [userRole]);

  const fetchLexeraUsers = async () => {
    setLoadingUsers(true);
    try {
      const usersQuery = query(
        collection(firestore, 'Users'),
        or(
          where('promoCode', '==', 'lexera'),
          where('promoCode', '==', 'n4zlex')
        )
      );
      
      const querySnapshot = await getDocs(usersQuery);
      const options: UserOption[] = [];
      
      querySnapshot.docs.forEach((doc) => {
        const data = doc.data();
        if (data.email) {
          options.push({
            id: doc.id,
            email: data.email,
          });
        }
      });
      
      setUserOptions(options);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoadingUsers(false);
    }
  };

  const fetchAllUsers = async () => {
    setLoadingUsers(true);
    try {
      const usersQuery = query(collection(firestore, 'Users'));
      
      const querySnapshot = await getDocs(usersQuery);
      const options: UserOption[] = [];
      
      querySnapshot.docs.forEach((doc) => {
        const data = doc.data();
        if (data.email) {
          options.push({
            id: doc.id,
            email: data.email,
          });
        }
      });
      
      setAllUserOptions(options);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoadingUsers(false);
    }
  };

  const handleUserSelect = (email: string) => {
    setCreatedUser({
      ...createdUser,
      email,
    });
  };

  const renderEmailInput = () => {
    // LEXERA role - always use dropdown with filtered users
    if (userRole === ROLE_LEXERA) {
      return (
        <>
          <Typography variant={'body2'}>Wybierz użytkownika *</Typography>
          <FormControl fullWidth size="small" sx={{ marginBottom: '20px' }}>
            <Select
              value={createdUser.email}
              onChange={(e) => handleUserSelect(e.target.value)}
              displayEmpty
              disabled={loadingUsers}
            >
              <MenuItem value="" disabled>
                {loadingUsers ? 'Ładowanie użytkowników...' : 'Wybierz użytkownika'}
              </MenuItem>
              {userOptions.map((user) => (
                <MenuItem key={user.id} value={user.email}>
                  {user.email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      );
    }
    
    // ADMIN role - can choose between dropdown of all users or manual entry
    return (
      <>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Typography variant={'body2'}>Email *</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={useDropdown}
                onChange={(e) => setUseDropdown(e.target.checked)}
                size="small"
              />
            }
            label="Wybierz istniejącego użytkownika"
            labelPlacement="start"
          />
        </Box>
        
        {useDropdown ? (
          <FormControl fullWidth size="small" sx={{ marginBottom: '20px' }}>
            <Select
              value={createdUser.email}
              onChange={(e) => handleUserSelect(e.target.value)}
              displayEmpty
              disabled={loadingUsers}
            >
              <MenuItem value="" disabled>
                {loadingUsers ? 'Ładowanie użytkowników...' : 'Wybierz użytkownika'}
              </MenuItem>
              {allUserOptions.map((user) => (
                <MenuItem key={user.id} value={user.email}>
                  {user.email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <TextField
            size={'small'}
            fullWidth
            sx={{ marginBottom: '20px' }}
            value={createdUser.email}
            onChange={(e) => {
              setCreatedUser({ ...createdUser, email: e.target.value });
            }}
            placeholder="podaj adres email"
          />
        )}
      </>
    );
  };

  return (
    <Box
      width={'400px'}
      height={'550px'}
      padding="30px"
      style={{
        border: '1px solid lightGray',
        borderRadius: '5px',
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      <Box width={'100%'} display={'flex'} justifyContent={'center'}>
        <Typography fontWeight={'600'}>
          {' '}
          Zmodyfikuj cenę / okres triala dla użytkownika
        </Typography>
      </Box>
      <Box
        width={'100%'}
        display={'flex'}
        justifyContent={'center'}
        marginTop={'20px'}
        flexDirection={'column'}
      >
        {renderEmailInput()}

        <Typography variant={'body2'}>Opis</Typography>
        <TextField
          size={'small'}
          fullWidth
          value={createdUser.description}
          sx={{ marginBottom: '10px' }}
          onChange={(e) => {
            setCreatedUser({ ...createdUser, description: e.target.value });
          }}
          placeholder="podaj opis (opcjonalnie)"
        />

        <Typography variant={'body2'}>Cena</Typography>
        <TextField
          size={'small'}
          fullWidth
          value={createdUser.price}
          sx={{ marginBottom: '10px' }}
          onChange={(e) => {
            setCreatedUser({
              ...createdUser,
              price: Number(e.target.value.replace(/\D/g, '')),
            });
          }}
          placeholder="podaj cenę"
        />

        <Typography variant={'body2'}>Dni próbne</Typography>
        <TextField
          size={'small'}
          fullWidth
          value={createdUser.trialDays}
          sx={{ marginBottom: '10px' }}
          onChange={(e) => {
            setCreatedUser({
              ...createdUser,
              trialDays: Number(e.target.value.replace(/\D/g, '')),
            });
          }}
          placeholder="podaj ilość dni próbnych"  
        />
      </Box>
      <Box
        width={'100%'}
        display={'flex'}
        justifyContent={'center'}
        marginTop={'20px'}
      >
        <Button
          disabled={createdUser.email.trim() === ''}
          fullWidth
          variant="contained"
          onClick={() => {
            onClick(createdUser);
          }}
        >
          {loading ? (
            <CircularProgress size={20} color={'inherit'} />
          ) : (
            'Utwórz / aktualizuj dane użytkownika'
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default UserWithPriceForm;
