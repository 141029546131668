import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { blogArticles } from '../../data/blogArticles';
import { useState } from 'react';
import { BlogArticleModel } from '../../../models/BlogArticle';
import ArticleTemplate from 'module-public/components/templates/article-template/ArticleTemplate';
import { PathType, routesManager } from 'routes/routes';

const BlogArticle = () => {
  const location = useLocation();
  const [article, setArticle] = useState<BlogArticleModel | undefined>(
    undefined
  );

  useEffect(() => {
    let art = blogArticles.find(
      (article) => article.url === location.pathname.split('/')[2]
    );

    if (art) {
      setArticle(art);
    } else {
      console.log('Article not found');
    }
  }, [location]);

  return (
    <div>
      <ArticleTemplate
        article={article}
        backLink={routesManager.getPath(PathType.BLOG)}
        backLinkText="Powrót do bloga"
      />
    </div>
  );
};

export default BlogArticle;
