import React from 'react';
import colors from '../../../configs/colorConfig';
import BlogItem from './components/BlogItem';
import { blogArticles } from '../../data/blogArticles';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import style from './styles.module.scss';

/**
 * Page with list of blog articles
 */
export const BlogPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  const sortedBlogArticles = [...blogArticles].sort(
    (a, b) => b.date.getTime() - a.date.getTime()
  );

  return (
    <div>
      <div className={style.mainDiv}>
        <div
          className={`${style.greetingsDiv} ${isMd ? style.smallPadding : style.largePadding}`}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div style={{ maxWidth: '700px' }}>
              <h1
                className={style.title}
                style={{
                  color: colors.primary,
                  marginBottom: '30px',
                }}
              >
                {t('start:blog.helloMessage')}
              </h1>
              <p style={{ fontSize: '18px', textAlign: 'center' }}>
                {t('start:blog.helloDescription')}
              </p>
            </div>
          </div>
          <h1
            className={style.title}
            style={{
              marginTop: '50px',
              color: colors.primary,
            }}
          >
            {t('start:blog.articles')}
          </h1>
          <div className={style.articlesDiv}>
            {sortedBlogArticles.map((article, index) => (
              <BlogItem key={index} article={article} type="blog" />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
