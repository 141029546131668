import { UserStats } from 'module-settings/pages/admin-user-details-page/UserDetailsPanel';
import { useEffect, useState } from 'react';
import { Box, Button, Modal, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { firestore } from '../../../../configs/firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import LoadingWrapper from 'common/loading-wrapper/LoadingWrapper';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  width: '300px',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid lightGray',
  borderRadius: '10px',
  boxShadow: 24,
  p: 2,
};

interface UserTableHeatMapProps {
  stats: UserStats;
  userId: string;
  onSave: () => void;
}

const UserTableHeatMap = ({ stats, userId, onSave }: UserTableHeatMapProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(dayjs());
  const [numberOfEmailsSent, setNumberOfEmailsSent] = useState(0);
  const [numberOfPhoneCalls, setNumberOfPhoneCalls] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState('#fff');
  const [loading, setLoading] = useState(false);
  const [numberOfLinkedinInteractions, setNumberOfLinkedinInteractions] =
    useState(0);

  const handleSave = async () => {
    try {
      setLoading(true);
      const userRef = doc(firestore, 'Users', userId);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        console.error('User document not found');
        return;
      }

      const statsData = {
        date: selectedDate?.toDate() || new Date(),
        numberOfEmailsSent,
        numberOfPhoneCalls,
        numberOfLinkedinInteractions: numberOfLinkedinInteractions,
        lastUpdated: new Date(),
      };

      await updateDoc(userRef, {
        emails_sent_count: numberOfEmailsSent,
        phone_calls_count: numberOfPhoneCalls,
        linkedin_contacts_count: numberOfLinkedinInteractions,
        last_contact_date: selectedDate?.toDate() || new Date(),
        stats: statsData,
      });
      setLoading(false);
      setOpenModal(false);
      onSave();
    } catch (error) {
      console.error('Error saving stats:', error);
    }
  };

  useEffect(() => {
    setNumberOfEmailsSent(stats.numberOfEmailsSent);
    setNumberOfPhoneCalls(stats.numberOfPhoneCalls);
    setNumberOfLinkedinInteractions(stats.numberOfLinkedinInteractions);
    setSelectedDate(stats.lastContact ? dayjs(stats.lastContact) : dayjs());
  }, [stats]);

  const getColor = (h: number) => {
    switch (h) {
      case 1:
        return '#5579C7';
      case 2:
        return '#5579C7';
      case 3:
        return '#B3C6FF';
      case 4:
        return '#98FB98';
      case 5:
        return '#32CD32';
      default:
        return '#fff';
    }
  };

  const getCellHeat = () => {
    let cellHeat = 0;

    if (stats.lastContact) {
      const currentDate = new Date();
      const lastContactDate = new Date(stats.lastContact);
      const timeDifference = currentDate.getTime() - lastContactDate.getTime();
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

      if (daysDifference > 28) {
        cellHeat = 1;
      } else if (daysDifference > 21) {
        cellHeat = 2;
      } else if (daysDifference > 14) {
        cellHeat = 3;
      } else if (daysDifference > 7) {
        cellHeat = 4;
      } else {
        cellHeat = 5;
      }
    } else {
      cellHeat = 1;
    }
    return cellHeat;
  };

  useEffect(() => {
    const h = getCellHeat();
    setBackgroundColor(getColor(h));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stats]);

  return (
    <>
      <div
        onClick={() => {
          setOpenModal(true);
        }}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          cursor: 'pointer',
          backgroundColor: backgroundColor,
        }}
      >
        <p style={{ width: '100%', textAlign: 'center' }}>
          {stats.lastContact
            ? dayjs(new Date(stats.lastContact)).format('DD.MM.YYYY')
            : 'brak danych'}
        </p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}
        >
          <p>{stats.numberOfEmailsSent}</p>
          <p>{stats.numberOfPhoneCalls}</p>
          <p>{stats.numberOfLinkedinInteractions}</p>
        </div>
      </div>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={modalStyle}>
          <LoadingWrapper
            isLoading={loading}
            description="Zapisywanie danych..."
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Data ostatniego kontaktu"
                value={selectedDate}
                onChange={(value) => {
                  if (value) {
                    setSelectedDate(value);
                  }
                }}
                format="DD.MM.YYYY"
                slotProps={{ textField: { fullWidth: true, size: 'small' } }}
              />
            </LocalizationProvider>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                paddingTop: '30px',
              }}
            >
              <TextField
                size="small"
                label="email"
                value={numberOfEmailsSent}
                onChange={(e) => setNumberOfEmailsSent(Number(e.target.value))}
              />
              <TextField
                size="small"
                label="telefon"
                value={numberOfPhoneCalls}
                onChange={(e) => setNumberOfPhoneCalls(Number(e.target.value))}
              />
              <TextField
                size="small"
                label="linkedin"
                value={numberOfLinkedinInteractions}
                onChange={(e) =>
                  setNumberOfLinkedinInteractions(Number(e.target.value))
                }
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                paddingTop: '30px',
                justifyContent: 'center',
              }}
            >
              <Button variant="contained" color="primary" onClick={handleSave}>
                Zapisz
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpenModal(false)}
              >
                Anuluj
              </Button>
            </div>
          </LoadingWrapper>
        </Box>
      </Modal>
    </>
  );
};

export default UserTableHeatMap;
