import * as i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

(i18n as any).use(initReactI18next).init({
  resources: {
    pl: {
      instruction: require('./locales/pl/instruction.json'),
      dashboard: require('./locales/pl/dashboard.json'),
      start: require('./locales/pl/start.json'),
      settings: require('./locales/pl/settings.json'),
    },
    fr: {
      instruction: require('./locales/fr/instruction.json'),
      dashboard: require('./locales/fr/dashboard.json'),
      start: require('./locales/fr/start.json'),
      settings: require('./locales/fr/settings.json'),
    },
  },
  lng: process.env.REACT_APP_LANGUAGE,
  fallbackLng: 'pl',
  interpolation: {
    escapeValue: false,
  },
});
