import React, { useEffect, useState, useRef } from 'react';
import Sketch from 'react-p5';
import colors from '../../configs/colorConfig';

function hexToRGB(hex: string) {
  hex = hex.replace(/^#/, '');
  if (hex.length === 3) {
    hex = hex.split('').map((char) => char + char).join('');
  }
  if (hex.length !== 6) {
    throw new Error('Invalid hex color format');
  }
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return [r, g, b];
}

declare global {
  interface Window {
    CCapture: any;
  }
}

const P5Animation = () => {
  const [themeBackgroundStartPrimary, setThemeBackgroundStartPrimary] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [isProcessingGif, setIsProcessingGif] = useState(false);
  const [progressMessage, setProgressMessage] = useState('');
  const capturer = useRef<any>(null);
  const recordingFramesRef = useRef<number>(0);
  const p5Ref = useRef<any>(null);
  const canvasRef = useRef<HTMLDivElement>(null);
  
  // Set total frames for looping animation
  const totalFrames = 100;
  
  // Add cycle length to ensure looping
  const cycleLength = 2 * Math.PI; // One complete cycle for sine/cosine functions
  
  // Use ref for time to maintain value between renders
  const timeRef = useRef<number>(0);

  useEffect(() => {
    const updateCssValue = () => {
      const cssValue = getComputedStyle(document.documentElement)
        .getPropertyValue('--background-start-primary')
        .trim();
      setThemeBackgroundStartPrimary(cssValue);
    };

    updateCssValue();
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'data-theme') {
          updateCssValue();
        }
      });
    });
    observer.observe(document.documentElement, { attributes: true });
    return () => observer.disconnect();
  }, []);

  const startRecording = () => {
    if (isRecording || isProcessingGif) {
      console.warn('Cannot start recording - already recording or processing');
      return;
    }

    if (p5Ref.current && window.CCapture) {
      try {
        console.log('Initializing CCapture for looping animation');
        capturer.current = new window.CCapture({
          format: 'gif',
          framerate: 10, //30,
          verbose: true,
          quality: 10, //90,
          transparent: true, // Enable transparency for GIF
          name: 'p5-animation-loop',
          workersPath: '/ccapture/', // Using local workers path instead of unpkg
          onProgress: (progress: number) => {
            console.log(`GIF Processing: ${Math.round(progress * 100)}%`);
            setProgressMessage(`Processing: ${Math.round(progress * 100)}%`);
          },
        });

        // Reset time to 0 when starting recording to ensure proper loop
        timeRef.current = 0;
        recordingFramesRef.current = 0;
        setIsRecording(true);
        setProgressMessage(`Recording: 0/${totalFrames} frames`);
        capturer.current.start();
        console.log('Recording started with transparency enabled');
      } catch (error) {
        console.error('Error starting recording:', error);
        setIsRecording(false);
        setProgressMessage('Error starting recording');
        setTimeout(() => setProgressMessage(''), 3000);
      }
    } else {
      console.warn('P5 or CCapture not available');
    }
  };

  const stopRecording = () => {
    if (isRecording && capturer.current) {
      setIsRecording(false);
      setIsProcessingGif(true);
      setProgressMessage(`Processing looping GIF with transparency (${totalFrames} frames)...`);

      setTimeout(() => {
        try {
          console.log('Stopping capturer');
          capturer.current.stop();

          console.log('Saving transparent GIF');
          capturer.current.save((blob: Blob) => {
            if (blob) {
              console.log('Transparent GIF blob received, initiating download');
              const url = URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'p5-animation-loop-transparent.gif';
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              URL.revokeObjectURL(url);

              setIsProcessingGif(false);
              setProgressMessage('Transparent looping GIF downloaded');
              setTimeout(() => setProgressMessage(''), 3000);
            } else {
              throw new Error('No blob returned from capturer.save');
            }
            capturer.current = null; // Clean up
          });
        } catch (error) {
          console.error('Error during GIF processing:', error);
          setIsProcessingGif(false);
          setProgressMessage('Error processing GIF');
          setTimeout(() => setProgressMessage(''), 3000);
          capturer.current = null;
        }
      }, 100);
    }
  };

  let time = 0;
  const gridSize = 40;
  const gridSpacing = 16;
  const waveAmplitude = 35;
  const waveFrequency = 0.05;
  const COLOR_START: number[] = hexToRGB(colors.primary);
  const COLOR_END: number[] = themeBackgroundStartPrimary
    ? hexToRGB(themeBackgroundStartPrimary)
    : [255, 255, 255];

  const setup = (p5: any, canvasParentRef: any) => {
    p5Ref.current = p5;
    const canvas = p5.createCanvas(p5.windowWidth, p5.windowHeight, p5.WEBGL).parent(canvasParentRef);
    
    // Enable transparency for the canvas
    const gl = canvas.GL;
    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    
    p5.noFill();
    p5.smooth();
    p5.frameRate(30);
  };

  const draw = (p5: any) => {
    // Replace the clear with a background using the theme color
    if (themeBackgroundStartPrimary) {
      const bgColor = hexToRGB(themeBackgroundStartPrimary);
      p5.background(bgColor[0], bgColor[1], bgColor[2]);
    } else {
      p5.clear(0, 0, 0, 0);
    }
    p5.noLights();

    p5.push();
    p5.rotateY(timeRef.current * 0.1);
    p5.rotateX(p5.PI / 2);
    p5.strokeWeight(1.2);

    const offset = (gridSize - 1) * gridSpacing / 2;
    const maxDistance = p5.dist(offset, offset, 0, 0) * 0.6;

    let heights: number[][] = [];
    let colorsGrid: number[][][] = [];
    for (let x = 0; x < gridSize; x++) {
      heights[x] = [];
      colorsGrid[x] = [];
      const px = x * gridSpacing - offset;
      for (let z = 0; z < gridSize; z++) {
        const pz = z * gridSpacing - offset;
        const y1 = p5.sin(px * waveFrequency + timeRef.current * 1.5) * waveAmplitude;
        const y2 = p5.sin(pz * waveFrequency + timeRef.current * 0.8) * waveAmplitude * 0.6;
        const y3 = p5.cos((px + pz) * waveFrequency * 0.3 + timeRef.current * 0.7) * waveAmplitude * 0.4;
        heights[x][z] = y1 + y2 + y3;

        const distance = p5.dist(px, pz, 0, 0);
        const lerpAmount = p5.map(distance, 0, maxDistance, 0, 1);
        const clampedLerpAmount = p5.constrain(lerpAmount, 0, 1);
        colorsGrid[x][z] = [
          p5.lerp(COLOR_START[0], COLOR_END[0], clampedLerpAmount),
          p5.lerp(COLOR_START[1], COLOR_END[1], clampedLerpAmount),
          p5.lerp(COLOR_START[2], COLOR_END[2], clampedLerpAmount),
        ];
      }
    }

    for (let x = 0; x < gridSize - 1; x++) {
      for (let z = 0; z < gridSize - 1; z++) {
        const px = x * gridSpacing - offset;
        const pz = z * gridSpacing - offset;
        const py = heights[x][z];
        const color = colorsGrid[x][z];

        p5.stroke(color[0], color[1], color[2]);
        const nx = (x + 1) * gridSpacing - offset;
        const nyRight = heights[x + 1][z];
        p5.line(px, py, pz, nx, nyRight, pz);
        const nz = (z + 1) * gridSpacing - offset;
        const nyBottom = heights[x][z + 1];
        p5.line(px, py, pz, px, nyBottom, nz);
      }
    }

    p5.pop();

    if (isRecording) {
      // During recording, increment time precisely for exact looping
      const timeIncrement = cycleLength / totalFrames;
      timeRef.current = (timeRef.current + timeIncrement) % cycleLength;
    } else {
      // During normal playback, use delta time for smooth animation
      timeRef.current = (timeRef.current + p5.deltaTime * 0.0012) % cycleLength;
    }

    if (isRecording && capturer.current) {
      recordingFramesRef.current += 1;
      console.log(`Capturing frame ${recordingFramesRef.current}/${totalFrames}`);
      setProgressMessage(`Recording: ${recordingFramesRef.current}/${totalFrames} frames`);

      // Use p5.canvas instead of document.getElementById
      try {
        capturer.current.capture(p5.canvas);
      } catch (error) {
        console.error('Error capturing frame:', error);
      }

      if (recordingFramesRef.current >= totalFrames) {
        console.log(`Reached ${totalFrames} frames, stopping recording`);
        stopRecording();
      }
    }
  };

  const windowResized = (p5: any) => {
    p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
  };

  return (
    <div ref={canvasRef} style={{ position: 'relative' }}>
      <Sketch setup={setup} draw={draw} windowResized={windowResized} />
      {typeof window.CCapture !== 'undefined' && (
        <div style={{
          position: 'absolute',
          bottom: '20px',
          right: '20px',
          zIndex: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          gap: '10px',
        }}>
          {progressMessage && (
            <div style={{
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
              padding: '5px 10px',
              borderRadius: '4px',
              fontSize: '14px',
            }}>
              {progressMessage}
            </div>
          )}
          {!isRecording && !isProcessingGif ? (
            <button
              onClick={startRecording}
              style={{
                padding: '10px 20px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
            >
              Record Looping GIF ({totalFrames} frames)
            </button>
          ) : isRecording ? (
            <button
              onClick={stopRecording}
              style={{
                padding: '10px 20px',
                backgroundColor: '#f44336',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
            >
              Stop Recording
            </button>
          ) : (
            <button
              disabled
              style={{
                padding: '10px 20px',
                backgroundColor: '#9e9e9e',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'not-allowed',
                fontWeight: 'bold',
              }}
            >
              Processing...
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default P5Animation;