import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import styles from './styles.module.scss';
import SingleLineSchema from './components/SingleLineSchema';
import ParallelSchema from './components/ParallelSchema';

const DescriptionSchema: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <div
      style={{
        background: 'transparent',
      }}
    >
      <div className={styles.container}>
      <svg className={styles.svgImg} viewBox="0 0 500 50" width="100%">
        <defs>
          <linearGradient id="pathGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#915ac2" />
            <stop offset="20%" stopColor="#915ac2" />
            <stop offset="40%" stopColor="#7a4baf" />
            <stop offset="60%" stopColor="#5a3baa" />
            <stop offset="80%" stopColor="#3a2ba5" />
            <stop offset="100%" stopColor="blue" />
          </linearGradient>
        </defs>
        <path d="M0,50 L0,0 C100,70 350,0 500,15 L500,50 Z" fill="url(#pathGradient)" />
      </svg>
        <div className={styles.container2}>
          {isSmallScreen ? (
            <SingleLineSchema />
          ) : (
            <ParallelSchema
              isSmallScreen={isSmallScreen}
              isMediumScreen={isMediumScreen}
            />
          )}

          <svg
            className={styles.svgImg2}
            viewBox="0 0 500 50"
            width="100%"
            style={{
              transform: 'rotate(180deg)',
              position: 'absolute',
              bottom: -2,
            }}
          >
            <path
              d="M0,0 C150,50 350,0 500,15 L500,0 L0,0 Z"
              style={{ fill: 'var(--background-start-primary)' }}
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default DescriptionSchema;
