import { CircularProgress, Grid, TextFieldProps } from '@mui/material';
import { Button, TextField, Box } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, firestore } from '../../../configs/firebaseConfig';
import colors from '../../../configs/colorConfig';
import AuthenticationBox from 'module-public/components/molecules/authentication-box/AuthenticationBox';
import {
  collection,
  doc,
  getDocs,
  updateDoc,
  getDoc,
  deleteDoc,
  setDoc,
} from 'firebase/firestore';
import { getUidByEmail } from 'api/get-uid';

const LEXERA_DAYS = 7;
const N4ZLEX_DAYS = 5
;
const FormField = (props: TextFieldProps) => (
  <TextField
    {...props}
    margin="normal"
    required={props.required ?? true}
    fullWidth
  >
    {props.children}
  </TextField>
);

type Props = {
  email: string | null;
};
const UserSetupPanelGoogle = ({ email }: Props) => {
  const [isLoadingCredentials, setIsLoadingCredentials] = useState(false);
  const [credentials, setCredentials] = useState<{
    firstName: string;
    lastName: string;
    promoCode: string;
  }>({
    firstName: '',
    lastName: '',
    promoCode: '',
  });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();

  const handleConfigSetup = async (e: any) => {
    e.preventDefault();
    setIsLoadingCredentials(true);
    setErrorMessage('');

    await updateFirebaseUser();
    navigate('/czat');
  };

  let endTrialDate: Date | null = null;
  const updateFirebaseUser = async () => {
    const email = auth.currentUser?.email;
    const uid = await getUidByEmail('Users', email);
    const customerId = await getUidByEmail('Customers', email);

    if (
      credentials.promoCode &&
      (credentials.promoCode.toLowerCase() === 'lexera' ||
        credentials.promoCode.toLowerCase() === 'n4zlex')
    ) {
      const hyperparametersRef = collection(firestore, 'Hyperparameters');
      const hyperparametersDoc = await getDocs(hyperparametersRef);
      const hyperparametersData = hyperparametersDoc.docs
        .find((doc) => doc.id === 'default')
        ?.data();
      // default:
      var trialDays = hyperparametersData?.default_trial_days;
      const currentDate = new Date();
      endTrialDate = new Date(currentDate);

      // overwriting, using promo codes:
      trialDays =
        credentials.promoCode.toLowerCase() === 'lexera'
          ? LEXERA_DAYS
          : credentials.promoCode.toLowerCase() === 'n4zlex'
            ? N4ZLEX_DAYS
            : trialDays;
      endTrialDate.setDate(currentDate.getDate() + trialDays);
    }

    if (uid) {
      const userRef = doc(firestore, 'Users', uid);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data();

      const updateData: { [key: string]: any } = {};

      if (credentials.firstName?.trim()) {
        updateData.firstName = credentials.firstName;
      }
      if (credentials.lastName?.trim()) {
        updateData.lastName = credentials.lastName;
      }
      if (credentials.promoCode) {
        updateData.promoCode = credentials.promoCode.toLowerCase();
      }
      if (endTrialDate && !userData?.trial_ended_at) {
        updateData.trial_ended_at = endTrialDate;
      }

      if (Object.keys(updateData).length > 0 && uid === customerId) {
        await updateDoc(userRef, updateData);
      } else if (customerId && uid !== customerId) {
        const margeData = { ...userData, ...updateData };
        await deleteDoc(userRef);
        const userDocRef = doc(firestore, 'Users', customerId);
        await setDoc(userDocRef, margeData);
      }
    }
  };

  return (
    <AuthenticationBox title="Podaj dane" errorMessage={errorMessage}>
      <Box
        sx={{
          marginTop: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box>
          <FormField
            label="Imię"
            required={false}
            value={credentials.firstName}
            onChange={(e) =>
              setCredentials({ ...credentials, firstName: e.target.value })
            }
          />
          <FormField
            label="Nazwisko"
            value={credentials.lastName}
            required={false}
            onChange={(e) =>
              setCredentials({ ...credentials, lastName: e.target.value })
            }
          />

          <FormField
            required={false}
            label="Kod polecającego"
            value={credentials.promoCode}
            onChange={(e) =>
              setCredentials({ ...credentials, promoCode: e.target.value })
            }
          />

          <Grid container>
            <Grid item xs>
              {isLoadingCredentials ? (
                <Grid
                  container
                  width={'100%'}
                  marginTop={'10px'}
                  height={'40px'}
                  display={'flex'}
                  justifyContent={'center'}
                >
                  <CircularProgress size={'22px'} sx={{ margin: 'auto' }} />
                </Grid>
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleConfigSetup}
                  sx={{
                    backgroundColor: colors.primary,
                    mt: 3,
                    height: '40px',
                  }}
                >
                  Kontynuuj
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </AuthenticationBox>
  );
};

export default UserSetupPanelGoogle;
