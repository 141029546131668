import { useContext, useEffect, useRef, useState } from 'react';
import Chat from '../chat-feed/components/chat/Chat';
import { DocumentModel } from 'module-private/models/DocumentModel';
import QuestionTextField from '../question-text-field/QuestionTextField';
import { useNavigate, useParams } from 'react-router-dom';
import { auth } from 'configs/firebaseConfig';
import DocumentService, { ModifyOutput } from '../../../../api/documentService';
import { StatusEnum } from '../document-display/components/LoadingDocumentPanel';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'context/Auth';
import { getCssVariable } from 'styles/getVariables';
import styles from './styles.module.scss';
import { styled, Typography, TypographyProps } from '@mui/material';
import SuggestionQuestionsDocument from './components/suggestion-questions-document/SuggestionQuestionsDocument';
import FileUploadModal from '../file-upload-modal/FileUploadModal';
import { UserModel } from 'models/UserModel';

const TypographySub = styled(Typography)<TypographyProps>({
  fontSize: '14px',
  margin: 'auto',
});

type Props = {
  chosenDocument: DocumentModel | null;
  onDocumentContentChange: (content: string) => void;
  onConversationStarted: () => void;
  onNewQuestion: () => void;
  onStatusChange: (status: StatusEnum | null) => void;
  onLoadingStateChange: (loading: boolean) => void;
  onPartOfTextModified: (output: ModifyOutput) => void;
  onLadingTextChange: (text: string) => void;
};

const ChatFeedDocument = ({
  chosenDocument,
  onDocumentContentChange,
  onConversationStarted,
  onNewQuestion,
  onStatusChange,
  onPartOfTextModified,
  onLoadingStateChange,
  onLadingTextChange,
}: Props) => {
  const textFieldRef = useRef<HTMLInputElement>(null);
  const chatStreamResponse = useRef<string>('');
  const [listOfQuestions, setListOfQuestions] = useState<string[]>([]);
  const [listOfResponses, setListOfResponses] = useState<string[]>([]);
  const [currentResponse, setCurrentResponse] = useState<string>('');
  const [uploadedFileId, setUploadedFileId] = useState<string>('');
  const [conversationId, setConverstionId] = useState<string>('');
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [responseIsRendering, setResponseIsRendering] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const navigate = useNavigate();
  const { id } = useParams();
  const { currentUser } = useContext(AuthContext);
  const [isDraggingFile, setIsDraggingFile] = useState<boolean>(false);
  const dragCounter = useRef(0);

  const initializeNewQuestion = async (questionText: string) => {
    if (!auth.currentUser) return null;

    const token = await auth.currentUser.getIdToken();
    setListOfQuestions((prev) => [...prev, questionText]);

    return token;
  };

  const getReplyIdAndNotify = async (
    token: string,
    questionText: string,
    file?: File
  ) => {
    let replyId = '';

    if (!id && !file) {
      // Generate a new document without a file
      onLadingTextChange('Generowanie dokumentu');
      replyId = await DocumentService.generateDocument(
        token,
        questionText,
        true
      );
    } else if (file && uploadedFileId) {
      // Modify an uploaded document
      onLadingTextChange('Generowanie dokumentu');
      replyId = await DocumentService.generateDocument(
        token,
        questionText,
        false,
        uploadedFileId
      );
    } else if (id) {
      // Generate based on existing document ID
      onLadingTextChange('Generowanie dokumentu');
      replyId = await DocumentService.generateDocument(
        token,
        questionText,
        true,
        id
      );
    } else {
      console.error('Invalid state: No document ID or file');
      throw new Error('Invalid state: No document ID or file');
    }
    return replyId;
  };

  const handleAskQuestion = async (questionText: string) => {
    onNewQuestion();
    if (listOfResponses.length < listOfQuestions.length) {
      setListOfResponses((prev) => [...prev, currentResponse]);
    }
    setCurrentResponse('');

    const token = await initializeNewQuestion(questionText);
    if (!token) {
      throw new Error('Failed to get authentication token');
    }

    const replyId = await getReplyIdAndNotify(
      token,
      questionText,
      selectedFile
    );

    // Only poll status endpoint when the user asks a question from scratch
    // (not when they've already uploaded a file)
    if (!id && !selectedFile) {
      onConversationStarted();
      console.log('============1');
      // This is a new question without a file, show status animation
      let localStatus: string = 's1_generation';
      let statusResponseDone: boolean = false;

      while (statusResponseDone !== true) {
        const statusResponse = await DocumentService.getDocumentStatus(
          token,
          replyId
        );
        localStatus = statusResponse.status;
        statusResponseDone = statusResponse.done ?? false;
        onStatusChange(localStatus as StatusEnum);
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }

    if (!replyId) {
      throw new Error('Failed to get reply ID');
    }

    // Only call streamGenerateDocumentContent when we're not dealing with an uploaded file
    // or when we're asking a question about an uploaded file
    if (!selectedFile && !id) {
      await DocumentService.streamGenerateDocumentContent(
        token,
        replyId,
        (chunk: string) => {
          onDocumentContentChange(chunk);
        }
      );
    } else if (selectedFile || id) {
      onLoadingStateChange(true);
      onLadingTextChange('Modyfikowanie dokumentu');
      const output: ModifyOutput = await DocumentService.modifyDocument(
        token,
        questionText,
        replyId
      );
      onPartOfTextModified(output);
      onLoadingStateChange(false);
    }

    await streamResponse(token, replyId);

    if (listOfResponses.length === 0 && id == null) {
      navigate(replyId);
    }
  };

  useEffect(() => {
    if (chosenDocument && chosenDocument.messages) {
      setListOfQuestions(
        chosenDocument.messages
          .filter((m) => m.role === 'user')
          .map((message) => message.content)
      );
      console.log(
        'chosenDocument',
        chosenDocument.messages.map((message) =>
          message.content.substring(0, 10)
        )
      );
      setListOfResponses(
        chosenDocument.messages
          .filter((m) => m.role === 'assistant')
          .map((message) => message.content)
      );
    } else {
      // If the chosen document exists but has no messages, initialize with empty arrays
      // This can happen when a new document is uploaded
      setListOfQuestions([]);
      setListOfResponses([]);
    }
  }, [chosenDocument]);

  useEffect(() => {
    if (id) {
      //fetchConversation(chatId);
    } else {
      setListOfQuestions([]);
      setListOfResponses([]);
      setConverstionId('');
      setSelectedFile(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const streamResponse = async (token: string, replyId: string) => {
    let currentText = '';

    try {
      await DocumentService.streamDocumentConversation(
        token,
        replyId,
        (chunk) => {
          const formattedChunk = chunk.replace(/\\n/g, '\n');
          currentText += formattedChunk;
          setCurrentResponse((prev) => prev + formattedChunk);
          chatStreamResponse.current = currentText;
        }
      );
    } catch (error) {
      console.error('Error streaming response:', error);
    }
  };

  const handleFileSelected = async (file: File) => {
    if (!file) return;

    const isDocx = file.name.toLowerCase().endsWith('.docx');
    const isDoc = file.name.toLowerCase().endsWith('.doc');
    const isPdf = file.name.toLowerCase().endsWith('.pdf');

    console.log('file', file);
    if (isDocx || isDoc || isPdf) {
      const token = await auth.currentUser?.getIdToken();
      if (!token) {
        throw new Error('Failed to get authentication token');
      }

      setSelectedFile(file);
      setFileLoading(true);

      try {
        // Save the document ID and formatted content returned from the upload
        const uploadResult = await DocumentService.uploadDocument(file, token);
        console.log(
          'File uploaded successfully, document ID:',
          uploadResult.documentId
        );
        setUploadedFileId(uploadResult.documentId);

        // Clear previous states
        setListOfQuestions([]);
        setListOfResponses([]);

        // Pass the formatted content to the document display
        if (uploadResult.formattedContent) {
          // Calling onNewQuestion to clear any previous content
          onNewQuestion();

          // Set the status to 'done' to avoid showing the loading animation
          console.log('============2');

          onStatusChange(null);

          // Create a minimal delay to ensure proper state updates
          setTimeout(() => {
            // Use the special prefix to directly set content instead of appending
            onDocumentContentChange('!DIRECT!' + uploadResult.formattedContent);
          }, 50);
        }

        // Navigate to the new document
        navigate(uploadResult.documentId);
      } catch (error) {
        console.error('Error uploading file:', error);
        // Optionally add error handling here (show error message, etc.)
      } finally {
        setFileLoading(false);
      }

      // Set focus to the text field
      if (textFieldRef.current) {
        textFieldRef.current.focus();
      }
    } else {
      // If it's not a supported document, you could show an error message
      console.warn('Unsupported file type:', file.type);
      // You could add a notification here if you want
    }
  };

  const hideTimeout = useRef<NodeJS.Timeout | null>(null);
  // Use useEffect to handle document-level drag events
  useEffect(() => {
    const handleDragIn = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter.current++;
      if (e.dataTransfer?.types && e.dataTransfer.types.includes('Files')) {
        if (
          !responseIsRendering &&
          (currentUser?.activeTrial || currentUser?.activeSubscription)
        ) {
          setIsDraggingFile(true);
        } else {
        }
      }
    };

    const handleDragOut = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter.current--;
      if (dragCounter.current === 0) {
        if (hideTimeout.current) {
          clearTimeout(hideTimeout.current);
        }
        hideTimeout.current = setTimeout(() => {
          setIsDraggingFile(false);
        }, 100);
      }
    };

    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer) {
        e.dataTransfer.dropEffect = 'copy';
      }
      if (e.dataTransfer?.types && e.dataTransfer.types.includes('Files')) {
        if (
          !responseIsRendering &&
          currentUser?.activeTrial !== false &&
          currentUser?.activeSubscription !== false
        ) {
          setIsDraggingFile(true);
        }
      }
    };

    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter.current = 0;
      if (hideTimeout.current) {
        clearTimeout(hideTimeout.current);
      }
      if (
        !responseIsRendering &&
        currentUser?.activeTrial !== false &&
        currentUser?.activeSubscription !== false
      ) {
        if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
          const file = e.dataTransfer.files[0];
          console.log('FILE DROPPED:', file.name, file.type);
          handleFileSelected(file);
        }
      }
    };

    window.addEventListener('dragenter', handleDragIn);
    window.addEventListener('dragleave', handleDragOut);
    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('drop', handleDrop);

    return () => {
      window.removeEventListener('dragenter', handleDragIn);
      window.removeEventListener('dragleave', handleDragOut);
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('drop', handleDrop);
      if (hideTimeout.current) {
        clearTimeout(hideTimeout.current);
      }
    };
  }, [responseIsRendering, currentUser as UserModel | null]);

  return (
    <div className={styles.container}>
      <div className={styles.chatContainer}>
        <p style={{ margin: '10px', width: '100%', textAlign: 'center' }}>
          {t('dashboard:chatFeedDocument.askQuestion')}
          <br />
          {t('dashboard:chatFeedDocument.askQuestion2')}
        </p>
        <Chat
          listOfQuestions={listOfQuestions}
          listOfResponses={listOfResponses}
          currentResponse={currentResponse}
          disableQuestionClick={true}
        />
      </div>
      {currentUser?.activeTrial === false &&
        currentUser?.activeSubscription === false && (
          <div className={styles.infoContainer}>
            <div className={styles.infoTextContainer}>
              <TypographySub sx={{ color: getCssVariable('--text-primary') }}>
                {t('dashboard:chat.subscriptionExpiredMessage')}
              </TypographySub>
            </div>
          </div>
        )}
      {listOfQuestions.length === 0 && (
        <SuggestionQuestionsDocument onQuestionClick={handleAskQuestion} />
      )}
      <div style={{ padding: '20px' }}>
        <QuestionTextField
          uploadedFileName={selectedFile?.name}
          fileIsUploading={fileLoading}
          onFiledDelete={() => setSelectedFile(undefined)}
          data-testid="ask-question-button"
          textfieldProps={{
            inputRef: textFieldRef,
          }}
          disabled={
            responseIsRendering ||
            (currentUser?.activeTrial === false &&
              currentUser?.activeSubscription === false)
          }
          onAskQuestion={handleAskQuestion}
          onFileSelected={handleFileSelected}
        />
      </div>
      <FileUploadModal
        isOpen={isDraggingFile}
        onClose={() => setIsDraggingFile(false)}
        onFileDropped={handleFileSelected}
      />
    </div>
  );
};

export default ChatFeedDocument;
