import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Tooltip,
} from '@mui/material';
import { SidebarListOfConversations } from './SidebarListOfConverstion';
import HistoryIcon from '@mui/icons-material/History';
import { ConversationModel } from 'models/ConversationModel';
import { deleteDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { getRoutePath, PathType } from 'routes/routes';
import { firestore } from 'configs/firebaseConfig';
import { useTranslation } from 'react-i18next';

type Props = {
  conversationList: ConversationModel[];
  loading: boolean;
  onDeleteClick: (conversation: ConversationModel) => void;
};
const HistoryAccordion = ({
  conversationList,
  loading,
  onDeleteClick,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleClick = (conversation: ConversationModel) => {
    const route = getRoutePath(PathType.CHAT_ID, conversation.id);
    navigate(route);
  };

  const handleDeleteClick = (conversation: ConversationModel) => {
    const docRef = doc(firestore, 'Conversations', conversation.id);
    deleteDoc(docRef)
      .then(() => {
        onDeleteClick(conversation);
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <Accordion
      sx={{
        backgroundColor: 'transparent',
        '&.Mui-expanded': {
          margin: '0',
        },
      }}
      defaultExpanded={conversationList.length > 0}
    >
      <AccordionSummary
        sx={{
          height: '40px',
          minHeight: '40px !important',
          '& .MuiAccordionSummary-content': {
            margin: '0 !important',
          },
        }}
      >
        <Tooltip title={t('dashboard:sidebar.history')} placement="right">
          <Box px={'18px'} display={'flex'} justifyContent={'center'}>
            <IconButton onClick={() => {}}>
              <HistoryIcon sx={{ color: 'white' }} />
            </IconButton>
          </Box>
        </Tooltip>
      </AccordionSummary>
      <AccordionDetails>
        <List sx={{ paddingLeft: '0px', paddingTop: '0px' }}>
          <ListItem
            key={'Konwersacje'}
            disablePadding
            sx={{
              paddingLeft: '0px',
              margin: '2px',
              display: 'block',
            }}
          >
            <>
              {loading ? (
                <Box display={'flex'} justifyContent={'center'}>
                  <CircularProgress sx={{ color: 'white' }} size={'22px'} />
                </Box>
              ) : (
                <SidebarListOfConversations
                  conversationList={conversationList}
                  onDeleteClick={(c) => {
                    handleDeleteClick(c);
                  }}
                  onClick={(c) => {
                    handleClick(c);
                  }}
                />
              )}
            </>
          </ListItem>
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default HistoryAccordion;
