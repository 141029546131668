import { LawModel, lawFromMap } from '../models/LawModel';
import { RulingModel, rulingFromMap } from '../models/RulingModel';
import axios from './axios';

/**
 * NOTE: All document-related API calls have been migrated to DocumentService.
 * The original functions have been commented out with DEPRECATED comments.
 * Please use the methods in DocumentService (in src/api/documentService.ts) for 
 * all document-related operations.
 */

export const getConversationId = async (
  token: string,
  question: string,
  conversationId?: string | undefined
): Promise<string> => {
  let output = '';

  let query = {
    query: {
      conversation_id: conversationId,
      user_message: question,
    },
  };

  await axios
    .post('interface', query, {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: '*',
        'content-type': 'application/json',
      },
    })
    .then((response) => {
      output = response.data.conversation_id;
    });
  return output;
};

export const getArticlesAsSources = async (
  token: string,
  conversationId: string
): Promise<LawModel[]> => {
  let sources: LawModel[] = [];
  await axios
    .get(`interface/articles/${conversationId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: '*',
        'content-type': 'application/json',
      },
    })
    .then((response) => {
      if (response.data.articles) {
        response.data.articles.forEach((model: any) => {
          let law: LawModel = lawFromMap(model);
          law.rulings = [];
          law.rulingsLoading = true;
          sources.push(law);
        });
      }
    });
  return sources;
};

export const getRulingsAsSources = async (
  token: string,
  conversationId: string,
  article: LawModel
): Promise<RulingModel[]> => {
  let sources: RulingModel[] = [];
  console.log('eeee');

  if (article.pathJson) {
    await axios
      .get(
        `interface/rulings/${conversationId}?path_json=${encodeURIComponent(
          article.pathJson
        )}&act_unified_url=${encodeURIComponent(article.url || '')}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            accept: '*',
            'content-type': 'application/json',
          },
        }
      )
      .then((response) => {
        if (response.data.rulings) {
          response.data.rulings.forEach((model: any) => {
            let ruling: RulingModel = rulingFromMap(model);
            sources.push(ruling);
          });
        }
      });
  }
  return sources;
};

export const getRandomRulings = async (
  conversationId: string | undefined,
  token: string
) => {
  if (!conversationId) {
    return [];
  }

  console.log('getRandomRulingsconversationId', conversationId);
  const response = await axios.get(
    `interface/rulings/random/${conversationId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: '*',
        'content-type': 'application/json',
      },
    }
  );

  console.log('getRandomRulingsresponse3', response.data);
  let output: RulingModel[] = response.data.rulings.map((ruling: any) =>
    rulingFromMap(ruling)
  );

  if (response.status !== 200) {
    console.log('getRandomRulingsresponse3', response);
    throw new Error('Failed to fetch random rulings');
  }

  return output;
};

export const getSupremeCourtRulings = async (
  conversationId: string | undefined,
  token: string
): Promise<RulingModel[]> => {
  if (!conversationId) {
    return [];
  }

  try {
    const response = await axios.get(
      `interface/rulings/sn/${conversationId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: '*',
          'content-type': 'application/json',
        },
      }
    );

    if (response.status !== 200) {
      console.log('getSupremeCourtRulings error', response);
      throw new Error('Failed to fetch Supreme Court rulings');
    }

    return response.data.rulings.map((ruling: any) => rulingFromMap(ruling));
  } catch (error) {
    console.error('Error fetching Supreme Court rulings:', error);
    return [];
  }
};

export const getRulingText = async (
  token: string,
  rulingUrl: string,
  startIndex: number,
  endIndex: number
): Promise<string> => {
  try {
    const urlUrl = encodeURIComponent(rulingUrl);
    const response = await axios.get(
      `/lawmodel/rulings/text?ruling_link=${urlUrl}&start_index=${startIndex}&end_index=${endIndex}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: '*',
          'content-type': 'application/json',
        },
        responseType: 'json',
      }
    );
    
    return response.data;
  } catch (error: any) {
    console.error('Error in getRulingText:', error);
    if (error.response?.status === 500) {
      throw new Error('Le serveur a rencontré une erreur interne');
    } else if (error.code === 'ERR_NETWORK') {
      throw new Error('Erreur de connexion au serveur - vérifiez les paramètres CORS');
    }
    throw error;
  }
};
