import { firestore } from 'configs/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import ChatFeedDocument from 'module-private/components/organisms/chat-feed-document/ChatFeedDocument';
import DashboardTemplate from 'module-private/components/templates/dashboard-template/DashboardTemplate';
import { DocumentModel } from 'module-private/models/DocumentModel';
import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import DocumentsRightPanel from 'module-private/components/organisms/documents-right-panel/DocumentsRightPanel';
import { StatusEnum } from 'module-private/components/organisms/document-display/components/LoadingDocumentPanel';
import { ModifyOutput } from 'api/documentService';

const DocumentsPage = () => {
  const { id } = useParams();
  const [chosenDocument, setChosenDocument] = useState<DocumentModel | null>(
    null
  );
  const [chosenContent, setChosenContent] = useState<string>('');
  const [loadingText, setLoadingText] = useState<string>('');
  // const [modifiedContent, setModifiedContent] = useState<string>('');
  // const [newContent, setNewContent] = useState<string>('');
  const [modifiedContent, setModifiedContent] = useState<ModifyOutput | null>(
    null
  );
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
  const [status, setStatus] = useState<StatusEnum | null>(null);

  const fetchDocument = async () => {
    if (id) {
      const document = await getDoc(doc(firestore, 'Documents', id));

      // If document doesn't exist yet in Firestore (it might be in the process of being created)
      if (!document.exists()) {
        console.log('Document does not exist yet in Firestore:', id);
        setLoadingDocuments(true);
        return;
      }

      const docData = document.data();
      const newDoc: DocumentModel = {
        id: id,
        lastModified: new Date(),
        // Initialize messages as empty array if it doesn't exist
        messages: docData?.messages || [],
        content: docData?.document || '',
        formattedContent: docData?.formattedContent,
      };
      setChosenDocument(newDoc);

      // If there's formatted content, use it; otherwise, fall back to regular content
      if (newDoc.formattedContent) {
        setChosenContent(newDoc.formattedContent);
      } else {
        setChosenContent(newDoc.content);
      }

      setLoadingDocuments(false);
    } else {
      setChosenDocument(null);
      setChosenContent('');
    }
  };

  // Function to reset loading states and help with document upload
  const resetLoadingStates = useCallback(() => {
    setLoadingDocuments(false);
    setStatus(null);
  }, []);

  useEffect(() => {
    if (id) {
      // Reset status when navigating to a new document ID
      setStatus(null);
      fetchDocument();

      // Add a safety timeout to ensure we don't get stuck in loading state
      const safetyTimer = setTimeout(() => {
        resetLoadingStates();
      }, 1000);

      return () => clearTimeout(safetyTimer);
    } else {
      setChosenDocument(null);
      setChosenContent('');
    }
    //eslint-disable-next-line
  }, [id, resetLoadingStates]);

  return (
    <DashboardTemplate
      leftPanel={
        <div className={styles.leftSidePanel}>
          <ChatFeedDocument
            onLadingTextChange={(text: string) => {
              setLoadingText(text);
            }}
            onLoadingStateChange={(loading: boolean) => {
              setLoadingDocuments(loading);
            }}
            onPartOfTextModified={(output: ModifyOutput) => {
              setModifiedContent(output);
            }}
            onNewQuestion={() => {
              setChosenContent('');
              //console.log('onNewQuestion');
            }}
            chosenDocument={chosenDocument}
            onDocumentContentChange={(value) => {
              // Special case: if value starts with '!DIRECT!', it's a direct replacement with loading disabled
              if (value.startsWith('!DIRECT!')) {
                // Explicitly set loading to false
                setLoadingDocuments(false);
                setChosenContent(value.substring(8)); // Remove the '!DIRECT!' prefix
              } else {
                // For regular content during document generation
                setLoadingDocuments(false);
                setChosenContent((prevContent) => prevContent + value);
              }
            }}
            onConversationStarted={() => {
              setLoadingDocuments(true);
            }}
            onStatusChange={(status) => {
              setStatus(status);
            }}
          />
        </div>
      }
      rightPanel={
        <div className={styles.rightSidePanel}>
          <DocumentsRightPanel
            loadingText={loadingText}
            modifiedContent={modifiedContent}
            chosenDocument={chosenDocument}
            chosenContent={chosenContent}
            loadingDocuments={loadingDocuments}
            status={status}
          />
        </div>
      }
    />
  );
};

export default DocumentsPage;
