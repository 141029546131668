import { Button } from '@mui/material';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
type Props = {
  onGoToDocuments: () => void;
};

const NewFunctionalityInfo = ({ onGoToDocuments }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <h4 className={styles.title}>
        {t('dashboard:monit.newFunctionalityInfo.title')}
      </h4>
      <p className={styles.description}>
        {t('dashboard:monit.newFunctionalityInfo.description')}
      </p>

      <div className={styles.buttons}>
        <Button variant="contained" color="secondary" onClick={onGoToDocuments}>
          {t('dashboard:monit.newFunctionalityInfo.button')}
        </Button>
      </div>
    </div>
  );
};

export default NewFunctionalityInfo;
