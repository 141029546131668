import { collection, getDocs, query } from 'firebase/firestore';
import { firestore } from '../../../configs/firebaseConfig';
import { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

const UserList = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const [newsletter, setNewsletter] = useState<string>('all');

  const getUsers = async () => {
    setLoading(true);

    // Get all users
    const querySnapshot = await getDocs(query(collection(firestore, 'Users')));

    const usersList: {
      email: string;
      createdAt: Date;
      newsletterSubscription?: boolean;
    }[] = querySnapshot.docs
      .map((doc) => {
        return {
          email: doc.data().email,
          createdAt: doc.data().creationDate?.seconds ?? 0,
          newsletterSubscription:
            doc.data().preferences?.newsletterSubscribtion,
        };
      })
      .sort((a, b) => b.createdAt - a.createdAt);

    // Filter users based on newsletter selection
    let filteredUsers = usersList;
    if (newsletter === 'newsletter') {
      filteredUsers = usersList.filter(
        (user) =>
          user.newsletterSubscription === true ||
          user.newsletterSubscription === undefined
      );
    } else if (newsletter === 'notNewsletter') {
      filteredUsers = usersList.filter(
        (user) => user.newsletterSubscription === false
      );
    }

    setUsers(filteredUsers.map((user) => user.email));
    setLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, [newsletter]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  return (
    <Box
      width={'700px'}
      height={'600px'}
      padding="30px"
      style={{
        overflowY: 'auto',
        border: '1px solid lightGray',
        borderRadius: '5px',
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      <Box display={'flex'} justifyContent={'space-between'}>
        <TextField
          label="Wyszukaj użytkownika"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ marginBottom: '20px' }}
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
        />
        <Box width={'250px'}>
          <Select
            size="small"
            fullWidth
            value={newsletter}
            onChange={(e) => setNewsletter(e.target.value)}
          >
            <MenuItem value="all">Wszystkie</MenuItem>
            <MenuItem value="newsletter">Zapisani do newslettera</MenuItem>
            <MenuItem value="notNewsletter">
              Niezapisani do newslettera
            </MenuItem>
          </Select>
        </Box>
      </Box>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <div>
          {users
            .filter((user) => user && user.includes(search))
            .map((user, index) => {
              return (
                <div key={index} style={{ marginBottom: '7px' }}>
                  {user}
                </div>
              );
            })}
        </div>
      )}
    </Box>
  );
};

export default UserList;
