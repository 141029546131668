import React, { useRef, useEffect } from 'react';
import colors from '../../../../../configs/colorConfig';
import { Box, Link, LinkProps, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import P5Animation from '../../../../components/P5Animation';
// import NeuralNetAnimation from '../../../../components/NeuralNetAnimation';
// import p5TestGif from '../../../../../assets/gif/p5test.gif';

const StyledLink = styled(Link)<LinkProps>({
  background: colors.primary,
  color: 'white',
  textDecoration: 'none',
  '&&:hover': {
    background: colors.lightGray,
  },
  padding: '2px',
  borderRadius: '15px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '200px',
});

const VID_LINK = 'https://prawmi.github.io/video-hosting/main-video.webm';

const Hero = () => {
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement>(null);

  // WebM videos might need specific handling for autoplay
  const videoUrl = VID_LINK;

  useEffect(() => {
    // Make sure video loads and plays
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, []);

  return (
    <>
      <div id="o-nas">
        {/* Video Section - New row above existing content */}
        <div className="m-auto mx-4 px-2 md:px-12 py-8" data-aos="fade-up">
          <div className="flex justify-center">
            <div
              style={{
                width: '100%',
                maxWidth: '80rem',
                // borderRadius: '12px',
                overflow: 'hidden',
                // boxShadow: '0 20px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
                background: 'transparent',
              }}
            >
              <video
                ref={videoRef}
                src={videoUrl}
                autoPlay
                muted
                loop
                playsInline
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  transform: 'scale(1.003)', // zoom - bo jest syf na krawędziach
                }}
              />
            </div>
          </div>
        </div>

        {/* Original Content */}

        <div
          className="m-auto overflow-hidden h-5/6"
          style={{
            marginTop: '30px',
            marginLeft: '200px',
            marginRight: '200px',
          }}
          data-aos="zoom-in"
        >
          <div
            id="hero"
            className="flex flex-col py-8 justify-center text-center"
            style={{
              position: 'relative',
              height: '500px',
            }}
            >
            {/* Text container with background animation */}
            <div
              className="w-full flex flex-col justify-center items-center relative"
              data-aos="zoom-in"
              data-aos-delay="200"
              >
              {/* Background animation layer */}
              {/*
              <div 
                className="absolute inset-0 z-0"
                style={{
                  backgroundImage: `url(${p5TestGif})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  opacity: 0.7,
                  backgroundColor: 'red',
                }}
              /> */}

              {/* Foreground text & button */}
              <div className="relative z-10">
                <h1
                  style={{ color: colors.primary }}
                  className="mb-5 md:text-5xl text-3xl font-bold"
                >
                  {t('start:hero.title1')}
                  <br />
                  {t('start:hero.title2')}
                </h1>
                <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">
                  {t('start:hero.text')}
                </div>
                <div className="mb-4 space-x-0 md:space-x-2 md:mb-8 flex justify-center">
                  <StyledLink href="#kontakt">
                    {t('start:hero.button')}
                    <svg
                      className="w-4 h-4 ml-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </StyledLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
