import { Button } from '@mui/material';
import styles from './styles.module.scss';

type Props = {
  title: string;
  description: string;
  question: string;
  buttonText: string;
  buttonAction: () => void;
};

const NewsletterPanel = ({
  title,
  description,
  question,
  buttonText,
  buttonAction,
}: Props) => {
  return (
    <div id="subscribed" className={styles.newsletterBox}>
      <h3>{title}</h3>
      <p>{description}</p>
      <p className={styles.question}>{question}</p>
      <Button variant="contained" onClick={buttonAction}>
        {buttonText}
      </Button>
    </div>
  );
};

export default NewsletterPanel;
