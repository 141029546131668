import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import styles from './styles.module.scss';
import { DocumentModel } from 'module-private/models/DocumentModel';
import { Box, IconButton, Modal, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { getCssVariable } from 'styles/getVariables';
import { parseConversationalMarkdown } from '../chat-feed/components/bot-response/BotResponse';
import { useTranslation } from 'react-i18next';
import { PathType, routesManager } from 'routes/routes';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useState } from 'react';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid lightGray',
  borderRadius: '10px',
  boxShadow: 24,
  p: 2,
};

type Props = {
  documents: DocumentModel[];
  garbageDocuments: DocumentModel[];
  loading: boolean;
  onMoveToGarbage: (id: string) => void;
  onRestoreDocument: (id: string) => void;
  onDeleteDocument: (id: string) => void;
};

const ListOfDocuments = ({
  documents,
  garbageDocuments,
  loading,
  onMoveToGarbage,
  onRestoreDocument,
  onDeleteDocument,
}: Props) => {
  const navigate = useNavigate();
  const [garbageModalOpen, setGarbageModalOpen] = useState(false);
  const { t } = useTranslation();

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleDocumentClick = (id: string) => {
    console.log('id', id);
    navigate(routesManager.getPath(PathType.DOCUMENT_ID, id));
  };

  /**
   * Removes all HTML tags from a string
   * @param html - String that may contain HTML tags
   * @returns String with all HTML tags removed
   */
  const stripHtmlTags = (html: string): string => {
    // First, remove any <style> tags and their content using regex
    const htmlWithoutStyleTags = html.replace(
      /<style[^>]*>[\s\S]*?<\/style>/gi,
      ''
    );

    // Remove title tags and their content
    const htmlWithoutTitles = htmlWithoutStyleTags.replace(
      /<title[^>]*>[\s\S]*?<\/title>/gi,
      ''
    );

    // Remove other metadata tags that might be present
    const htmlWithoutMetadata = htmlWithoutTitles.replace(
      /<meta[^>]*>|<link[^>]*>/gi,
      ''
    );

    // Also remove any CSS blocks that might be in the text
    const htmlWithoutCss = htmlWithoutMetadata.replace(/{[^{}]*}/g, '');

    // Create a temporary div element
    const tempDiv = document.createElement('div');
    // Set the HTML content
    tempDiv.innerHTML = htmlWithoutCss;

    // Remove all style attributes from all elements
    const elementsWithStyle = tempDiv.querySelectorAll('*[style]');
    elementsWithStyle.forEach((element) => {
      element.removeAttribute('style');
    });

    // Remove all class attributes to eliminate external styles
    const elementsWithClass = tempDiv.querySelectorAll('*[class]');
    elementsWithClass.forEach((element) => {
      element.removeAttribute('class');
    });

    // Return the text content only (without HTML tags)
    return tempDiv.textContent || tempDiv.innerText || '';
  };

  const getText = (doc: DocumentModel) => {
    if (!doc.content) return '';
    
    // Extract content from the body tag
    const bodyMatch = doc.content.match(/<body[^>]*>([\s\S]*?)<\/body>/i);
    if (!bodyMatch || !bodyMatch[1]) return 'No content available';
    
    const bodyContent = bodyMatch[1];
    
    // Remove HTML comments
    const contentWithoutComments = bodyContent.replace(/<!--[\s\S]*?-->/g, '');
    
    // Create a temporary element to parse the HTML content
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = contentWithoutComments;
    
    // Get paragraphs and headers
    const paragraphs = tempDiv.querySelectorAll('p, h1, h2, h3, h4, h5, h6');
    
    if (paragraphs.length === 0) return 'No content available';
    
    // Get first few paragraphs/headers
    const contentBlocks = Array.from(paragraphs)
      .slice(0, 3)
      .map(element => element.textContent?.trim() || '');
    
    // Format nicely
    let formattedContent = contentBlocks.join('\n\n');
    
    // Add indication if there are more blocks
    if (paragraphs.length > 3) {
      formattedContent += `\n\n... (${paragraphs.length - 3} more sections)`;
    }
    
    return formattedContent;
  };

  const getDocumentTitle = (doc: DocumentModel) => {
    if (!doc.content) return 'Untitled Document';
    
    // Try to extract the title from title tag
    const titleMatch = doc.content.match(/<title[^>]*>(.*?)<\/title>/i);
    if (titleMatch && titleMatch[1]) {
      return titleMatch[1];
    }
    
    // Try to extract from h1 if no title tag
    const h1Match = doc.content.match(/<h1[^>]*>(.*?)<\/h1>/i);
    if (h1Match && h1Match[1]) {
      return h1Match[1];
    }
    
    return 'Untitled Document';
  };

  const getFormattedDate = (date: Date) => {
    const datePart = format(date, 'dd.MM.yy');
    const hourPart = format(date, 'HH:mm');
    return { datePart, hourPart };
  };

  const handleRestoreDocument = (id: string) => {
    onRestoreDocument(id);
  };

  const handleDeleteDocument = (id: string) => {
    onDeleteDocument(id);
  };

  return (
    <div
      style={{
        height: '100%',
        maxHeight: '100%',
        position: 'relative',
      }}
    >
      {documents.length === 0 ? (
        <div>{t('dashboard:listOfDocuments.noDocuments')}</div>
      ) : (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflowY: 'auto',
            padding: '20px',
            background: 'var(--background-color-secondary)', // Match the dark gray header color
          }}
        >
          {documents.map((doc) => (
            <div key={doc.id} className={styles.documentItem}>
              <div style={{ position: 'relative' }}>
                <div
                  className={styles.documentItemContent}
                  onClick={() => handleDocumentClick(doc.id)}
                >
                  <div className={styles.documentHeader}>
                    <p className={styles.documentDate}>
                      {getFormattedDate(doc.lastModified).datePart}&nbsp;&nbsp;&nbsp;<span className={styles.documentHour}>{getFormattedDate(doc.lastModified).hourPart}</span>
                    </p>
                  </div>
                  <div className={styles.previewWrapper}>
                    <div className={styles.documentPreview}>
                      <div className={styles.sideBend}></div>
                      {getText(doc)}
                    </div>
                  </div>
                </div>
                <div style={{ 
                  position: 'absolute',
                  right: '5px',
                  top: '5px',
                  zIndex: 5 
                }}>
                  <IconButton onClick={(e) => {
                    e.stopPropagation();
                    onMoveToGarbage(doc.id);
                  }}>
                    <DeleteIcon
                      sx={{ color: getCssVariable('--text-primary') }}
                    />
                  </IconButton>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div
        style={{
          position: 'absolute',
          bottom: 10,
          right: 10,
          border: '1px solid var(--text-primary)',
          borderRadius: '50%',
        }}
      >
        <Tooltip title={t('dashboard:listOfDocuments.garbage')}>
          <IconButton onClick={() => setGarbageModalOpen(true)}>
            <DeleteIcon sx={{ color: getCssVariable('--text-primary') }} />
          </IconButton>
        </Tooltip>
      </div>
      <Modal open={garbageModalOpen} onClose={() => setGarbageModalOpen(false)}>
        <Box sx={{ ...modalStyle, width: 600 }}>
          <div>
            <h2 style={{ fontSize: '16px' }}>
              {t('dashboard:listOfDocuments.garbageList')}
            </h2>
            {garbageDocuments.length === 0 ? (
              <div>{t('dashboard:listOfDocuments.noDocumentsInGarbage')}</div>
            ) : (
              <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                {garbageDocuments.map((doc) => (
                  <div key={doc.id} className={styles.garbageDocumentItem}>
                    <div style={{ position: 'relative' }}>
                      <div className={styles.documentItemContent}>
                        <div className={styles.documentHeader}>
                          <p className={styles.documentDate}>
                            {getFormattedDate(doc.lastModified).datePart}&nbsp;&nbsp;&nbsp;<span className={styles.documentHour}>{getFormattedDate(doc.lastModified).hourPart}</span>
                          </p>
                        </div>
                        <div className={styles.previewWrapper}>
                          <div className={styles.documentPreview}>
                            <div className={styles.sideBend}></div>
                            {getText(doc)}
                          </div>
                        </div>
                      </div>
                      <div style={{ 
                        position: 'absolute',
                        right: '5px',
                        top: '5px',
                        zIndex: 5
                      }}>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRestoreDocument(doc.id);
                          }}
                        >
                          <RestartAltIcon
                            sx={{ color: getCssVariable('--text-primary') }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDocument(doc.id);
                          }}
                        >
                          <DeleteIcon
                            sx={{ color: getCssVariable('--text-primary') }}
                          />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ListOfDocuments;
