import { Button } from '@mui/material';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  onSendMessage: () => void;
  onGoToSubscription: () => void;
};

const TrialEndedInfo = ({ onSendMessage, onGoToSubscription }: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      <h4 className={styles.title}>
        {t('dashboard:monit.trialEndedInfo.title')}
      </h4>
      <p className={styles.description}>
        {t('dashboard:monit.trialEndedInfo.description1')}
      </p>
      <p className={styles.description}>
        {t('dashboard:monit.trialEndedInfo.description2')}
      </p>
      <div className={styles.buttons}>
        <Button
          variant="contained"
          color="secondary"
          onClick={onGoToSubscription}
        >
          {t('dashboard:monit.trialEndedInfo.button1')}
        </Button>
        <Button variant="outlined" color="secondary" onClick={onSendMessage}>
          {t('dashboard:monit.trialEndedInfo.button2')}
        </Button>
      </div>
    </div>
  );
};

export default TrialEndedInfo;
