import assets from '../../assets';
import { BlogArticleModel } from '../../models/BlogArticle';

export const actualizationsArticles: BlogArticleModel[] = [
  {
    image: assets.actualization.image1_1,
    title: 'PrawMi – Nowe funkcje, ulepszenia',
    introduction: '',
    text: [
      {
        sectionTitle: 'Tworzenie Dokumentów na nowym poziomie!',
        text: '&#128640; <strong>Szybsze generowanie dokumentów</strong> – teraz odpowiedź pojawia się w 10-20 sekund.<br/>&#9997; <strong>Interaktywne komentarze w .docx</strong> – teraz jako organiczne komentarze Worda, a nie tekst kursywą.<br/>&#127912; <strong>Lepsze formatowanie</strong> – nagłówki, kolorowe placeholdery i czytelniejszy układ.<br/>&#128229; <strong>Nowy przycisk pobierania pliku</strong> bez komentarzy.<br/>&#128161; <strong>Przykłady promptów</strong> – teraz łatwiej doprecyzujesz swoje zapytania.',
      },
      {
        sectionTitle: '',
        text: '<hr style="border: 1px solid lightgray;">',
      },
      {
        sectionTitle: 'Aktualizacja bazy aktów prawnych',
        text: '&#128204; <strong>Zaktualizowaliśmy teksty ustaw</strong> do stanu obecnego.<br/>&#128214; <strong>Dodaliśmy ~100 brakujących ustaw</strong> – teraz mamy kompletną bazę!',
      },
      {
        sectionTitle: '',
        text: '<hr style="border: 1px solid lightgray;">',
      },
      {
        sectionTitle: 'Lepsze wyszukiwanie orzeczeń',
        text: '&#128279; <strong>Połączenie orzeczeń Sądów Administracyjnych z artykułami</strong> – teraz masz łatwiejszy dostęp do 200k orzeczeń bezpośrednio z przeglądarki ustawy.',
      },
      {
        sectionTitle: '',
        text: '<hr style="border: 1px solid lightgray;">',
      },
      {
        sectionTitle: 'Nowy, klarowniejszy interfejs',
        text: '&#128197; <strong>Wyświetlanie nazwy sądu i daty orzeczeń</strong> w źródłach.<br/>&#128194; <strong>Lepsza nawigacja</strong> – nowy, bardziej intuicyjny sidebar.<br/>&#9889; <strong>Szybsze zwracanie źródeł</strong> – kilka sekund mniej oczekiwania!<br/>&#128736; <strong>Nowe mechanizmy testowania kodu</strong> – mniej błędów, większa stabilność.',
      },
      {
        sectionTitle: '',
        text: '<hr style="border: 1px solid lightgray;">',
      },
      {
        sectionTitle: 'Lepsza komunikacja funkcji ',
        text: '&#127916;<strong> Film na stronie głównej</strong> – teraz jeszcze lepiej zrozumiesz funkcje PrawMi.<br/>&#128172;<strong> Nowe monity w aplikacji</strong> wyjaśniające obsługę (daj nam znać, czy są pomocne!).',
      },
      {
        sectionTitle: '',
        text: '<hr style="border: 1px solid lightgray;">',
      },
      {
        sectionTitle: '',
        text: 'Twoje zdanie jest dla nas ważne. Jeśli masz jakiekolwiek sugestie, chętnie je poznamy!',
      },

      {
        sectionTitle: '',
        text: '',
      },
    ],
    date: new Date(2025, 2, 31),
    url: 'nowe-funkcje-w-prawmi',
    sources: [],
  },
];
