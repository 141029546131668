import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import { LawModel } from '../../../../models/LawModel';
import { RulingModel } from '../../../../models/RulingModel';
import Source from './Source';
import ListOfRulingsOnly from './ListOfDistinctRulings';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getCssVariable } from 'styles/getVariables';
import RulingItem from './RulingItem';

const POLISH_SUPREME_COURT_TYPE = 'polish-sn';
const STORAGE_KEY_SUPREME_COURT = 'expandedSupremeCourtSection';
const STORAGE_KEY_OTHER_RULINGS = 'expandedOtherRulingsSection';

type Props = {
  sources: LawModel[];
  sourceRulings: RulingModel[];
  sourcesLoading: boolean;
  onRulingSelect: (source: RulingModel) => void;
  onLawSelect: (source: LawModel) => void;
  expanded: boolean;
  onExpandedChange: (expanded: boolean) => void;
};

const ListOfSources = ({
  sources,
  sourceRulings,
  sourcesLoading,
  onRulingSelect,
  onLawSelect,
  expanded,
  onExpandedChange,
}: Props) => {
  const { t } = useTranslation();
  const [supremeCourtRulings, setSupremeCourtRulings] = useState<RulingModel[]>([]);
  const [otherRulings, setOtherRulings] = useState<RulingModel[]>([]);
  const isInitialMount = useRef(true);
  
  // Get saved expanded state from localStorage, defaulting to the expanded prop if not found
  const getSavedExpandedState = (key: string) => {
    const savedState = localStorage.getItem(key);
    return savedState !== null ? savedState === 'true' : expanded;
  };
  
  // Initialize expanded states from localStorage
  const [expandedSupremeCourtSection, setExpandedSupremeCourtSection] = useState<boolean>(
    getSavedExpandedState(STORAGE_KEY_SUPREME_COURT)
  );
  const [expandedOtherRulingsSection, setExpandedOtherRulingsSection] = useState<boolean>(
    getSavedExpandedState(STORAGE_KEY_OTHER_RULINGS)
  );

  // Custom state setters to update localStorage when state changes
  const handleSupremeCourtSectionExpand = (newState: boolean) => {
    setExpandedSupremeCourtSection(newState);
    localStorage.setItem(STORAGE_KEY_SUPREME_COURT, String(newState));
    // Also notify parent of the state change
    onExpandedChange(newState);
  };

  const handleOtherRulingsSectionExpand = (newState: boolean) => {
    setExpandedOtherRulingsSection(newState);
    localStorage.setItem(STORAGE_KEY_OTHER_RULINGS, String(newState));
    // Also notify parent of the state change
    onExpandedChange(newState);
  };

  useEffect(() => {
    // Filter Supreme Court rulings (SN - Sąd Najwyższy)
    setSupremeCourtRulings(sourceRulings.filter(ruling => 
      (ruling.courtType && ruling.courtType === POLISH_SUPREME_COURT_TYPE)
    ));
    // setSupremeCourtRulings(sourceRulings.filter(ruling => 
    //   ruling.courtType
    // ));
    
    // Other rulings
    setOtherRulings(sourceRulings.filter(ruling => !ruling.courtType));
    
  }, [sources, sourceRulings]);

  useEffect(() => {
    console.log('all rulings:', sourceRulings);
  }, [sourceRulings]);

  useEffect(() => {
    console.log('supremeCourtRulings updated:', supremeCourtRulings);
  }, [supremeCourtRulings]);
  
  useEffect(() => {
    console.log('otherRulings updated:', otherRulings);
  }, [otherRulings]);

  // Update individual section states when parent expanded state changes
  // Only apply parent state during initial mount
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      // Only apply parent state if localStorage doesn't have saved values
      if (localStorage.getItem(STORAGE_KEY_SUPREME_COURT) === null) {
        handleSupremeCourtSectionExpand(expanded);
      }
      if (localStorage.getItem(STORAGE_KEY_OTHER_RULINGS) === null) {
        handleOtherRulingsSectionExpand(expanded);
      }
    }
  }, [expanded]);

  return (
    <>

      <ListOfRulingsOnly
        rulings={supremeCourtRulings}
        loading={false}
        onRulingSelect={onRulingSelect}
        onLawSelect={onLawSelect}
        expanded={expandedSupremeCourtSection}
        onExpandedChange={handleSupremeCourtSectionExpand}
        titleKey="dashboard:listOfSources.supremeCourtRulings"
      />
      <ListOfRulingsOnly
        rulings={otherRulings}
        loading={false}
        onRulingSelect={onRulingSelect}
        onLawSelect={onLawSelect}
        expanded={expandedOtherRulingsSection}
        onExpandedChange={handleOtherRulingsSectionExpand}
        titleKey="dashboard:listOfSources.otherInterestingRulings"
      />

      {sources.length > 0 && !sourcesLoading ? (
        <>
          {sources.map((source, _) => (
            <>
              <Source
                source={source}
                onRulingSelect={onRulingSelect}
                onLawSelect={onLawSelect}
              />
            </>
          ))}
        </>
      ) : (
        <>
          {sourcesLoading ? (
            <>
              <Box display={'flex'} justifyContent={'center'}>
                <CircularProgress size={'30px'} />
              </Box>
            </>
          ) : (
            <Box>
              <Box display={'flex'} justifyContent={'center'}>
                {process.env.REACT_APP_LANGUAGE !== 'fr' && (
                  <Typography margin={'40px'} color={'dimgray'}>
                    {t('dashboard:listOfSources.ruling')}
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default ListOfSources;
