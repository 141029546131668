import { Select, MenuItem } from '@mui/material';

type Props = {
  guardian: string | null;
  onChange: (value: string) => void;
};

const getBackgroundColor = (value: string) => {
  switch (value) {
    case '-':
      return '#e0e0e0';
    case 'JM':
      return '#FDAE45';
    case 'BW':
      return '#9bddff';
    case 'TB':
      return '#BAEAA0';
    case 'LX':
      return '#6D6E73';
    case 'PM':
      return '#915AC2';
    default:
      return '#ffffff';
  }
};

const GuardianSelector = ({ guardian, onChange }: Props) => {
  const value = guardian ?? '-';

  return (
    <div>
      <Select
        size="small"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        IconComponent={() => null}
        sx={{
          width: '75px',
          color: 'black',
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'black',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'black',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'black',
          },
          backgroundColor: getBackgroundColor(value),
          '& .MuiSelect-select': {
            padding: '4px 2px',
            textAlign: 'center',
            paddingRight: '2px !important',
          },
        }}
      >
        <MenuItem value="-">-</MenuItem>
        <MenuItem value="JM">JM</MenuItem>
        <MenuItem value="BW">BW</MenuItem>
        <MenuItem value="TB">TB</MenuItem>
        <MenuItem value="LX">LX</MenuItem>
        <MenuItem value="PM">PM</MenuItem>
      </Select>
    </div>
  );
};

export default GuardianSelector;
