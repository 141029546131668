import { Box, Grid } from '@mui/material';
import { LawModel } from '../../../../models/LawModel';
import { RulingModel } from '../../../../models/RulingModel';
import LawPanel, { LawPanelData } from '../law-panel/LawPanel';
import NavigationMenu from './components/navigation-menu/NavigationMenu';
import useTabs from '../../../../hooks/useTabs';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import GoBackButton from './components/go-back-button/GoBackButton';
import Popover from '@mui/material/Popover';
import ListOfConnectedRulings from '../law-panel/components/list-of-connected-rulings/ListOfConnectedRulings';
import { CaseModel } from '../../../models/CaseModel';
import { SearchBarProvider } from '../../../../context/SearchBarProvider';
import SearchTabPanel from '../search-tab-panel/SearchTabPanel';
import styles from './styles.module.scss';
import { getCssVariable } from 'styles/getVariables';
import RulingsListPanel from '../rulings-list-panel/RulingsListPanel';
import ListOfSources from '../list-of-sources/ListOfSources';
import RulingPanel from '../ruling-panel/RulingPanel';
import { PathType, routesManager } from 'routes/routes';

type Props = {
  sources: LawModel[];
  sourceRulings: RulingModel[];
  sourcesLoading: boolean;
  gridProps?: any;
};

export interface RulingPopoverModel {
  path: {
    article: string;
    paragraph?: string;
    point?: string;
    letter?: string;
  };
  endpoint: string;
  anchor: string | null;
  law: LawModel;
  loadedRulings: RulingModel[];
  numberOfRulings: number;
  visibility: boolean;
}

const MainRightPanel = ({
  sources,
  sourceRulings,
  gridProps,
  sourcesLoading,
}: Props) => {
  const { id } = useParams<{ id: string }>();
  const [tabRulings, setTabRulings] = useState<CaseModel[]>([]);
  const [expandedListOfSources, setExpandedListOfSources] = useState(false);
  const [listOfLawPanelData, setListOfLawPanelData] = useState<LawPanelData[]>(
    []
  );
  const [listOfHTMLTexts, setListOfHTMLTexts] = useState<string[]>([]);
  const { displayed, setDisplayedCase, addRuling, tabs, modifyRuling } =
    useTabs();
  const scrollPositionRef = useRef<number[]>(new Array(100).fill(0));
  const location = useLocation();
  const listOfArticlePositionsRef = useRef<
    { name: string; position: number }[]
  >([]);
  const [visibleArticle, setVisibleArticle] = useState<string>('');
  const [popoverData, setPopoverData] = useState<RulingPopoverModel>({
    path: {
      article: '',
      paragraph: '',
      point: '',
      letter: '',
    },
    endpoint: '',
    anchor: null,
    law: {} as LawModel,
    loadedRulings: [],
    numberOfRulings: 0,
    visibility: false,
  });

  const handleAddCase = (chosenCase: RulingModel | LawModel) => {
    if (tabs.map((ruling) => ruling.url).includes(chosenCase.url)) {
      modifyRuling(chosenCase, id);
      setDisplayedCase(chosenCase);
    } else {
      addRuling(chosenCase, id);
      setDisplayedCase(chosenCase);
    }
  };

  useEffect(() => {
    let index = 0;
    if (displayed === 'search' || displayed === 'sources') {
      index = 0;
    } else {
      tabs.forEach((ruling, i) => {
        if (ruling === displayed) {
          index = i + 2;
        }
      });
    }

    const box = document.getElementById('boxScrolled');

    if (box) {
      box.onscroll = () => {
        scrollPositionRef.current[index] = box.scrollTop;
        if (
          displayed !== 'search' &&
          displayed !== 'sources' &&
          displayed.type === 'law'
        ) {
          let name = listOfArticlePositionsRef.current.find(
            (position) => position.position > box.scrollTop
          )?.name;
          console.log(name);
          setVisibleArticle(name ?? listOfArticlePositionsRef.current[0].name);
        }
      };

      box.scrollTop = scrollPositionRef.current[index];
    }
    // eslint-disable-next-line
  }, [displayed]);

  useEffect(() => {
    if (tabRulings.length > tabs.length) {
      let listOfTitlesTabs = tabs.map((ruling) => ruling.title);
      let r = tabRulings.find(
        (ruling) => !listOfTitlesTabs.includes(ruling.title)
      );
      let deletedRuling = tabRulings
        .filter((f) => f.type === r?.type)
        .findIndex((ruling) => !listOfTitlesTabs.includes(ruling.title));
      if (r?.type === 'law') {
        let listOfData = [...listOfLawPanelData];
        listOfData.splice(deletedRuling, 1);
        setListOfLawPanelData([...listOfData]);
      } else if (r?.type === 'ruling') {
        let listOfData = [...listOfHTMLTexts];
        listOfData.splice(deletedRuling, 1);
        setListOfHTMLTexts([...listOfData]);
      }
    }
    setTabRulings(tabs);
    //eslint-disable-next-line
  }, [tabs]);

  useEffect(() => {
    if (location.pathname === routesManager.getPath(PathType.CHAT)) {
      setListOfLawPanelData([]);
    }
    setListOfHTMLTexts([]);
  }, [location.pathname]);

  useEffect(() => {
    if (displayed !== popoverData.law) {
      setPopoverData({ ...popoverData, visibility: false });
    } else if (
      displayed === popoverData.law &&
      popoverData.anchor &&
      document.getElementById(popoverData.anchor) != null
    ) {
      setPopoverData({ ...popoverData, visibility: true });
    }
    // eslint-disable-next-line
  }, [displayed]);

  const handleScroll = (
    index: number,
    scrollNumber: number,
    smooth?: boolean
  ) => {
    index += 2;
    scrollPositionRef.current[index] = scrollNumber;

    const box = document.getElementById('boxScrolled');
    if (box) {
      if (smooth) {
        box.scrollTo({
          top: scrollPositionRef.current[index],
          behavior: 'smooth',
        });
      } else {
        box.scrollTop = scrollPositionRef.current[index];
      }
    }
  };

  const handleSelection = (value: string) => {
    let index = 0;
    if (displayed === 'search' || displayed === 'sources') {
      index = 0;
    } else {
      tabs.forEach((ruling, i) => {
        if (ruling === displayed) {
          index = i + 2;
        }
      });
    }
    let scrollNumber = listOfArticlePositionsRef.current.find(
      (m) => m.name === value
    )?.position;
    if (scrollNumber) {
      handleScroll(index, scrollNumber - 50, true);
    }
  };

  const renderSources = () => {
    return (
      <Box
        id={'boxScrolled'}
        p={'10px'}
        style={{
          height: '100%',
          overflowY: 'auto',
          display: displayed === 'sources' ? 'block' : 'none',
        }}
      >
        <ListOfSources
          sources={sources}
          sourcesLoading={sourcesLoading}
          sourceRulings={sourceRulings}
          onRulingSelect={(source) => {
            handleAddCase(source);
          }}
          onLawSelect={(source) => {
            handleAddCase(source);
          }}
          expanded={expandedListOfSources}
          onExpandedChange={(v) => {
            setExpandedListOfSources(v);
          }}
        />
      </Box>
    );
  };

  const renderSearch = () => {
    return (
      <Box
        id={'boxScrolled'}
        p={'10px'}
        height={'100%'}
        style={{
          overflowY: 'auto',
          display: displayed === 'search' ? 'block' : 'none',
        }}
      >
        <SearchTabPanel />
      </Box>
    );
  };

  const renderRulingsList = () => {
    return <RulingsListPanel />;
  };

  const handleChangePart = (lawData: LawPanelData, index: number) => {
    let newListOfData: LawPanelData[] = [...listOfLawPanelData];

    if (index >= listOfLawPanelData.length) {
      for (let i = listOfLawPanelData.length; i < index; i++) {
        newListOfData.push({
          listOfArticleNames: [],
          listOfParts: [],
          highlightedArticle: '',
        });
      }
      newListOfData.push(lawData);
      setListOfLawPanelData([...newListOfData]);
    } else {
      newListOfData[index] = lawData;
      setListOfLawPanelData([...newListOfData]);
    }
  };

  const handleChangeHTMLText = (text: string, index: number) => {
    let newListOfHTMLs = [...listOfHTMLTexts];

    if (index >= listOfHTMLTexts.length) {
      for (let i = listOfHTMLTexts.length; i < index; i++) {
        newListOfHTMLs.push('');
      }
      newListOfHTMLs.push(text);
      setListOfHTMLTexts([...newListOfHTMLs]);
    } else {
      newListOfHTMLs[index] = text;
      setListOfHTMLTexts([...newListOfHTMLs]);
    }
  };

  const renderLaw = (law: LawModel) => {
    let filtered = tabs.filter((m) => m.type === 'law');

    let mapped = filtered.map((m) => {
      return { number: m.number, year: m.year };
    });
    let display = {
      number: (displayed as LawModel).number ?? -1,
      year: (displayed as LawModel).year ?? -1,
    };
    let index = mapped.findIndex(
      (m) => m.number === display.number && m.year === display.year
    );
    return (
      <Box
        id={'boxScrolled'}
        sx={{
          background: getCssVariable('background-document'),
          height: '100%',
          overflowY: 'auto',
          p: '20px',
        }}
      >
        <LawPanel
          onLawPanelDataSet={(data: LawPanelData) =>
            handleChangePart(data, index)
          }
          onArticlePositionsSet={(
            positions: { name: string; position: number }[]
          ) => {
            listOfArticlePositionsRef.current = positions;
            setVisibleArticle(positions[0].name);
          }}
          lawPanelData={listOfLawPanelData[index] ?? []}
          law={law}
          onParagraphSet={(scrollNumber: number) => {
            handleScroll(index, scrollNumber);
          }}
          onLawHammerClick={(data: RulingPopoverModel) => {
            setPopoverData(data);
          }}
        />
      </Box>
    );
  };

  const renderRuling = (ruling: RulingModel) => {
    let index = tabs
      .filter((m) => m.type === 'ruling')
      .map((model: any) => model.url)
      .indexOf((displayed as RulingModel).url);
    return (
      <Box
        id={'boxScrolled'}
        sx={{
          background: getCssVariable('background-document'),
          height: '100%',
          overflowY: 'auto',
          p: '20px',
        }}
      >
        <RulingPanel
          ruling={ruling}
          onHtmlTextSet={(htmlText: string) => {
            handleChangeHTMLText(htmlText, index);
          }}
          htmlText={listOfHTMLTexts[index] ?? null}
          scrollToFragment={true}
        />
      </Box>
    );
  };

  return (
    <SearchBarProvider>
      <Grid
        className={styles.rightSidePanelGrid}
        {...gridProps}
        height={'100%'}
        width={'100%'}
      >
        <Box width={'100%'} height={'27px'}>
          <NavigationMenu />
        </Box>
        {displayed !== 'search' &&
          displayed !== 'sources' &&
          displayed.type === 'law' && (
            <GoBackButton
              onArticleSelected={(value: string) => {
                handleSelection(value);
              }}
              listOfArticleNames={listOfArticlePositionsRef.current.map(
                (position) => position.name
              )}
              value={visibleArticle}
            />
          )}

        <Box sx={{ height: 'calc(100% - 27px)' }}>
          {displayed === 'search'
            ? renderSearch()
            : displayed === 'sources'
              ? renderSources()
              : displayed.type === 'law'
                ? renderLaw(displayed as LawModel)
                : displayed.type === 'ruling'
                  ? renderRuling(displayed as RulingModel)
                  : renderRulingsList()}
        </Box>
        <Popover
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={
            popoverData.anchor != null &&
            popoverData.law === displayed &&
            popoverData.visibility
          }
          anchorEl={document.getElementById(popoverData.anchor ?? '')}
          onClose={() => {
            setPopoverData({ ...popoverData, anchor: null });
          }}
        >
          <Box
            sx={{
              minHeight: '250px',
              maxHeight: '750px',
              padding: '10px',
              maxWidth: '700px',
            }}
          >
            <ListOfConnectedRulings
              onClick={() => {}}
              law={popoverData.law}
              endpoint={popoverData.endpoint}
              path={popoverData.path}
              onRulingLoad={(rulings) =>
                setPopoverData({ ...popoverData, loadedRulings: rulings })
              }
              loadedRulings={popoverData.loadedRulings}
              numberOfRulings={popoverData.numberOfRulings}
            />
          </Box>
        </Popover>
      </Grid>
    </SearchBarProvider>
  );
};

export default MainRightPanel;
