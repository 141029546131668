import React, { useEffect, useState } from 'react';
import { ConversationModel } from '../../../../models/ConversationModel';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { sortConversations } from './ListOfConversations';
import ConversationSidebarButton from './ConversationSidebarButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useTranslation } from 'react-i18next';

type Props = {
  conversationList: ConversationModel[];
  onClick: (conversation: ConversationModel) => void;
  onDeleteClick: (conversation: ConversationModel) => void;
};

export const SidebarListOfConversations = ({
  conversationList,
  onClick,
  onDeleteClick,
}: Props) => {
  const [todaysConversations, setTodaysConversations] = useState<
    ConversationModel[]
  >([]);
  const [olderConversations, setOlderConversations] = useState<
    ConversationModel[]
  >([]);
  const { t } = useTranslation();

  useEffect(() => {
    let output = sortConversations(conversationList);

    setTodaysConversations(output.todays);
    setOlderConversations(output.older);
    //eslint-disable-next-line
  }, [conversationList]);

  const renderConversations = (
    title: string,
    conversations: ConversationModel[]
  ) => {
    if (conversations.length === 0) return null;
    else {
      return (
        <Box marginBottom={'1px'}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              style={{ fontSize: '14px', fontWeight: '700', color: '#f0f0f0' }}
            >
              {title}
            </Typography>
          </div>
          {conversations.map((conversation, index) => (
            <Tooltip
              title={
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    ml: 1,
                    mr: 1,
                    padding: '4px 4px',
                    width: '100%',
                  }}
                >
                  <Typography
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#f0f0f0',
                    }}
                  >
                    {conversation.title}...
                  </Typography>
                  <IconButton
                    size="small"
                    sx={{
                      color: '#f0f0f0',
                      padding: '2px',
                      ml: 1,
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      },
                    }}
                    onClick={() => {
                      onDeleteClick(conversation);
                    }}
                  >
                    <DeleteOutlineIcon fontSize="small" />
                  </IconButton>
                </Box>
              }
              placement="right"
              key={index}
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 10], // [skidding, distance] - skidding is vertical, distance is horizontal
                    },
                  },
                ],
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <ConversationSidebarButton
                  index={index}
                  conversation={conversation}
                  onDeleteClick={(c) => {}}
                  onClick={(c) => {
                    onClick(c);
                  }}
                />
              </Box>
            </Tooltip>
          ))}
        </Box>
      );
    }
  };

  return (
    <Box>
      {renderConversations(t('dashboard:sidebar.today'), todaysConversations)}
      {renderConversations(t('dashboard:sidebar.older'), olderConversations)}
    </Box>
  );
};
