import React, { createContext, useEffect, useState } from 'react';
import { auth, firestore } from '../configs/firebaseConfig';
import { User } from 'firebase/auth';
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { UserModel } from 'models/UserModel';

interface PropsAuthProvider {
  children: React.ReactNode;
}

interface AuthContextType {
  currentUser: UserModel | null;
  userRole: number | null;
}

export const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  userRole: null,
});

export const AuthProvider = ({ children }: PropsAuthProvider) => {
  const [currentUser, setCurrentUser] = useState<UserModel | null>(null);
  const [userRole, setUserRole] = useState<number | null>(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    const fetchUserData = async (user: User) => {
      const db = getFirestore();

      const rolesRef = collection(db, 'Roles');
      const roleQuery = query(rolesRef, where('email', '==', user.email));
      const roleSnapshot = await getDocs(roleQuery);

      if (!roleSnapshot.empty) {
        setUserRole(roleSnapshot.docs[0].data().role);
      } else {
        setUserRole(null);
      }

      const userDoc = await getDoc(doc(db, 'Users', user.uid));

      if (userDoc.exists()) {
        const subscriptionsRef = collection(
          firestore,
          'Users',
          user.uid,
          'subscriptions'
        );
        const q = query(
          subscriptionsRef,
          where('status', 'in', ['trialing', 'active'])
        );

        const querySnapshot = await getDocs(q);
        const subscriptions = querySnapshot.docs.map((doc) => doc.data());
        let activeSubscription;
        if (subscriptions.length > 0) {
          activeSubscription = subscriptions[0];
        }

        let userData: UserModel = {
          id: user.uid,
          firstName: userDoc.data().firstName,
          lastName: userDoc.data().lastName,
          email: user.email ?? '',
          roles: userDoc.data().roles,
          trialFinishDate: userDoc.data().trial_ended_at
            ? userDoc.data().trial_ended_at.toDate()
            : undefined,
          activeTrial: userDoc.data().trial_ended_at
            ? userDoc.data().trial_ended_at.toDate() > new Date()
            : true,
          activeSubscription: activeSubscription?.current_period_end
            ? activeSubscription?.current_period_end.toDate() > new Date()
            : false,
          subscribtionFinishDate: activeSubscription?.current_period_end
            ? activeSubscription?.current_period_end.toDate()
            : undefined,
          subscribtionLastPaymentDate: activeSubscription?.current_period_start
            ? activeSubscription?.current_period_start.toDate()
            : undefined,
        };

        setCurrentUser(userData);
      } else {
      }
    };

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        let userData: UserModel = {
          id: user.uid,
          firstName: '',
          lastName: '',
          email: user.email ?? '',
          roles: [],
          activeTrial: true,
          activeSubscription: false,
        };
        setCurrentUser(userData);

        await fetchUserData(user);
      } else setCurrentUser(null);
      setPending(false);
    });

    return () => unsubscribe();
  }, []);

  if (pending) {
    return <></>;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        userRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
