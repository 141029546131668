import { Box, Button, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { addDoc, collection, getDocs, updateDoc } from 'firebase/firestore';
import { firestore } from '../../../configs/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/Auth';
import UserWithPriceForm, { FormUser } from './UserWithPriceAddition';
import UserList from './UserList';
import { PathType, routesManager } from 'routes/routes';
import { ROLE_ADMIN, ROLE_LEXERA } from '../../../constants/roles';

const AdminPage = () => {
  const { userRole } = useContext(AuthContext);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');
  const [showUserList, setShowUserList] = useState(false);

  const navigate = useNavigate();

  const createFormUser = async (formUser: FormUser) => {
    if (formUser != null) {
      setLoading(true);

      const usersRef = collection(firestore, 'Users');
      const querySnapshot = await getDocs(usersRef);

      // Find existing user with matching email
      const matchingDoc = querySnapshot.docs.find(
        (doc) =>
          doc.data().email?.toLowerCase() === formUser.email.toLowerCase()
      );

      const trialEndDate = new Date();
      trialEndDate.setDate(trialEndDate.getDate() + formUser.trialDays);

      // Prepare user data (excluding trialDays)
      const userData: Partial<
        FormUser & { trial_ended_at: Date; user_price_setup_by_admin: Date }
      > = {
        ...formUser,
        email: formUser.email.toLowerCase(),
        trial_ended_at: trialEndDate,
        user_price_setup_by_admin: new Date(),
      };
      delete userData.trialDays;

      if (matchingDoc) {
        // Update existing user
        const existingData = matchingDoc.data();
        userData.description = existingData.description
          ? `${existingData.description}\n${formUser.description}`
          : formUser.description;

        await updateDoc(matchingDoc.ref, userData);
        setText('Zaktualizowano użytkownika');
      } else {
        // For LEXERA role, only allow updates to existing users, not creation
        if (userRole === ROLE_LEXERA) {
          setText('Nie znaleziono użytkownika o podanym adresie email');
          setLoading(false);
          return;
        }
        
        // Create new user (only ADMIN role reaches here)
        await addDoc(usersRef, userData);
        setText('Dodano użytkownika');
      }

      setLoading(false);
    }
  };

  const handleFormUserClick = (formUser: FormUser) => {
    createFormUser(formUser);
  };

  useEffect(() => {
    if (userRole !== ROLE_ADMIN && userRole !== ROLE_LEXERA) {
      navigate('/');
    } else {
      setLoadingRoles(false);
    }
    //eslint-disable-next-line
  }, []);

  // LEXERA users always see the price form, never the list
  useEffect(() => {
    if (userRole === ROLE_LEXERA) {
      setShowUserList(false);
    }
  }, [userRole]);

  const renderContent = () => {
    // For LEXERA role, always show the form, never the list
    if (userRole === ROLE_LEXERA) {
      return (
        <>
          <Typography variant={'h3'} fontSize="16px" textAlign={'center'}>
            {text}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <UserWithPriceForm
                onClick={handleFormUserClick}
                loading={loading}
                userRole={userRole}
              />
            </Grid>
          </Grid>
        </>
      );
    }
    
    // For ADMIN role, show form by default, toggle to list when showUserList is true
    return showUserList ? (
      <UserList />
    ) : (
      <>
        <Typography variant={'h3'} fontSize="16px" textAlign={'center'}>
          {text}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <UserWithPriceForm
              onClick={handleFormUserClick}
              loading={loading}
              userRole={userRole}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Grid
      height={'100vh'}
      width={'calc(100vw - 100px)'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      alignContent={'center'}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '0',
          right: '0',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Button
          onClick={() => navigate(routesManager.getPath(PathType.MNB_USERS))}
        >
          Użytkownicy
        </Button>
        {userRole === ROLE_ADMIN && (
          <Button
            onClick={() => navigate(routesManager.getPath(PathType.MNB_CHAT))}
          >
            Konwersacje
          </Button>
        )}
      </Box>
      {loadingRoles ? (
        <></>
      ) : (
        <Box>
          {renderContent()}
          {userRole === ROLE_ADMIN && (
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                setShowUserList(!showUserList);
              }}
            >
              {showUserList ? 'Ustal cenę i okres próbny' : 'Lista użytkowników'}
            </Button>
          )}
        </Box>
      )}
    </Grid>
  );
};

export default AdminPage;
