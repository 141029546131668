import React, { useCallback, useState, createContext } from 'react';
import { CaseModel } from '../module-private/models/CaseModel';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../configs/firebaseConfig';

// Recursive function to sanitize an object for Firebase
// Replaces undefined values with null or empty string based on field type
const sanitizeForFirebase = (obj: any): any => {
  if (obj === undefined) return null;
  if (obj === null) return null;
  if (typeof obj !== 'object') return obj;

  if (Array.isArray(obj)) {
    return obj.map(item => sanitizeForFirebase(item));
  }

  const sanitized: Record<string, any> = {};
  for (const [key, value] of Object.entries(obj)) {
    // For string fields, replace undefined/null with empty string
    if (['highlightedArticle', 'highlightedParagraph', 'url', 'entityPath', 'uniqueKey', 'type'].includes(key)) {
      sanitized[key] = value === undefined || value === null ? '' : value;
    } else {
      // For other fields, replace undefined with null
      sanitized[key] = sanitizeForFirebase(value);
    }
  }
  return sanitized;
};

async function updateConversationField(
  conversationId: string,
  newValue: any[]
) {
  try {
    if (!conversationId) {
      console.error('Cannot update conversation: missing conversationId');
      return;
    }

    // Deep sanitize the array and all its objects
    const sanitizedValue = sanitizeForFirebase(newValue);
    
    const conversationRef = doc(firestore, 'Conversations', conversationId);
    await updateDoc(conversationRef, {
      tabs: sanitizedValue,
    });
  } catch (error) {
    console.error('Error updating conversation:', error);
    // You could implement retry logic or user notification here
  }
}

interface PropsBasicDataProvider {
  children: React.ReactNode;
}

interface ContextDataModel {
  tabs: CaseModel[];
  addRuling: (ruling: CaseModel, id: string | undefined) => void;
  setRulingList: (rulings: CaseModel[]) => void;
  removeRuling: (ruling: CaseModel, id: string | undefined) => void;
  displayed: CaseModel | 'sources' | 'search';
  setDisplayedCase: (ruling: CaseModel | 'sources' | 'search') => void;
  modifyRuling: (ruling: CaseModel, id: string | undefined) => void;
  addSearchPanel: (lawUrl: string, entityPath: string) => void;
}

export const TabsContext: React.Context<ContextDataModel> = createContext({
  tabs: [] as CaseModel[],
  addRuling: (error: any, id: string | undefined) => {},
  setRulingList: (error: any) => {},
  removeRuling: (error: any, id: string | undefined) => {},
  displayed: 'search' as CaseModel | 'sources' | 'search',
  setDisplayedCase: (chosenCase: CaseModel | 'sources' | 'search') => {},
  modifyRuling: (error: any, id: string | undefined) => {},
  addSearchPanel: (lawUrl: string, entityPath: string) => {},
});

export const TabsProvider: any = ({ children }: PropsBasicDataProvider) => {
  const [localTabs, setLocalTabs] = useState<CaseModel[]>([]);
  const [localDisplayed, setLocalDisplayed] = useState<
    CaseModel | 'sources' | 'search'
  >('search');

  const addRuling = (ruling: CaseModel, id: string | undefined) => {
    setLocalTabs((prevRulings: CaseModel[]) => {
      if (prevRulings.some((r: CaseModel) => r.url === ruling.url)) {
        return prevRulings;
      } else {
        if (id) {
          updateConversationField(id, [...prevRulings, ruling]);
        }
        return [...prevRulings, ruling];
      }
    });
  };

  const modifyRuling = (ruling: CaseModel, id: string | undefined) => {
    setLocalTabs((rulingsList: CaseModel[]) => {
      let newList = rulingsList.map((current: any) => {
        if (current.url === ruling.url) {
          return ruling;
        }
        return current;
      });
      if (id) {
        updateConversationField(id, [...newList]);
      }
      return [...newList];
    });
  };

  const addSearchPanel = (lawUrl: string, entityPath: string) => {
    setLocalTabs((prevRulings: CaseModel[]) => {
      let t = [...prevRulings];
      let index = prevRulings.findIndex((r: CaseModel) =>
        lawUrl.includes(r.url)
      );
      if (index !== -1) {
        t.splice(index + 1, 0, {
          type: 'search',
          uniqueKey: lawUrl,
          url: lawUrl,
          entityPath,
        });
      } else {
        t.push({ type: 'search', uniqueKey: lawUrl, url: lawUrl, entityPath });
      }
      return [...t];
    });
  };

  const setRulingList = (rulings: CaseModel[]) => {
    setLocalTabs(rulings);
  };

  const removeRuling = (ruling: any, id: string | undefined) => {
    setLocalTabs((rulingsList: CaseModel[]) => {
      let newList = rulingsList.filter(
        (current: any) => current.url !== ruling.url
      );
      if (id) {
        updateConversationField(id, [...newList]);
      }
      return [...newList];
    });
  };

  const setDisplayedCase = (r: CaseModel | 'sources' | 'search') => {
    setLocalDisplayed(r);
    return r;
  };

  const contextValue = {
    tabs: localTabs,
    addRuling: useCallback(
      (error: any, id: string | undefined) => addRuling(error, id),
      []
    ),
    setRulingList: useCallback((error: any) => setRulingList(error), []),
    removeRuling: useCallback(
      (error: any, id: string | undefined) => removeRuling(error, id),
      []
    ),
    displayed: localDisplayed,
    setDisplayedCase: useCallback(
      (chosenCase: any) => setDisplayedCase(chosenCase),
      []
    ),
    modifyRuling: useCallback(
      (error: any, id: string | undefined) => modifyRuling(error, id),
      []
    ),
    addSearchPanel: useCallback(
      (lawUrl: string, entityPath: string) =>
        addSearchPanel(lawUrl, entityPath),
      []
    ),
  };

  return (
    <TabsContext.Provider value={contextValue}>{children}</TabsContext.Provider>
  );
};
