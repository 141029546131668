import { Box, Modal } from '@mui/material';
import colors from '../../../configs/colorConfig';
import MainRightPanel from '../../components/organisms/main-right-panel/MainRightPanel';
import ChatFeed from '../../components/organisms/chat-feed/ChatFeed';
import { useCallback, useEffect, useState } from 'react';
import ShortcutPanel from '../../../common/shortcut-panel/ShortcutPanel';
import { useParams } from 'react-router';
import { LawModel } from '../../../models/LawModel';
import useTabs from '../../../hooks/useTabs';
import Joyride from 'react-joyride';
import { steps } from '../../instructions/steps';
import {
  collection,
  doc,
  getDocs,
  query,
  where,
  updateDoc,
} from 'firebase/firestore';
import { auth, firestore } from '../../../configs/firebaseConfig';
import useInstruction from '../../../hooks/useInstruction';
import DashboardTemplate from '../../components/templates/dashboard-template/DashboardTemplate';
import { getCssVariable } from 'styles/getVariables';
import { getUidByEmail } from 'api/get-uid';
import { RulingModel } from 'models/RulingModel';
import LoadingWrapper from 'common/loading-wrapper/LoadingWrapper';
import { useTranslation } from 'react-i18next';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid lightGray',
  borderRadius: '10px',
  boxShadow: 24,
  p: 2,
};

const DashboardPage = () => {
  const [sources, setSources] = useState<LawModel[]>([]);
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [cmdKeyValue, setCmdKeyValue] = useState<boolean>(false);
  const [focusOnInput, setFocusOnInput] = useState<boolean>(false);
  const [sourcesLoading, setSourcesLoading] = useState<boolean>(false);
  const [sourceRulings, setSourceRulings] = useState<RulingModel[]>([]);
  const { id } = useParams();
  const { setDisplayedCase } = useTabs();
  const [show, setShow] = useState<boolean>(false);
  const { showInstruction, setShowInstruction } = useInstruction();

  useEffect(() => {
    if (id != null) {
      setDisplayedCase('sources');
    } else {
      setDisplayedCase('search');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (showInstruction) {
      setShow(true);
      setShowInstruction(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInstruction]);

  const findUserData = async () => {
    let userData = null;
    const querySnapshot = await getDocs(
      query(
        collection(firestore, 'Users'),
        where('email', '==', auth.currentUser?.email)
      )
    );
    querySnapshot.forEach((doc) => {
      userData = doc.data();
      if (userData?.showInstructions == null) {
        setShow(true);
      }
    });
  };

  const updateUserInstructionStatus = async () => {
    if (auth.currentUser?.email != null) {
      const uid = await getUidByEmail('Users', auth.currentUser?.email);
      if (uid) {
        const updateDocRef = doc(firestore, 'Users', uid);
        await updateDoc(updateDocRef, {
          showInstructions: false,
        });
      }
    }
  };

  useEffect(() => {
    findUserData();
  }, []);

  const checkKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.metaKey && event.key === '/') {
        setOpenModal(true);
        setCmdKeyValue(true);
      } else if (event.ctrlKey && event.key === '/') {
        setOpenModal(true);
        setCmdKeyValue(false);
      } else if (event.key === '/') {
        event.preventDefault();
        setFocusOnInput(!focusOnInput);
      }
    },
    [focusOnInput]
  );

  useEffect(() => {
    window.addEventListener('keydown', checkKeyPress);

    return () => {
      window.removeEventListener('keydown', checkKeyPress);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkKeyPress]);

  return (
    <>
      <Joyride
        steps={steps}
        run={show}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        locale={{
          skip: 'Pomiń',
          last: 'Zakończ',
          next: 'Dalej',
          back: 'Wstecz',
        }}
        callback={(data) => {
          if (data.action === 'reset') {
            setShow(false);
            updateUserInstructionStatus();
          }
        }}
        styles={{
          options: {
            primaryColor: colors.primary,
            zIndex: 10000,
            backgroundColor: getCssVariable('--background-color-secondary'),
            textColor: getCssVariable('--text-primary'),
          },
          tooltipContent: {
            whiteSpace: 'pre-wrap',
          },
        }}
      />
      <DashboardTemplate
        leftPanel={
          <ChatFeed
            onSourcesChange={(values: LawModel[], rulings: RulingModel[]) => {
              setSources(values);
              setSourceRulings(rulings);
            }}
            onSourcesLoading={(v: boolean) => setSourcesLoading(v)}
            focusOnInput={focusOnInput}
            chatId={id ?? undefined}
            onSnRulingsChange={(rulings: RulingModel[]) => {
              // Handle Supreme Court rulings if needed
              // For now, we can just log them
              console.log('Supreme Court rulings:', rulings);
            }}
          />
        }
        rightPanel={
          <div
            className="rightSidePanel"
            style={{
              height: '100%',
              width: '100%',
              backgroundColor: getCssVariable('--background-color-secondary'),
            }}
          >
            <LoadingWrapper
              isLoading={sourcesLoading && sourceRulings.length === 0}
              description={t(
                'dashboard:loadingSearch.searchArticlesAndRulings'
              )}
              loadingHeight={'400px'}
            >
              <MainRightPanel
                sources={sources}
                sourceRulings={sourceRulings}
                sourcesLoading={sourcesLoading}
              />
            </LoadingWrapper>
          </div>
        }
      />
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...modalStyle, width: 600 }}>
          <ShortcutPanel
            cmdKeyUsed={cmdKeyValue}
            onClose={() => setOpenModal(focusOnInput)}
          />
        </Box>
      </Modal>
    </>
  );
};

export default DashboardPage;
