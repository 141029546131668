import { Button, CircularProgress, Snackbar, Alert } from '@mui/material';
import { auth } from 'configs/firebaseConfig';
import { DocumentModel } from 'module-private/models/DocumentModel';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getCssVariable } from 'styles/getVariables';
// import { parseConversationalMarkdown } from '../chat-feed/components/bot-response/BotResponse';
import LoadingDocumentPanel, {
  StatusEnum,
} from './components/LoadingDocumentPanel';
import { useTranslation } from 'react-i18next';
import DocumentService, { ModifyOutput } from '../../../../api/documentService';

type Props = {
  documentModel: DocumentModel | null;
  content: string;
  loading: boolean;
  status: StatusEnum | null;
  modifiedContent: ModifyOutput | null;
  loadingText: string;
};

export const formatDocumentForDisplay = (
  originalDocument: string,
  modifiedDocument: string,
  ids: string[] = [],
  newFragments: string[] = []
): { html: string; comments: { element: string; text: string }[] } => {
  // Helper function to extract styles from HTML
  const extractStyles = (html: string): string => {
    const styleRegex = /<style[^>]*>([\s\S]*?)<\/style>/gi;
    let match;
    let styles = '';

    while ((match = styleRegex.exec(html)) !== null) {
      styles += match[1];
    }

    return styles;
  };

  // Helper function to extract body content
  const extractBodyContent = (html: string): string => {
    const bodyRegex = /<body[^>]*>([\s\S]*?)<\/body>/i;
    const match = bodyRegex.exec(html);
    return match ? match[1] : html;
  };

  // Extract HTML comments from the document
  const extractComments = (html: string): { element: string; text: string }[] => {
    const comments: { element: string; text: string }[] = [];
    
    // Step 1: Find all section headings and their IDs
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    
    // Find all elements with section notation "§ X."
    const sectionElements: Array<{id: string, number: number, element: Element}> = [];
    
    const sections = tempDiv.querySelectorAll('*');
    sections.forEach(section => {
      if (section.textContent && section.textContent.includes('§')) {
        // Try to extract the section number
        const sectionMatch = section.textContent.match(/§\s*(\d+)/);
        if (sectionMatch) {
          const sectionNumber = parseInt(sectionMatch[1]);
          
          // Ensure each section has an ID based on its number
          if (!section.id) {
            section.id = `legal-section-${sectionNumber}`;
          }
          
          sectionElements.push({
            id: section.id,
            number: sectionNumber,
            element: section
          });
        }
      }
    });
    
    // Sort by section number
    sectionElements.sort((a, b) => a.number - b.number);
    
    // Step 2: Extract all comments from HTML
    const allComments: Array<{index: number, text: string}> = [];
    const commentRegex = /<!--([\s\S]*?)-->/g;
    let commentMatch;
    
    while ((commentMatch = commentRegex.exec(html)) !== null) {
      allComments.push({
        index: commentMatch.index,
        text: commentMatch[1].trim()
      });
    }
    
    // Step 3: Associate each comment with the section that follows it
    for (let i = 0; i < sectionElements.length; i++) {
      const section = sectionElements[i];
      
      // Find the position of this section in the HTML
      const sectionRegex = new RegExp(`§\\s*${section.number}[^\\d]`);
      const sectionMatch = html.match(sectionRegex);
      
      if (sectionMatch && sectionMatch.index !== undefined) {
        const sectionPosition = sectionMatch.index;
        
        // Find comments that appear before this section
        // but after the previous section (if any)
        let prevSectionPosition = 0;
        if (i > 0) {
          const prevSection = sectionElements[i-1];
          const prevSectionRegex = new RegExp(`§\\s*${prevSection.number}[^\\d]`);
          const prevSectionMatch = html.match(prevSectionRegex);
          if (prevSectionMatch && prevSectionMatch.index !== undefined) {
            prevSectionPosition = prevSectionMatch.index;
          }
        }
        
        // Find comments that belong to the PREVIOUS section
        // (they appear after the previous section marker but before this section marker)
        if (i > 0) {
          const relevantComments = allComments.filter(comment => 
            comment.index > prevSectionPosition && comment.index < sectionPosition
          );
          
          if (relevantComments.length > 0) {
            // Use the last comment (closest to this section) for the previous section
            const closestComment = relevantComments[relevantComments.length - 1];
            
            comments.push({
              element: sectionElements[i-1].id,
              text: closestComment.text
            });
          }
        }
        
        // For the last section, also check for comments after it
        if (i === sectionElements.length - 1) {
          const commentsAfterLastSection = allComments.filter(comment => 
            comment.index > sectionPosition
          );
          
          if (commentsAfterLastSection.length > 0) {
            comments.push({
              element: section.id,
              text: commentsAfterLastSection[0].text
            });
          }
        }
      }
    }
    
    return comments;
  };

  // If no modified document, process the original
  if (modifiedDocument === '') {
    // Extract styles and body content
    const extractedStyles = extractStyles(originalDocument);
    const bodyContent = extractBodyContent(originalDocument);
    
    // Extract comments
    const comments = extractComments(originalDocument);

    // Apply placeholder styling to body content
    const styledContent = bodyContent.replace(
      /{([^}]+)}/g,
      '<span style="color: var(--placeholder-color); font-weight: 500;">$&</span>'
    );

    // Combine styles and content
    return {
      html: `<style>${extractedStyles}</style>${styledContent}`,
      comments,
    };
  }

  // For modified document
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = modifiedDocument;
  
  // Extract comments
  const comments = extractComments(modifiedDocument);

  // Extract styles from the original document
  const extractedStyles = extractStyles(originalDocument);

  // Apply green background to all modified elements
  ids.forEach((id) => {
    const elements = tempDiv.querySelectorAll(`#${CSS.escape(id)}`);
    elements.forEach((element) => {
      (element as HTMLElement).style.backgroundColor = getCssVariable(
        '--document-highlight-background'
      );
    });
  });

  // Apply placeholder styling to content
  const allElements = tempDiv.querySelectorAll('*');
  allElements.forEach((element) => {
    if (
      element.innerHTML &&
      element.tagName !== 'STYLE' &&
      element.tagName !== 'SCRIPT'
    ) {
      element.innerHTML = element.innerHTML.replace(
        /{([^}]+)}/g,
        '<span style="color: var(--placeholder-color); font-weight: 500;">$&</span>'
      );
    }
  });

  // Add a single "Accept all" button at the top if there are modifications
  if (ids.length > 0) {
    const acceptButton = document.createElement('button');
    acceptButton.textContent = 'Akceptuj wszystkie'; // "Accept all" in Polish
    acceptButton.className = 'accept-all-modifications-button';
    acceptButton.style.position = 'relative';
    acceptButton.style.marginBottom = '20px';
    acceptButton.style.fontSize = '14px';
    acceptButton.style.padding = '8px 16px';
    acceptButton.style.backgroundColor = '#4CAF50';
    acceptButton.style.color = 'white';
    acceptButton.style.border = 'none';
    acceptButton.style.borderRadius = '4px';
    acceptButton.style.cursor = 'pointer';
    acceptButton.style.boxShadow = '0 2px 4px rgba(0,0,0,0.2)';
    const firstElemnt = tempDiv.querySelectorAll(`#${CSS.escape(ids[0])}`);
    // Insert the button at the beginning of the document
    tempDiv.insertBefore(acceptButton, firstElemnt[0] ?? tempDiv.firstChild);
  }

  // Get the modified HTML and combine with extracted styles
  const modifiedHtml = tempDiv.innerHTML;
  return {
    html: `<style>${extractedStyles}</style>${modifiedHtml}`,
    comments,
  };
};

// Function declaration for comments handling (does nothing for now)
export const displayComments = (
  comments: { element: string; text: string }[]
) => {
  // TODO: Later, implement to display comments as simple modals of text
};

const escapeHtml = (unsafe: string) => {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
};

export const attachCommentTooltips = (
  container: HTMLElement,
  comments: { element: string; text: string }[],
  translateFn?: (key: string, fallback: string) => string
) => {
  // Create tooltip element if it doesn't exist
  let tooltip = document.getElementById('comment-tooltip');
  if (!tooltip) {
    tooltip = document.createElement('div');
    tooltip.id = 'comment-tooltip';
    tooltip.style.position = 'absolute';
    tooltip.style.backgroundColor = 'white';
    tooltip.style.border = '1px solid black';
    tooltip.style.borderRadius = '8px';
    tooltip.style.padding = '10px';
    tooltip.style.zIndex = '1000';
    tooltip.style.display = 'none';
    tooltip.style.boxShadow = '0 2px 8px rgba(0,0,0,0.2)';
    tooltip.style.maxWidth = '300px';
    document.body.appendChild(tooltip);
  }

  // Process all comments
  comments.forEach(comment => {
    // Safely escape the ID for querySelector
    const safeElementId = CSS.escape(comment.element);
    const element = container.querySelector(`#${safeElementId}`);
    
    if (element) {
      // Get the section number for the title
      let sectionNumber = '';
      const sectionMatch = element.textContent?.match(/§\s*\d+/);
      if (sectionMatch) {
        sectionNumber = sectionMatch[0];
      }
      
      // Position is needed for proper event handling
      (element as HTMLElement).style.position = 'relative';
      
      // Prepare tooltip content
      const tooltipContent = `
        <div style="display: flex; flex-direction: column; gap: 8px;">
          <div style="font-weight: bold; color: var(--accent-color, #4285f4); border-bottom: 1px solid #eee; padding-bottom: 4px;">
            ${sectionNumber ? `${sectionNumber} - ` : ''}${translateFn ? translateFn('document.comment', 'Komentarz') : 'Comment'}
          </div>
          <div>${escapeHtml(comment.text)}</div>
        </div>
      `;
      
      // Add hover event listeners
      const showTooltip = () => {
        if (tooltip) {
          tooltip.innerHTML = tooltipContent;
          const rect = element.getBoundingClientRect();
          
          // Position the tooltip next to the paragraph
          const viewportWidth = window.innerWidth;
          const tooltipWidth = 300; // max-width of tooltip
          
          // If there's more space on the right, show tooltip there, otherwise show on the left
          let left;
          if (rect.right + tooltipWidth + 20 < viewportWidth) {
            left = rect.right + window.scrollX + 10; // Right side
          } else {
            left = rect.left + window.scrollX - tooltipWidth - 10; // Left side
          }
          
          const top = rect.top + window.scrollY; // Align with top of element
          
          tooltip.style.top = `${top}px`;
          tooltip.style.left = `${left}px`;
          tooltip.style.display = 'block';
        }
      };
      
      const hideTooltip = () => {
        if (tooltip) {
          tooltip.style.display = 'none';
        }
      };
      
      // Add event listeners to the element
      element.addEventListener('mouseover', showTooltip);
      element.addEventListener('mouseout', hideTooltip);
    }
  });
};

const DocumentDisplay = ({
  modifiedContent,
  documentModel,
  content,
  loading,
  status,
  loadingText,
}: Props) => {
  const { id } = useParams();
  const [response, setResponse] = useState<string>('');
  const contentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Safer version of downloadFile that handles potential undefined values
  const downloadFile = async (
    doc: DocumentModel | null,
    useComments: boolean = true
  ) => {
    try {
      // Early validation for required values
      if (!id) {
        console.warn('Cannot download file: No document ID available');
        return;
      }

      const token = await auth.currentUser?.getIdToken();
      if (!token) {
        console.warn('Cannot download file: Not authenticated');
        return;
      }

      // TypeScript doesn't recognize that we've guarded against id being undefined
      // so we need to use a type assertion to satisfy the compiler
      const documentId = String(id);

      // At this point we know id and token are valid
      const blob = await DocumentService.downloadDocument(token, documentId, {
        useComments,
      });

      let prefix;
      switch (process.env.REACT_APP_LANGUAGE) {
        case 'fr':
          prefix = 'LaLoire';
          break;
        case 'pl':
          prefix = 'PrawMi';
          break;
        default:
          throw new Error(
            `Unsupported language: ${process.env.REACT_APP_LANGUAGE}`
          );
      }
      const fallbackFilename = `${prefix}-${new Date()
        .toLocaleString('pl-PL', {
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        })
        .replace(/[/,.\s:]/g, '-')}.docx`;

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fallbackFilename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const handleAcceptAllModifications = async () => {
    try {
      if (!id) {
        console.warn('Cannot accept modifications: No document ID available');
        return;
      }

      const token = await auth.currentUser?.getIdToken();
      if (!token) {
        console.warn('Cannot accept modifications: Not authenticated');
        return;
      }

      const result = await DocumentService.acceptModification(token, id);
      if (result && result.document_formatted) {
        setResponse(result.document_formatted);
        setShowSuccessMessage(true);
      }
    } catch (error) {
      console.error('Error accepting all modifications:', error);
    }
  };

  // Handle accept modification click
  const handleAcceptModification = async (fragmentId: string) => {
    try {
      if (!id) {
        console.warn('Cannot accept modification: No document ID available');
        return;
      }

      const token = await auth.currentUser?.getIdToken();
      if (!token) {
        console.warn('Cannot accept modification: Not authenticated');
        return;
      }

      // Call the API to accept the modification
      const result = await DocumentService.acceptModification(
        token,
        id,
        fragmentId
      );

      // Update the document with the accepted modifications
      if (result && result.document_formatted) {
        // Hide all accept buttons for this modification
        const buttonElements = document.querySelectorAll(
          `.accept-modification-button[data-modification-id="${fragmentId}"]`
        );
        buttonElements.forEach((button) => {
          (button as HTMLElement).style.display = 'none';
        });

        // Update the document content
        setResponse(result.document_formatted);

        // Show success message
        setShowSuccessMessage(true);
      }
    } catch (error) {
      console.error('Error accepting modification:', error);
    }
  };

  // Add click event handlers after the content is rendered
  useEffect(() => {
    if (!contentRef.current) return;

    const handleClick = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();

      const button = e.currentTarget as HTMLElement;
      if (button.classList.contains('accept-all-modifications-button')) {
        handleAcceptAllModifications();
      } else if (button.classList.contains('accept-modification-button')) {
        const modificationId = button.getAttribute('data-modification-id');
        if (modificationId) {
          handleAcceptModification(modificationId);
        }
      }
    };

    const acceptButtons = contentRef.current.querySelectorAll(
      '.accept-all-modifications-button, .accept-modification-button'
    );
    acceptButtons.forEach((button) => {
      button.addEventListener('click', handleClick);
    });

    if (acceptButtons.length > 0) {
      acceptButtons[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    return () => {
      acceptButtons.forEach((button) => {
        button.removeEventListener('click', handleClick);
      });
    };
    // eslint-disable-next-line
  }, [response, id]);

  // Handle closing the success message
  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false);
  };

  useEffect(() => {
    const contentToDisplay =
      content ||
      documentModel?.formattedContent ||
      documentModel?.content ||
      '';
    const { html, comments } = formatDocumentForDisplay(
      contentToDisplay,
      modifiedContent?.modifiedDocument || '',
      modifiedContent?.ids || [],
      modifiedContent?.newFragments || []
    );
    setResponse(html);

    if (contentRef.current) {
      attachCommentTooltips(contentRef.current, comments, t);
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [content, documentModel, modifiedContent, t]);

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {status === null ? (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <CircularProgress size={30} />
                <p>{loadingText}</p>
              </div>
            </div>
          ) : (
            <div>
              <LoadingDocumentPanel status={status} />
            </div>
          )}
        </div>
      ) : (
        <>
          <div style={{ flex: 1, position: 'relative' }}>
            <div
              ref={contentRef}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflowY: 'auto',
                paddingLeft: '50px',
                paddingRight: '50px',
              }}
            >
              {/* <pre style={{ whiteSpace: 'pre-wrap', fontFamily: 'inherit' }}>
                {content ?? documentModel?.content}
              </pre> */}

              <div
                style={{
                  fontSize: '14px',
                  color: getCssVariable('--text-primary'),
                }}
                className="document-content"
                dangerouslySetInnerHTML={{ __html: response }}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              padding: '20px',
              gap: '16px',
            }}
          >
            <Button
              variant="contained"
              onClick={() => downloadFile(documentModel)}
              sx={{ minWidth: '200px' }}
            >
              {t('dashboard:documentsRightPanel.downloadButton')}
            </Button>
            <Button
              variant="contained"
              onClick={() => downloadFile(documentModel, false)}
              sx={{ minWidth: '200px' }}
            >
              {t('dashboard:documentsRightPanel.downloadWithoutCommentsButton')}
            </Button>
          </div>

          {/* Success message */}
          <Snackbar
            open={showSuccessMessage}
            autoHideDuration={6000}
            onClose={handleCloseSuccessMessage}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert
              onClose={handleCloseSuccessMessage}
              severity="success"
              variant="filled"
            >
              {t(
                'dashboard:documentsRightPanel.modificationAccepted',
                'Modyfikacja została zaakceptowana'
              )}
            </Alert>
          </Snackbar>

          {/* No need for custom style overrides anymore as we're preserving backend styles */}
        </>
      )}
    </div>
  );
};

export default DocumentDisplay;
