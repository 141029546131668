import { format } from 'date-fns';
import { CaseModel } from '../module-private/models/CaseModel';

/** Model reprezentujący orzeczenie*/
export interface RulingModel extends CaseModel {
  startIndex: number;
  text?: string;
  endIndex: number;
  judgmentDate?: string;
  judgmentType?: string;
  division?: string;
  courtType?: string;
}

export const rulingFromMap = (data: RulingBackendModel): RulingModel => {
  const judgmentDate = data.judgment_date;
  console.log('judgmentDate', judgmentDate);
  let date: Date | undefined;
  if (judgmentDate && judgmentDate.seconds) {
    const timestamp = judgmentDate.seconds * 1000;
    date = new Date(timestamp);
  } else if (judgmentDate) {
    date = new Date(judgmentDate);
  }

  return {
    uniqueKey: data.link,
    url: data.link,
    type: 'ruling',
    text: data.text_excerpt
      .replace(/[\n\r]/g, ' ')
      .split(' ')
      .join(' '),
    title: data.court_case,
    startIndex: data.start_index ?? 0,
    endIndex: data.end_index ?? 0,
    division: data.division,
    courtType: data.court_type,
    judgmentDate: date ? format(date, 'dd.MM.yyyy') : undefined,
  };
};

/** Model reprezentujący orzeczenie  w formacie zwracanym przez backend */
interface RulingBackendModel {
  start_index: number;
  end_index: number;
  link: string;
  text_excerpt: string;
  court_case: string;
  division: string;
  judgment_date: any;
  court_type: string;
}
