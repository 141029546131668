import { Link, LinkProps, styled } from '@mui/material';
import React from 'react';
import colors from '../../../../../configs/colorConfig';
// import { HashLink } from 'react-router-hash-link';
import { auth } from '../../../../../configs/firebaseConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import { PathType, routesManager } from 'routes/routes';
import { Box } from '@mui/material';

const StyledLink = styled(Link)<LinkProps>({
  color: 'gray',
  textDecoration: 'none',
  ':&hover': {
    color: 'blue-900',
  },
});

// const StyledButton = styled(Button)<ButtonProps>({
//     textDecoration: "none",
//     textTransform: "none",
//     borderRadius: "0.75rem",
//     fontSize: "1rem",
//     backgroundColor: "white",
//     '&:hover': {
//         backgroundColor: "white",
//     },
// })
// dummy

interface NavLinksProps {
  mobile?: boolean;
  onClose?: () => void;
}

const NavLinks: React.FC<NavLinksProps> = ({ mobile = false, onClose }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isBlogPage = location.pathname.substring(0, 5) === '/blog';
  const isActualizationsPage =
    location.pathname.substring(0, 17) === '/aktualizacje';

  const navItems: { label: string; path: PathType }[] = [];

  navItems.push({ label: 'Kontakt', path: PathType.CONTACT });
  navItems.push({ label: 'Blog', path: PathType.BLOG });
  navItems.push({ label: 'Aktualizacje', path: PathType.ACTUALIZATIONS });
  if (auth.currentUser) {
    navItems.push({ label: 'Czat', path: PathType.CHAT });
  } else {
    navItems.push({ label: 'Logowanie', path: PathType.LOGIN });
    navItems.push({ label: 'Wypróbuj demo', path: PathType.REGISTRATION });
  }

  const handleMobileNav = (path: PathType) => {
    navigate(routesManager.getPath(path));
    onClose?.();
  };

  return (
    <>
      {!mobile && (
        <>
          {!isBlogPage && !isActualizationsPage && (
            <>
              <StyledLink
                style={{ color: 'lightGray' }}
                className="px-4 font-extrabold text-gray-600 hover:text-blue-900"
                href="#kontakt"
              >
                Kontakt
              </StyledLink>
              <StyledLink
                style={{ color: 'lightGray', border: '1px solid lightGray' }}
                className="inline-flex items-center justify-center w-auto px-6 py-2 shadow-xl rounded-xl"
                href="blog"
              >
                Blog
              </StyledLink>
              <StyledLink
                style={{ color: 'lightGray', border: '1px solid lightGray' }}
                className="inline-flex items-center justify-center w-auto px-6 py-2 shadow-xl rounded-xl"
                href="aktualizacje"
              >
                Aktualizacje
              </StyledLink>
            </>
          )}

          {auth.currentUser ? (
            <>
              <StyledLink
                sx={{ color: colors.primary, background: 'white' }}
                className="inline-flex items-center justify-center w-auto px-6 py-2 shadow-xl rounded-xl"
                href="czat"
              >
                Czat
              </StyledLink>
            </>
          ) : (
            <>
              <StyledLink
                sx={{ color: 'lightGray', border: '1px solid lightGray' }}
                className="inline-flex items-center justify-center w-auto px-6 py-2 shadow-xl rounded-xl"
                href={routesManager.getPath(PathType.LOGIN)}
              >
                Logowanie
              </StyledLink>
              <StyledLink
                sx={{ color: colors.primary, background: 'white' }}
                className="inline-flex items-center justify-center w-auto px-6 py-2 shadow-xl rounded-xl"
                href="rejestracja"
              >
                Wypróbuj demo
              </StyledLink>
            </>
          )}
        </>
      )}
      {mobile &&
        navItems.map((item, index) => (
          <Box
            key={index}
            component="button"
            onClick={() => handleMobileNav(item.path)}
            sx={{
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              color: 'primary.main',
              fontWeight: 500,
              fontSize: '1.125rem',
              padding: '14px 16px',
              textAlign: 'center',
              width: '100%',
              '&:hover': {
                textDecoration: 'underline',
              },
              borderBottom: '1px solid rgba(0,0,0,0.05)',
            }}
          >
            {item.label}
          </Box>
        ))}
    </>
  );
};

export default NavLinks;
