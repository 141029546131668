import { Box, Modal, Typography, Paper, Fade, keyframes } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getCssVariable } from 'styles/getVariables';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DescriptionIcon from '@mui/icons-material/Description';
import { useState, useEffect } from 'react';

// Define animations
const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(var(--primary-rgb), 0.4);
  }
  70% {
    transform: scale(1.02);
    box-shadow: 0 0 0 20px rgba(var(--primary-rgb), 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(var(--primary-rgb), 0);
  }
`;

const floatIcon = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onFileDropped: (file: File) => void;
};

const FileUploadModal = ({ isOpen, onClose, onFileDropped }: Props) => {
  const { t } = useTranslation();
  const [isOverDropZone, setIsOverDropZone] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  // Show the modal immediately without delay
  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [isOpen]);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Visual feedback when the user is over the drop zone
    setIsOverDropZone(true);
    
    // Set the drop effect
    e.dataTransfer.dropEffect = 'copy';
  };
  
  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOverDropZone(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOverDropZone(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      onFileDropped(file);
      onClose();
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="file-upload-modal"
      aria-describedby="drop-file-here-to-upload"
      BackdropProps={{
        onClick: () => onClose(),
        sx: { 
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          backdropFilter: 'blur(5px)'
        }
      }}
      style={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
      }}
    >
      <Fade in={isOpen} timeout={300}>
        <Paper
          elevation={10}
          sx={{
            width: '90%',
            maxWidth: '600px',
            height: '400px',
            bgcolor: 'var(--background-color-primary)',
            border: isOverDropZone 
              ? '4px solid var(--primary)' 
              : '4px dashed var(--border-color)',
            borderRadius: '16px',
            boxShadow: 24,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 5,
            transition: 'all 0.3s ease-in-out',
            animation: isOverDropZone ? 'none' : `${pulse} 2s infinite`,
            '&:hover': {
              boxShadow: '0 0 30px rgba(var(--primary-rgb), 0.5)',
            },
            zIndex: 9999
          }}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              marginBottom: 4,
              animation: `${floatIcon} 3s ease-in-out infinite`
            }}
          >
            <DescriptionIcon
              sx={{ 
                fontSize: 80,
                color: isOverDropZone 
                  ? getCssVariable('--primary') 
                  : getCssVariable('--text-primary'),
                marginRight: 2,
                transition: 'color 0.2s ease-in-out',
              }}
            />
            <CloudUploadIcon 
              sx={{ 
                fontSize: 100, 
                color: isOverDropZone 
                  ? getCssVariable('--primary') 
                  : getCssVariable('--text-primary'),
                transition: 'color 0.2s ease-in-out',
                marginLeft: 2
              }} 
            />
          </Box>
          
          <Typography 
            variant="h4"
            sx={{ 
              textAlign: 'center',
              color: isOverDropZone 
                ? getCssVariable('--primary') 
                : getCssVariable('--text-primary'),
              mb: 3,
              fontWeight: 'bold',
              transition: 'color 0.2s ease-in-out'
            }}
          >
            {t('dashboard:fileUpload.dropHere')}
          </Typography>
          
          <Typography 
            variant="h6"
            sx={{ 
              textAlign: 'center',
              color: getCssVariable('--text-secondary'),
              maxWidth: '90%',
              mb: 4
            }}
          >
            {t('dashboard:fileUpload.dropFileToSend')}
          </Typography>
          
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 4,
              marginTop: 2
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              opacity: 0.9
            }}>
              <InsertDriveFileIcon sx={{ fontSize: 40, mb: 1 }} />
              <Typography variant="subtitle1">.doc</Typography>
            </Box>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              opacity: 0.9
            }}>
              <InsertDriveFileIcon sx={{ fontSize: 40, mb: 1 }} />
              <Typography variant="subtitle1">.docx</Typography>
            </Box>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              opacity: 0.9
            }}>
              <InsertDriveFileIcon sx={{ fontSize: 40, mb: 1 }} />
              <Typography variant="subtitle1">.pdf</Typography>
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default FileUploadModal; 