import axios, { isAxiosError } from 'axios';
import axiosInstance from './axios';
import { DocumentModel } from '../module-private/models/DocumentModel';

/**
 * Document status as returned by the API
 */
export interface DocumentStatus {
  status:
    | 'pending'
    | 'processing'
    | 'done'
    | 'error'
    | 's1_generation'
    | string;
  message?: string;
  progress?: number;
  done?: boolean;
}

export interface ModifyOutput {
  modifiedDocument: string;
  ids: string[];
  newFragments: string[];
}

/**
 * Document API error with additional details
 */
export class DocumentServiceError extends Error {
  public status?: number;
  public endpoint: string;

  constructor(message: string, endpoint: string, status?: number) {
    super(message);
    this.name = 'DocumentServiceError';
    this.endpoint = endpoint;
    this.status = status;
  }
}

/**
 * Document API Service
 *
 * A centralized service for all document-related API operations.
 * This provides a clean interface to interact with the document endpoints.
 */
export const DocumentService = {
  /**
   * Handle API errors consistently
   *
   * @param error Error object
   * @param endpoint API endpoint being called
   * @throws DocumentServiceError
   */
  handleError: (error: any, endpoint: string): never => {
    console.error(`Error in ${endpoint}:`, error);
    if (isAxiosError(error)) {
      const status = error.response?.status;
      const message = error.response?.data?.message || error.message;
      throw new DocumentServiceError(`API error (${status}): ${message}`, endpoint, status);
    }
    throw new DocumentServiceError(error.message || 'Unknown error occurred', endpoint);
  },

  /**
   * Generate a document from scratch (without file)
   * Uses web search/gov templates
   *
   * @param token Authentication token
   * @param question User message
   * @param conversationId Optional conversation ID
   * @returns Document ID
   */
  // generateDocument: async (
  //   token: string,
  //   question: string,
  //   generate: boolean,
  //   conversationId?: string
  // ): Promise<string> => {
  //   try {
  //     const query = {
  //       query: {
  //         document_id: conversationId,
  //         user_message: question,
  //         generate: generate,
  //       },
  //     };

  //     const response = await axiosInstance.post('documents/', query, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         accept: '*',
  //         'content-type': 'application/json',
  //       },
  //     });

  //     return response.data.document_id;
  //   } catch (error) {
  //     return DocumentService.handleError(error, 'generateDocument');
  //   }
  // },

  generateDocument: async (
    token: string,
    question: string,
    generate: boolean,
    conversationId?: string
  ): Promise<string> => {
    try {
      const query = {
        query: {
          document_id: conversationId,
          user_message: question,
          generate: generate,
        },
      };
      const response = await axiosInstance.post('documents/', query, {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: '*',
          'content-type': 'application/json',
        },
      });
      return response.data.document_id;
    } catch (error) {
      return DocumentService.handleError(error, 'generateDocument');
    }
  },

  /**
   * Upload a document to the server
   *
   * @param file File to upload
   * @param token Authentication token
   * @returns Document ID and formatted content
   */
  uploadDocument: async (
    file: File,
    token: string
  ): Promise<{ documentId: string; formattedContent: string }> => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      if (file.name) formData.append('filename', file.name);

      const response = await axiosInstance.post('/documents/upload', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          accept: '*',
        },
      });

      if (response.data && response.data.document_id) {
        return {
          documentId: response.data.document_id,
          formattedContent: response.data.document_formatted || '',
        };
      }
      throw new Error('No document_id returned from upload');
    } catch (error) {
      return DocumentService.handleError(error, 'uploadDocument');
    }
  },

  /**
   * Modify an uploaded document
   *
   * @param token Authentication token
   * @param question User message
   * @param uploadedFileId Document ID
   * @returns Document ID
   */
  modifyDocument: async (
    token: string,
    message: string,
    id: string
  ): Promise<ModifyOutput> => {
    const query = {
      query: {
        user_message: message,
        document_id: id,
      },
    };
    try {
      const response = await axiosInstance.post(`documents/modify`, query, {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: '*',
          'content-type': 'application/json',
        },
      });
      return {
        modifiedDocument: response.data.modified_document,
        ids: response.data.ids,
        newFragments: response.data.new_fragments,
      };
    } catch (error) {
      return DocumentService.handleError(error, 'modifyDocument');
    }
  },

  /**
   * Get the status of document generation
   *
   * @param token Authentication token
   * @param documentId Document ID
   * @returns Document status
   */
  getDocumentStatus: async (
    token: string,
    documentId: string
  ): Promise<DocumentStatus> => {
    try {
      const response = await axiosInstance.get(`documents/document/status/${documentId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      return DocumentService.handleError(error, 'getDocumentStatus');
    }
  },

  /**
   * Get the generated document content when status is "done"
   *
   * @param token Authentication token
   * @param documentId Document ID
   * @param onChunk Optional callback for chunked responses
   * @returns Document content
   */
  streamGenerateDocumentContent: async (
    token: string,
    documentId: string,
    onChunk?: (chunk: string) => void
  ): Promise<any> => {
    try {
      const baseUrl = axiosInstance.defaults.baseURL?.replace(/\/$/, '') || '';
      // Ensure we're using HTTPS in production to prevent mixed content
      const url = baseUrl.startsWith('http:') && window.location.protocol === 'https:' 
        ? `${baseUrl.replace('http:', 'https:')}/documents/generate/${documentId}`
        : `${baseUrl}/documents/generate/${documentId}`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'text/event-stream',
          'Cache-Control': 'no-cache',
        },
        credentials: 'same-origin',
      });

      if (!response.ok) {
        throw new DocumentServiceError(
          `HTTP error! status: ${response.status}`,
          'getDocumentContent',
          response.status
        );
      }

      // Process response based on whether we need to handle chunked data
      if (onChunk && response.body) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        let buffer = '';
        let accumulatedText = '';

        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;

          const text = decoder.decode(value, { stream: true });
          buffer += text;

          const lines = buffer.split('\n');
          buffer = lines.pop() || '';

          for (const line of lines) {
            if (line.startsWith('data: ')) {
              const chunk = line.slice(6);
              if (chunk === '[DONE]') continue;

              const formattedChunk = chunk.replace(/\\n/g, '\n');
              accumulatedText = formattedChunk;
              onChunk(formattedChunk);
            }
          }
        }

        // Process any remaining buffer content
        if (buffer.startsWith('data: ')) {
          const chunk = buffer.slice(6);
          if (chunk !== '[DONE]') {
            const formattedChunk = chunk.replace(/\\n/g, '\n');
            accumulatedText = formattedChunk;
            onChunk(formattedChunk);
          }
        }

        return accumulatedText;
      } else {
        // For non-streaming responses, process the text normally
        const text = await response.text();
        // Check if this is an SSE format and extract the content if needed
        if (text.startsWith('data: ')) {
          const content = text.slice(6).replace(/\\n/g, '\n');
          return content !== '[DONE]' ? content : '';
        }
        return text;
      }
    } catch (error) {
      return DocumentService.handleError(error, 'getDocumentContent');
    }
  },

  /**
   * Download the generated document
   *
   * @param token Authentication token
   * @param documentId Document ID
   * @param options Optional parameters
   * @returns Blob of the document
   */
  downloadDocument: async (
    token: string,
    documentId: string | undefined,
    options?: { useComments?: boolean }
  ): Promise<Blob> => {
    try {
      if (!documentId) {
        return DocumentService.handleError(new Error('Document ID is required'), 'downloadDocument');
      }
      const response = await axiosInstance.get(`documents/document-download/${documentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: '*',
          'content-type': 'application/json',
        },
        responseType: 'blob',
        params: { use_comments: options?.useComments },
      });
      return response.data;
    } catch (error) {
      return DocumentService.handleError(error, 'downloadDocument');
    }
  },

  /**
   * Stream the conversation chatbot reply concerning the generated document
   *
   * @param token Authentication token
   * @param documentId Document ID
   * @param onChunk Callback for handling streamed chunks
   * @returns Full response text
   */
  streamDocumentConversation: async (
    token: string,
    documentId: string,
    onChunk: (chunk: string) => void
  ): Promise<string> => {
    try {
      const baseUrl = axiosInstance.defaults.baseURL?.replace(/\/$/, '') || '';
      // Ensure we're using HTTPS in production to prevent mixed content
      const url = baseUrl.startsWith('http:') && window.location.protocol === 'https:' 
        ? `${baseUrl.replace('http:', 'https:')}/documents/reply/${documentId}`
        : `${baseUrl}/documents/reply/${documentId}`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'text/event-stream',
          'Cache-Control': 'no-cache',
        },
        credentials: 'same-origin',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (response.body) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        let buffer = '';
        let completeResponse = '';

        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;

          const text = decoder.decode(value, { stream: true });
          buffer += text;

          const lines = buffer.split('\n');
          buffer = lines.pop() || '';

          for (const line of lines) {
            if (line.startsWith('data: ')) {
              const chunk = line.slice(6);
              if (chunk === '[DONE]') continue;

              const formattedChunk = chunk.replace(/\\n/g, '\n');
              completeResponse += formattedChunk;
              onChunk(formattedChunk);
            }
          }
        }

        // Process any remaining buffer
        if (buffer.startsWith('data: ')) {
          const chunk = buffer.slice(6);
          if (chunk !== '[DONE]') {
            const formattedChunk = chunk.replace(/\\n/g, '\n');
            completeResponse += formattedChunk;
            onChunk(formattedChunk);
          }
        }

        return completeResponse;
      } else {
        throw new Error('Response body is null');
      }
    } catch (error) {
      return DocumentService.handleError(error, 'streamDocumentConversation');
    }
  },

  /**
   * Accept document modifications
   *
   * @param token Authentication token
   * @param documentId Document ID
   * @returns Document formatted content
   */
  acceptModification: async (
    token: string,
    documentId: string,
    htmlElementId?: string
  ): Promise<{ document_id: string; document_formatted: string }> => {
    try {
      const payload = { token, document_id: documentId };
      if (htmlElementId) (payload as any).html_element_id = htmlElementId;
      const response = await axiosInstance.post(`documents/accept-modification`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: '*',
          'content-type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      return DocumentService.handleError(error, 'acceptModification');
    }
  },
};

export default DocumentService;
