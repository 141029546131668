import { DocumentModel } from 'module-private/models/DocumentModel';
import DocumentDisplay from '../document-display/DocumentDisplay';
import ListOfDocuments from '../list-of-documents/ListOfDocuments';
import { useParams } from 'react-router-dom';
import { Button, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { firestore, auth } from 'configs/firebaseConfig';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
  updateDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { StatusEnum } from '../document-display/components/LoadingDocumentPanel';
import { useTranslation } from 'react-i18next';
import { ModifyOutput } from 'api/documentService';

type Props = {
  chosenDocument: DocumentModel | null;
  chosenContent: string;
  loadingDocuments: boolean;
  status: StatusEnum | null;
  modifiedContent: ModifyOutput | null;
  loadingText: string;
};

type MappedDocument = {
  id: string;
  lastModified: Date;
  content: string;
  messages: any[];
};

const StyledButton = styled(Button)({
  textTransform: 'none',
  '&.Mui-disabled': {
    color: 'var(--text-primary)',
  },
  marginRight: '10px',
  maxHeight: '26px',
});

const DocumentsRightPanel = ({
  chosenDocument,
  chosenContent,
  loadingDocuments,
  status,
  modifiedContent,
  loadingText,
}: Props) => {
  const { id } = useParams();
  const [showListOfDocuments, setShowListOfDocuments] = useState(false);
  const [documents, setDocuments] = useState<DocumentModel[]>([]);
  const [garbageDocuments, setGarbageDocuments] = useState<DocumentModel[]>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const getDocuments = async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        setDocuments([]);
        return;
      }

      const documentsRef = collection(firestore, 'Documents');
      const q = query(documentsRef, where('uid', '==', currentUser.uid));
      const querySnapshot = await getDocs(q);

      const docs: any = [];
      querySnapshot.forEach((doc) => {
        docs.push({
          id: doc.id,
          ...doc.data(),
        } as DocumentModel);
      });
      const mappedDocs = docs.map((m: any) => {
        const text = m?.document;

        return {
          id: m.id,
          isDeleted: m.isDeleted,
          lastModified: m?.last_appended?.toDate() || new Date(),
          content: text,
          messages: m.messages,
        };
      });

      const sortedDocs = mappedDocs
        .filter((doc: any) => !doc.isDeleted)
        .sort((a: MappedDocument, b: MappedDocument) => {
          return b.lastModified.getTime() - a.lastModified.getTime();
        });

      const sortedGarbageDocs = mappedDocs
        .filter((doc: any) => doc.isDeleted)
        .sort((a: MappedDocument, b: MappedDocument) => {
          return b.lastModified.getTime() - a.lastModified.getTime();
        });

      setDocuments(sortedDocs);
      setGarbageDocuments(sortedGarbageDocs);
    } catch (error) {
      console.error('Error fetching documents:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleMoveToGarbage = async (id: string) => {
    try {
      const documentRef = doc(firestore, 'Documents', id);
      await updateDoc(documentRef, {
        isDeleted: true,
        deletedAt: serverTimestamp(),
      });
      getDocuments();
    } catch (error) {
      console.error('Error moving document to trash:', error);
    }
  };

  const handleRestoreDocument = async (id: string) => {
    try {
      const documentRef = doc(firestore, 'Documents', id);
      await updateDoc(documentRef, {
        isDeleted: false,
        deletedAt: null,
      });
      getDocuments();
    } catch (error) {
      console.error('Error restoring document:', error);
    }
  };

  const handleDeleteDocument = async (id: string) => {
    try {
      const documentRef = doc(firestore, 'Documents', id);
      await deleteDoc(documentRef);
      getDocuments();
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  useEffect(() => {
    getDocuments();
  }, []);

  useEffect(() => {
    if (id || chosenContent || loadingDocuments) {
      setShowListOfDocuments(false);
    } else {
      setShowListOfDocuments(true);
    }
  }, [id, chosenContent, loadingDocuments]);

  const renderTopBar = () => {
    if (id) {
      return (
        <div>
          <StyledButton
            variant={showListOfDocuments ? 'contained' : 'outlined'}
            onClick={() => setShowListOfDocuments(true)}
          >
            {t('dashboard:documentsRightPanel.listOfDocuments')}
          </StyledButton>
          <StyledButton
            variant={showListOfDocuments ? 'outlined' : 'contained'}
            onClick={() => setShowListOfDocuments(false)}
          >
            {t('dashboard:documentsRightPanel.currentDocument')}
          </StyledButton>
        </div>
      );
    } else {
      return (
        <div>
          <StyledButton variant="text" disabled={true}>
            {t('dashboard:documentsRightPanel.listOfDocuments')}
          </StyledButton>
        </div>
      );
    }
  };

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ padding: '10px' }}>{renderTopBar()}</div>
      <div style={{ flex: 1 }}>
        {showListOfDocuments ? (
          <ListOfDocuments
            documents={documents}
            garbageDocuments={garbageDocuments}
            loading={loading}
            onMoveToGarbage={handleMoveToGarbage}
            onRestoreDocument={handleRestoreDocument}
            onDeleteDocument={handleDeleteDocument}
          />
        ) : (
          <DocumentDisplay
            loadingText={loadingText}
            modifiedContent={modifiedContent}
            documentModel={chosenDocument}
            content={chosenContent}
            loading={loadingDocuments}
            status={status}
          />
        )}
      </div>
    </div>
  );
};

export default DocumentsRightPanel;
