import {
  Box,
  BoxProps,
  Fade,
  Grid,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getCssVariable } from 'styles/getVariables';

type Props = {
  onQuestionClick: (question: string) => void;
};

const SuggestionQuestionsDocument: React.FC<Props> = ({ onQuestionClick }) => {
  const { t } = useTranslation();

  const suggestedQuestions = [
    t('dashboard:documentSuggestionQuestions.question1'),
    t('dashboard:documentSuggestionQuestions.question2'),
  ];

  const BoxForSuggestions = styled(Box)<BoxProps>({
    border: '1px solid lightGray',
    borderRadius: '10px',
    minHeight: '50px',
    padding: '7px',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: getCssVariable('--hover-color'),
    },
  });

  const TypographySuggestion = styled(Typography)<TypographyProps>({
    fontSize: '14px',
    margin: 'auto',
    fontWeight: '500',
  });

  return (
    <>
      <Box width={'100%'} display={'flex'} justifyContent={'center'}>
        <Typography sx={{ fontSize: '14px', fontWeight: '700' }}>
          {t('dashboard:chatFeedDocument.examplePrompts')}
        </Typography>
      </Box>
      <Grid item xs={12} px={3}>
        {suggestedQuestions.map((question, index) => (
          <Fade
            in={true}
            key={index}
            timeout={{ enter: 1000 * (index + 1), exit: 1000 }}
          >
            <BoxForSuggestions
              marginTop={'10px'}
              component={'button'}
              key={index}
              onClick={() => {
                onQuestionClick(question);
              }}
            >
              <TypographySuggestion align="left">
                {question}
              </TypographySuggestion>
            </BoxForSuggestions>
          </Fade>
        ))}
      </Grid>
    </>
  );
};

export default SuggestionQuestionsDocument; 