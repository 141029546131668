import { Box, Button } from '@mui/material';
import { AuthContext } from 'context/Auth';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routesManager, PathType } from 'routes/routes';

type Props = {
  onVisibleChange: (visible: boolean) => void;
  isVisible: boolean;
};

const SubscribtionTopbar = ({ onVisibleChange, isVisible }: Props) => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [trialDays, setTrialDays] = useState(0);
  const [timeExpired, setTimeExpired] = useState(false);
  const { t } = useTranslation();

  const handleClick = () => {
    navigate(routesManager.getPath(PathType.SUBSCRIPTION));
  };

  useEffect(() => {
    if (currentUser?.activeSubscription === false) {
      const trialDate = currentUser?.trialFinishDate;
      const activeSubscription = currentUser?.activeSubscription;
      if (trialDate && !activeSubscription) {
        const today = new Date();
        const diffTime = trialDate.getTime() - today.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        onVisibleChange(true);
        if (diffDays > 0) {
          setTimeExpired(false);
        } else {
          setTimeExpired(true);
        }
        setTrialDays(diffDays);
      } else {
        onVisibleChange(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const getTrialDaysText = () => {
    if (trialDays === 1) {
      return t('dashboard:topbar.trial_days_one_day');
    }
    if (trialDays > 1) {
      return t('dashboard:topbar.trial_days_days', { days: trialDays });
    }
    return t('dashboard:topbar.trial_days_zero_days');
  };

  if (!isVisible) {
    return null;
  }
  return (
    <Box
      height={'40px'}
      zIndex={100}
      display="flex"
      alignItems="center"
      justifyContent="left"
      gap={4}
      px={2}
      sx={{ borderBottom: '1px solid #e0e0e0' }}
    >
      <Button
        variant="contained"
        size="small"
        color="secondary"
        sx={{
          textTransform: 'none',
        }}
        onClick={handleClick}
      >
        {t('dashboard:topbar.subscribe')}
      </Button>
      <p style={{ fontSize: '14px', color: 'firebrick' }}>
        {timeExpired
          ? t('dashboard:topbar.trial_expired')
          : `${t('dashboard:topbar.trial_days')} ${getTrialDaysText()}`}
      </p>
    </Box>
  );
};

export default SubscribtionTopbar;
