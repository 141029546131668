import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SidebarProvider } from './context/SidebarProvider';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import data from './secrets/stripeConfig.json';
import { TabsProvider } from './context/TabsProvider';
import { ConversationProvider } from './context/ConversationProvider';
import { TextHighlightProvider } from './context/TextHighlightProvider';
import { InstructionProvider } from './context/InstructionProvider';
import './i18next';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const stripePromise = loadStripe(data.publishableKey);

const setFavicon = () => {
  const env = process.env.REACT_APP_ENV;

  const createFaviconLink = (size: string, type: string, href: string) => {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.type = type;
    (link as any).sizes = size;
    link.href = href;
    document.head.appendChild(link);
  };

  // List of favicons with different sizes
  const faviconsPL = [
    {
      size: '16x16',
      type: 'image/x-icon',
      href: env === 'production' ? '/favicon.ico' : '/favicon2.ico',
    },
    {
      size: '32x32',
      type: 'image/png',
      href: '/logo512.png',
    },
    {
      size: '64x64',
      type: 'image/png',
      href: '/logo512.png',
    },
    {
      size: '192x192',
      type: 'image/png',
      href: '/logo512.png',
    },
    {
      size: '256x256',
      type: 'image/png',
      href: '/logo512.png',
    },
    {
      size: '512x512',
      type: 'image/png',
      href: '/logo512.png',
    },
    // Add more sizes as needed
  ];

  const faviconsFR = [
    {
      size: '16x16',
      type: 'image/x-icon',
      href: '/faviconFR.ico',
    },
    {
      size: '32x32',
      type: 'image/png',
      href: '/laloireLogo.png',
    },
    {
      size: '64x64',
      type: 'image/png',
      href: '/laloireLogo.png',
    },
    {
      size: '192x192',
      type: 'image/png',
      href: '/laloireLogo.png',
    },
    {
      size: '256x256',
      type: 'image/png',
      href: '/laloireLogo.png',
    },
    {
      size: '512x512',
      type: 'image/png',
      href: '/laloireLogo.png',
    },
  ];

  const favicons =
    process.env.REACT_APP_LANGUAGE !== 'fr' ? faviconsPL : faviconsFR;

  favicons.forEach((favicon) =>
    createFaviconLink(favicon.size, favicon.type, favicon.href)
  );

  // Optionally, set the default favicon
  const defaultLink = document.createElement('link');
  defaultLink.rel = 'icon';
  defaultLink.type = 'image/x-icon';
  defaultLink.href =
    process.env.REACT_APP_LANGUAGE !== 'fr'
      ? env === 'production'
        ? '/favicon.ico'
        : '/favicon2.ico'
      : '/faviconFR.ico';
  document.head.appendChild(defaultLink);
};

setFavicon();

root.render(
  // <React.StrictMode>
  <Elements stripe={stripePromise}>
    <TextHighlightProvider value={{ blockText: false, setBlockText: () => {} }}>
      <ConversationProvider>
        <TabsProvider>
          <SidebarProvider>
            <InstructionProvider>
              <App />
            </InstructionProvider>
          </SidebarProvider>
        </TabsProvider>
      </ConversationProvider>
    </TextHighlightProvider>
  </Elements>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
