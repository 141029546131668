import NewsletterPanel from 'module-settings/components/organisms/newsletter-panel/NewsletterPanel';
import { useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore } from 'configs/firebaseConfig';
import { AuthContext } from 'context/Auth';
import LoadingWrapper from 'common/loading-wrapper/LoadingWrapper';

const NewsletterPage = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const getUserDetails = async (guid: string) => {
    setIsLoading(true);
    const docRef = doc(firestore, 'Users', guid);
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const user = docSnap.data();
        console.log(user);
        setIsSubscribed(user?.preferences?.newsletterSubscribtion ?? true);
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeNewsletter = async () => {
    const docRef = doc(firestore, 'Users', currentUser?.id ?? '');
    setIsLoading(true);
    await updateDoc(docRef, {
      'preferences.newsletterSubscribtion': !isSubscribed,
    });
    getUserDetails(currentUser?.id ?? '');
    setIsLoading(false);
  };

  useEffect(() => {
    getUserDetails(currentUser?.id ?? '');
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.container}>
      <LoadingWrapper isLoading={isLoading}>
        {isSubscribed ? (
          <NewsletterPanel
            title="Jesteś zapisana/y do naszego newslettera"
            description="Regularnie wysyłamy ciekawe informacje, nowości i wyjątkowe oferty."
            question="Czy chcesz się wypisać?"
            buttonText="Wypisz się"
            buttonAction={handleChangeNewsletter}
          />
        ) : (
          <NewsletterPanel
            title="Nie jesteś zapisana/y do naszego newslettera"
            description="Możesz dołączyć, aby być na bieżąco z nowościami i promocjami."
            question="Czy chcesz się zapisać?"
            buttonText="Zapisz się"
            buttonAction={handleChangeNewsletter}
          />
        )}
      </LoadingWrapper>
    </div>
  );
};

export default NewsletterPage;
