import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import style from './styles.module.scss';
import useDarkMode from 'hooks/useDarkMode';
import { useEffect, useState } from 'react';
import { auth, firestore } from 'configs/firebaseConfig';
import { getUidByEmail } from 'api/get-uid';
import { doc, updateDoc } from 'firebase/firestore';
import { getCssVariable } from 'styles/getVariables';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const updateUserThemePreference = async (value: number) => {
  if (auth.currentUser?.email != null) {
    const uid = await getUidByEmail('Users', auth.currentUser?.email);
    if (uid) {
      const updateDocRef = doc(firestore, 'Users', uid);
      await updateDoc(updateDocRef, {
        theme: value,
      });
    }
  }
};

const UserPreferences = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('settings');
  const { setUserThemePreference } = useDarkMode();
  const [userTheme, setUserTheme] = useState(0);
  const [isSplitPaneSaved, setIsSplitPaneSaved] = useState(false);
  const [previousUserTheme, setPreviousUserTheme] = useState(0);
  const [previousIsSplitPaneSaved, setPreviousIsSplitPaneSaved] = useState(false);

  const handleThemeChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedTheme = parseInt(event.target.value) as 0 | 1 | 2;

    await updateUserThemePreference(selectedTheme);

    setUserThemePreference(selectedTheme);
    setUserTheme(selectedTheme);
    window.location.reload();
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.checked);
    localStorage.setItem('savedSplitPaneXPos', event.target.checked.toString());
    setIsSplitPaneSaved(event.target.checked);
    if (!event.target.checked) {
      localStorage.removeItem('splitPaneXPos');
    }
  };

  const handleClose = () => {
    // Restore previous state
    if (previousUserTheme !== userTheme) {
      localStorage.setItem('userPreferedTheme', previousUserTheme.toString());
      setUserThemePreference(previousUserTheme as 0 | 1 | 2);
    }
    
    if (previousIsSplitPaneSaved !== isSplitPaneSaved) {
      localStorage.setItem('savedSplitPaneXPos', previousIsSplitPaneSaved.toString());
      if (!previousIsSplitPaneSaved) {
        localStorage.removeItem('splitPaneXPos');
      }
    }
    
    // Navigate back
    navigate(-1);
  };

  useEffect(() => {
    const t = parseInt(localStorage.getItem('userPreferedTheme') || '0');
    const spl = localStorage.getItem('savedSplitPaneXPos');

    setUserTheme(t);
    setPreviousUserTheme(t);
    
    const isSaved = spl && spl === 'true' ? true : false;
    setIsSplitPaneSaved(isSaved);
    setPreviousIsSplitPaneSaved(isSaved);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.mainDiv}>
      <div className={style.headerContainer}>
        <h2 className={style.settingsTitle}>{t('settings')}</h2>
      </div>
      <div className={style.closeButton}>
        <IconButton onClick={handleClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </div>
      <Grid container spacing={2} width={'100%'} padding={3}>
        <FormControl>
          <FormLabel
            sx={{
              color: getCssVariable('--text-primary'),
            }}
          >
            {t('colorTheme')}
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={userTheme.toString()}
            name="radio-buttons-group"
            onChange={handleThemeChange}
          >
            <FormControlLabel
              value="2"
              control={
                <Radio
                  sx={{
                    color: getCssVariable('--text-primary'),
                    '&.Mui-checked': {
                      color: getCssVariable('--text-primary'),
                    },
                  }}
                />
              }
              label={t('lightTheme')}
              sx={{
                '& .MuiFormControlLabel-label': {
                  color: getCssVariable('--text-primary'),
                },
              }}
            />
            <FormControlLabel
              value="1"
              control={
                <Radio
                  sx={{
                    color: getCssVariable('--text-primary'),
                    '&.Mui-checked': {
                      color: getCssVariable('--text-primary'),
                    },
                  }}
                />
              }
              label={t('darkTheme')}
              sx={{
                '& .MuiFormControlLabel-label': {
                  color: getCssVariable('--text-primary'),
                },
              }}
            />
            <FormControlLabel
              value="0"
              control={
                <Radio
                  sx={{
                    color: getCssVariable('--text-primary'),
                    '&.Mui-checked': {
                      color: getCssVariable('--text-primary'),
                    },
                  }}
                />
              }
              label={t('systemTheme')}
              sx={{
                '& .MuiFormControlLabel-label': {
                  color: getCssVariable('--text-primary'),
                },
              }}
            />
          </RadioGroup>

          <FormLabel
            sx={{
              marginTop: 2,
              color: getCssVariable('--text-primary'),
            }}
          >
            {t('savePanelPosition')}
          </FormLabel>
          <FormControlLabel
            control={
              <Switch
                checked={isSplitPaneSaved}
                onChange={handleSwitchChange}
                name="jason"
              />
            }
            label={t('savePosition')}
          />
        </FormControl>
      </Grid>
    </div>
  );
};

export default UserPreferences;
