import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import { LawModel } from '../../../../models/LawModel';
import { RulingModel } from '../../../../models/RulingModel';
import LawItem from './LawItem';
import RulingItem from './RulingItem';
import styles from './listOfSources.module.scss';
import { useState, useRef, useEffect } from 'react';
import { getCssVariable } from 'styles/getVariables';

type Props = {
  source: LawModel;
  onRulingSelect: (source: RulingModel) => void;
  onLawSelect: (source: LawModel) => void;
};

const Source = ({ source, onRulingSelect, onLawSelect }: Props) => {
  // Generate a unique key for this source based on its url or longTitle
  const storageKey = `expandedSource_${source.url || source.longTitle || Math.random().toString(36).substring(2, 9)}`;
  
  // Check localStorage for saved state, default to false if not found
  const getSavedExpandedState = () => {
    const savedState = localStorage.getItem(storageKey);
    return savedState === 'true';
  };
  
  const [expanded, setExpanded] = useState(getSavedExpandedState());
  const contentRef = useRef<HTMLDivElement>(null);

  // Function to update expanded state and save to localStorage
  const toggleExpanded = (newState: boolean) => {
    setExpanded(newState);
    localStorage.setItem(storageKey, String(newState));
  };

  useEffect(() => {
    if (contentRef.current) {
      const height = contentRef.current.scrollHeight;
      contentRef.current.style.setProperty('--expanded-height', `${height}px`);
    }
  }, [source.rulings]); // Update when rulings change

  return (
    <>
      <Box marginBottom={'10px'}>
        <Box
          sx={{
            borderRadius: '10px',
            border: '1px solid lightGray',
            padding: '10px',
            background: getCssVariable('--background-color-primary'),
          }}
        >
          <LawItem
            // key={sourIndex}
            source={source}
            onSourceSelect={(s) => {
              onLawSelect(s);
            }}
          />

          <Box
            ref={contentRef}
            className={`${styles.textContainer} ${
              expanded ? styles.expanded : ''
            }`}
          >
            <Box
              className={styles.fadeOut}
              component={'button'}
              style={{ border: 'none' }}
              onClick={() => {
                toggleExpanded(!expanded);
              }}
            ></Box>
            {(source.rulingsLoading === false ||
              source.rulingsLoading == null) &&
            source.rulings.length === 0 ? (
              <Box />
            ) : (
              <Box marginTop={'10px'}>
                <Divider />
              </Box>
            )}

            <Box>
              <Box
                component={'button'}
                style={{ border: 'none' }}
                onClick={() => {
                  toggleExpanded(!expanded);
                }}
              >
                {(source.rulingsLoading === false ||
                  source.rulingsLoading == null) &&
                source.rulings.length === 0 ? (
                  <Box />
                ) : (
                  <Typography
                    // sx={{ color: getCssVariable('--rulings-header-color') }}
                    sx={{ color: getCssVariable('--primary') }}
                  >
                    Interesujące orzeczenia odnoszące się do powyższego
                    artykułu:{' '}
                  </Typography>
                )}
              </Box>
              {source.rulingsLoading === true && (
                <Box
                  paddingBottom={'15px'}
                  paddingTop={'5px'}
                  display={'flex'}
                  alignContent={'center'}
                  alignItems={'center'}
                  justifyContent={'left'}
                >
                  <CircularProgress
                    size={'22px'}
                    sx={{
                      marginRight: '10px',
                      marginLeft: '20px',
                    }}
                  />
                  <Typography sx={{ fontStyle: 'italic', color: 'darkgrey' }}>
                    Wyszukiwanie orzeczeń
                  </Typography>
                </Box>
              )}
            </Box>

            {source.rulings &&
              source.rulings.map((ruling, rulIndex) => (
                <>
                  <Box paddingLeft={'30px'}>
                    {rulIndex > 0 && <Divider />}

                    <RulingItem
                      // key={rulIndex}
                      source={ruling}
                      gridProps={{ mb: '20px' }}
                      onSourceSelect={(s) => {
                        onRulingSelect(s);
                      }}
                    />
                  </Box>
                </>
              ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Source;
