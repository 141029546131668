import {
  collection,
  getDocs,
  query,
  where,
  getDoc,
  doc,
} from 'firebase/firestore';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { firestore } from '../../../configs/firebaseConfig';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../../context/Auth';
import { PathType, routesManager } from 'routes/routes';
import UserDetailsPanel, {
  ConversationData,
  UserElement,
} from './UserDetailsPanel';
import { format } from 'date-fns';
import { ROLE_ADMIN } from '../../../constants/roles';

const AdminUserDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { userRole } = useContext(AuthContext);
  const [loadingConversations, setLoadingConversations] = useState(false);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserElement | null>(null);
  const [conversations, setConversations] = useState<ConversationData[]>([]);
  const [documents, setDocuments] = useState<ConversationData[]>([]);

  useEffect(() => {
    if (userRole !== ROLE_ADMIN) {
      navigate(routesManager.getPath(PathType.START_PAGE));
    }
    //eslint-disable-next-line
  }, []);

  const getUserDetails = async (guid: string) => {
    const docRef = doc(firestore, 'Users', guid);

    try {
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const user = docSnap.data();

        setSelectedUser({
          email: user.email,
          id: user.id,
          name: user.firstName + ' ' + user.lastName,
          trialEnd: user.trial_ended_at
            ? user.trial_ended_at.toDate().toLocaleDateString()
            : null,
          stats: {
            numberOfConversations: user.conversations_count,
            numberOfDocuments: user.documents_count,
            firstLogin: user.createdAt ? user.createdAt.toDate() : null,
            lastActivity: user.latest_activity_date
              ? user.latest_activity_date.toDate()
              : null,
            numberOfEmailsSent: user.stats?.numberOfEmailsSent ?? 0,
            numberOfPhoneCalls: user.stats?.numberOfPhoneCalls ?? 0,
            numberOfLinkedinInteractions:
              user.stats?.numberOfLinkedinInteractions ?? 0,
            lastContact: user.stats?.date ? user.stats.date.toDate() : null,
          },
        });
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const getUserConversations = async (id: string) => {
    setLoadingConversations(true);
    const q = query(
      collection(firestore, 'Conversations'),
      where('uid', '==', id)
    );

    const querySnapshot = await getDocs(q);
    const conversationsList: ConversationData[] = querySnapshot.docs.map(
      (doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          type: 'conversation',
          lastAppended: data.last_appended ? data.last_appended.toDate() : null,
          snippet: data.messages[1].content,
        };
      }
    );
    setConversations(conversationsList);
    setLoadingConversations(false);
  };

  const getUserDocuments = async (id: string) => {
    setLoadingDocuments(true);
    const q = query(collection(firestore, 'Documents'), where('uid', '==', id));

    const querySnapshot = await getDocs(q);
    const documentsList: ConversationData[] = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        type: 'document',
        lastAppended: data.last_appended ? data.last_appended.toDate() : null,
        snippet: data.messages[1].content,
      };
    });
    setDocuments(documentsList);
    setLoadingDocuments(false);
  };

  // useEffect(() => {
  //   getUsersList();
  //   //eslint-disable-next-line
  // }, [searchedText]);

  useEffect(() => {
    if (id != null) {
      getUserDetails(id);
      getUserConversations(id).then((user) => {});
      getUserDocuments(id).then((user) => {});
    }
    //eslint-disable-next-line
  }, [id]);

  return (
    <Grid container>
      <Grid
        item
        xs={3}
        padding={'20px'}
        sx={{
          maxHeight: 'calc(100vh - 20px)',
          borderRight: '1px solid lightGray',
        }}
      >
        <Typography sx={{ fontWeight: 'bold', paddingTop: '20px' }}>
          Imię i nazwisko
        </Typography>
        <Typography variant="h6">{selectedUser?.name}</Typography>
        <Typography sx={{ fontWeight: 'bold', paddingTop: '20px' }}>
          Email
        </Typography>
        <Typography variant="h6">{selectedUser?.email}</Typography>
        <Typography sx={{ fontWeight: 'bold', paddingTop: '20px' }}>
          Data pierwszego logowania
        </Typography>
        <Typography variant="h6">
          {selectedUser?.stats?.firstLogin
            ? format(selectedUser?.stats?.firstLogin, 'dd.MM.yyyy')
            : 'Nie posiada'}
        </Typography>
        <Typography sx={{ fontWeight: 'bold', paddingTop: '20px' }}>
          Data zakończenia okresu próbnego
        </Typography>
        <Typography variant="h6">
          {selectedUser?.trialEnd
            ? format(selectedUser?.trialEnd, 'dd.MM.yyyy')
            : 'Nie posiada'}
        </Typography>
        <Typography sx={{ fontWeight: 'bold', paddingTop: '20px' }}>
          Liczba konwersacji: {selectedUser?.stats?.numberOfConversations}
        </Typography>
        <Typography sx={{ fontWeight: 'bold', paddingTop: '20px' }}>
          Liczba dokumentów: {selectedUser?.stats?.numberOfDocuments}
        </Typography>
      </Grid>

      <Grid item xs={9} padding={'20px'}>
        {loadingConversations && loadingDocuments ? (
          <>
            <CircularProgress size={20} />
          </>
        ) : (
          <>
            {id != null ? (
              <UserDetailsPanel
                conversationsList={[...conversations, ...documents]}
              />
            ) : (
              <Typography variant="h6">Wybierz użytkownika</Typography>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default AdminUserDetailsPage;
