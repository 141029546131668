import React, { useEffect, useState } from 'react';
import P5Animation from '../components/P5Animation';

// dummy
// Create a type for the global CCapture object
declare global {
  interface Window {
    CCapture: any;
  }
}

const AnimationDemo = () => {
  const [isCCaptureAvailable, setIsCCaptureAvailable] = useState<boolean | null>(null);

  useEffect(() => {
    // Check if CCapture is available after the CDN script has had time to load
    const checkCCapture = setTimeout(() => {
      setIsCCaptureAvailable(typeof window.CCapture !== 'undefined');
    }, 1000);

    return () => clearTimeout(checkCCapture);
  }, []);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
      <div style={{ 
        position: 'absolute', 
        top: '20px', 
        left: '20px', 
        zIndex: 10, 
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: 'white',
        padding: '15px',
        borderRadius: '8px',
        maxWidth: '400px',
      }}>
        <h2 style={{ margin: '0 0 10px 0' }}>P5.js Animation with GIF Recording</h2>
        <p>
          This interactive wave animation demonstrates the beautiful patterns 
          created with P5.js. 
          {isCCaptureAvailable === true && (
            <>Click the "Record GIF" button in the bottom-right 
            corner to capture 5 seconds of animation as a downloadable GIF.</>
          )}
          {isCCaptureAvailable === false && (
            <><br/><br/><strong style={{ color: '#f44336' }}>
              Note: GIF recording functionality is not available. 
              The required library could not be loaded.
            </strong></>
          )}
        </p>
      </div>
      <P5Animation />
    </div>
  );
};

export default AnimationDemo; 