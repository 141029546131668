import { BlogArticleModel } from 'models/BlogArticle';
import ArticleTemplate from 'module-public/components/templates/article-template/ArticleTemplate';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PathType, routesManager } from 'routes/routes';
import { actualizationsArticles } from '../../data/actualizationArticles';

const ActualizationsArticle = () => {
  const location = useLocation();
  const [article, setArticle] = useState<BlogArticleModel | undefined>(
    undefined
  );

  useEffect(() => {
    const chosenArticle = actualizationsArticles.find(
      (article) => article.url === location.pathname.split('/')[2]
    );
    setArticle(chosenArticle);
  }, [location]);

  return (
    <div>
      <ArticleTemplate
        article={article}
        backLink={routesManager.getPath(PathType.ACTUALIZATIONS)}
        backLinkText="Powrót do aktualizacji"
      />
    </div>
  );
};

export default ActualizationsArticle;
