import AdminPage from '../module-settings/pages/admin-page/AdminPage';
import DashboardPage from '../module-private/pages/dashboard-page/DashboardPage';
import LoginPage from '../module-public/pages/login-page/LoginPage';
import SubscriptionPage from '../module-settings/pages/subscription-page/SubscriptionPage';
import AccountPanel from '../module-settings/pages/subscription-page/components/AccountPanel';
import UserSetupPage from '../module-settings/pages/user-setup-page/UserSetupPage';
import { AboutPage } from '../module-public/pages/about-page/AboutPage';
import BlogArticle from '../module-public/pages/blog-article/BlogArticle';
import { BlogPage } from '../module-public/pages/blog-page/BlogPage';
import { ContactPage } from '../module-public/pages/contact-page/ContactPage';
import RodoPage from '../module-public/pages/rodo-page/RodoPage';
import RegistrationPage from '../module-public/pages/registration-page/RegistrationPage';
import SettingsPage from '../module-settings/pages/settings-page/SettingsPage';
import AdminUsersPage from '../module-settings/pages/admin-users-page/AdminUsersPage';
import AdminConversationPage from '../module-settings/pages/admin-conversations-page/AdminConversationsPage';
import StatutePage from '../module-public/pages/statute-page/StatutePage';
import DocumentsPage from 'module-private/pages/documents-page/DocumentsPage';
import PricePage from 'module-public/pages/price-page/PricePage';
import StartPage from 'module-public/pages/start-page/StartPage';
import AdminDocumentsPage from 'module-settings/pages/admin-documents-page/AdminDocumentsPage';
import AdminUserDetailsPage from 'module-settings/pages/admin-user-details-page/AdminUserDetailsPage';
import AnimationDemo from 'module-public/pages/AnimationDemo';
import NewsletterPage from 'module-settings/pages/newsletter-page/NewsletterPage';
import ActualizationsArticle from 'module-public/pages/actualizations-article/ActualizationsArticle';
import { ActualizationsPage } from 'module-public/pages/actualizations-page/ActualizationsPage';

export enum PathType {
  DOCUMENT = 'document',
  DOCUMENT_ID = 'document-id',
  CHAT = 'chat',
  CHAT_ID = 'chat-id',
  SETTINGS = 'settings',
  SUBSCRIPTION = 'subscription',
  SUBSCRIPTION_TEXT = 'subscription-text',
  ACCOUNT = 'account',
  MNB = 'mnb',
  MNB_USERS = 'mnb-users',
  MNB_USERS_ID = 'mnb-users-id',
  MNB_CHAT_ID = 'mnb-chat-id',
  MNB_CHAT = 'mnb-chat',
  MNB_DOCUMENTS_ID = 'mnb-documents-id',
  MNB_DOCUMENTS = 'mnb-documents',
  LOGIN = 'login',
  REGISTRATION = 'registration',
  USER_SETUP = 'user-setup',
  START_PAGE = 'start-page',
  BLOG = 'blog',
  BLOG_ID = 'blog-id',
  ACTUALIZATIONS = 'aktualizacje',
  ACTUALIZATIONS_ID = 'aktualizacje-id',
  CONTACT = 'contact',
  ABOUT = 'about',
  RODO = 'rodo',
  STATUTE = 'statute',
  PRICE = 'price',
  ANIMATION_DEMO = 'animation-demo',
  NEWSLETTER = 'newsletter',
}

type Route = {
  name: PathType;
  pathPL: string;
  pathFR: string;
  element: () => JSX.Element;
  layout: 'dashboard' | 'public' | 'empty';
  authorization: boolean;
};

export class RoutesManager {
  private static instance: RoutesManager;
  private readonly localizedRoutes: (Route & { path: string })[];

  private constructor() {
    const language = process.env.REACT_APP_LANGUAGE || 'pl';

    const routes: Route[] = [
      {
        name: PathType.DOCUMENT,
        pathPL: '/dokument',
        pathFR: '/document',
        element: DocumentsPage,
        layout: 'dashboard',
        authorization: true,
      },
      {
        name: PathType.DOCUMENT_ID,
        pathPL: '/dokument/:id',
        pathFR: '/document/:id',
        element: DocumentsPage,
        layout: 'dashboard',
        authorization: true,
      },
      {
        name: PathType.CHAT,
        pathPL: '/czat',
        pathFR: '/chat',
        element: DashboardPage,
        layout: 'dashboard',
        authorization: true,
      },
      {
        name: PathType.CHAT_ID,
        pathPL: '/czat/:id',
        pathFR: '/chat/:id',
        element: DashboardPage,
        layout: 'dashboard',
        authorization: true,
      },
      {
        name: PathType.SETTINGS,
        pathPL: '/ustawienia',
        pathFR: '/settings',
        element: SettingsPage,
        layout: 'dashboard',
        authorization: true,
      },
      {
        name: PathType.SUBSCRIPTION,
        pathPL: '/subskrypcja',
        pathFR: '/subscription',
        element: SubscriptionPage,
        layout: 'dashboard',
        authorization: true,
      },
      {
        name: PathType.SUBSCRIPTION_TEXT,
        pathPL: '/subskrypcja/:text',
        pathFR: '/subscription/:text',
        element: SubscriptionPage,
        layout: 'dashboard',
        authorization: true,
      },
      {
        name: PathType.ACCOUNT,
        pathPL: '/account',
        pathFR: '/account',
        element: AccountPanel,
        layout: 'dashboard',
        authorization: true,
      },
      {
        name: PathType.MNB,
        pathPL: '/mnb',
        pathFR: '/mnb',
        element: AdminPage,
        layout: 'dashboard',
        authorization: true,
      },
      {
        name: PathType.MNB_USERS,
        pathPL: '/mnb/users',
        pathFR: '/mnb/users',
        element: AdminUsersPage,
        layout: 'dashboard',
        authorization: true,
      },
      {
        name: PathType.MNB_USERS_ID,
        pathPL: '/mnb/users/:id',
        pathFR: '/mnb/users/:id',
        element: AdminUserDetailsPage,
        layout: 'dashboard',
        authorization: true,
      },
      {
        name: PathType.MNB_CHAT_ID,
        pathPL: '/mnb/czat/:id',
        pathFR: '/mnb/chat/:id',
        element: AdminConversationPage,
        layout: 'dashboard',
        authorization: true,
      },
      {
        name: PathType.MNB_CHAT,
        pathPL: '/mnb/czat',
        pathFR: '/mnb/chat',
        element: AdminConversationPage,
        layout: 'dashboard',
        authorization: true,
      },
      {
        name: PathType.MNB_DOCUMENTS_ID,
        pathPL: '/mnb/dokumenty/:id',
        pathFR: '/mnb/documents/:id',
        element: AdminDocumentsPage,
        layout: 'dashboard',
        authorization: true,
      },
      {
        name: PathType.MNB_DOCUMENTS,
        pathPL: '/mnb/dokumenty',
        pathFR: '/mnb/documents',
        element: AdminDocumentsPage,
        layout: 'dashboard',
        authorization: true,
      },
      {
        name: PathType.LOGIN,
        pathPL: '/logowanie',
        pathFR: '/login',
        element: LoginPage,
        layout: 'empty',
        authorization: false,
      },
      {
        name: PathType.REGISTRATION,
        pathPL: '/rejestracja',
        pathFR: '/registration',
        element: RegistrationPage,
        layout: 'empty',
        authorization: false,
      },
      {
        name: PathType.NEWSLETTER,
        pathPL: '/newsletter',
        pathFR: '/newsletter',
        element: NewsletterPage,
        layout: 'dashboard',
        authorization: true,
      },
      {
        name: PathType.USER_SETUP,
        pathPL: '/dane',
        pathFR: '/dane',
        element: UserSetupPage,
        layout: 'empty',
        authorization: false,
      },
      {
        name: PathType.START_PAGE,
        pathPL: '/',
        pathFR: '/',
        element: StartPage,
        layout: 'public',
        authorization: false,
      },
      {
        name: PathType.BLOG,
        pathPL: '/blog',
        pathFR: '/blog',
        element: BlogPage,
        layout: 'public',
        authorization: false,
      },
      {
        name: PathType.BLOG_ID,
        pathPL: '/blog/:id',
        pathFR: '/blog/:id',
        element: BlogArticle,
        layout: 'public',
        authorization: false,
      },
      {
        name: PathType.ACTUALIZATIONS,
        pathPL: '/aktualizacje',
        pathFR: '/actualizations',
        element: ActualizationsPage,
        layout: 'public',
        authorization: false,
      },
      {
        name: PathType.ACTUALIZATIONS_ID,
        pathPL: '/aktualizacje/:id',
        pathFR: '/actualizations/:id',
        element: ActualizationsArticle,
        layout: 'public',
        authorization: false,
      },
      {
        name: PathType.CONTACT,
        pathPL: '/kontakt',
        pathFR: '/contact',
        element: ContactPage,
        layout: 'public',
        authorization: false,
      },
      {
        name: PathType.ABOUT,
        pathPL: '/o-nas',
        pathFR: '/about',
        element: AboutPage,
        layout: 'public',
        authorization: false,
      },
      {
        name: PathType.RODO,
        pathPL: '/polityka',
        pathFR: '/rodo',
        element: RodoPage,
        layout: 'empty',
        authorization: false,
      },
      {
        name: PathType.STATUTE,
        pathPL: '/regulamin',
        pathFR: '/terms',
        element: StatutePage,
        layout: 'empty',
        authorization: false,
      },
      {
        name: PathType.PRICE,
        pathPL: '/cennik',
        pathFR: '/price',
        element: PricePage,
        layout: 'empty',
        authorization: false,
      },
      {
        name: PathType.ANIMATION_DEMO,
        pathPL: '/animacja',
        pathFR: '/animation',
        element: AnimationDemo,
        layout: 'public',
        authorization: false,
      },
    ];

    // Calculate localized routes once during initialization
    this.localizedRoutes = routes.map((route) => ({
      ...route,
      path: language === 'fr' ? route.pathFR : route.pathPL,
    }));
  }

  public static getInstance(): RoutesManager {
    if (!RoutesManager.instance) {
      RoutesManager.instance = new RoutesManager();
    }
    return RoutesManager.instance;
  }

  public getLocalizedRoutes(): (Route & { path: string })[] {
    return this.localizedRoutes;
  }

  public getPath(name: PathType, parameter?: string): string {
    const route = this.localizedRoutes.find((r) => r.name === name);
    if (!route) throw new Error(`Route ${name} not found`);
    return parameter
      ? route.path.replace(':id', parameter).replace(':text', parameter)
      : route.path;
  }

  public getRoute(name: PathType): Route & { path: string } {
    const route = this.localizedRoutes.find((r) => r.name === name);
    if (!route) throw new Error(`Route ${name} not found`);
    return route;
  }

  public getAllRoutes(): (Route & { path: string })[] {
    return this.localizedRoutes;
  }
}

// Export a singleton instance
export const routesManager = RoutesManager.getInstance();

// For backward compatibility
export const routes = routesManager.getLocalizedRoutes();
export const getRoutePath = (name: PathType, parameter?: string) =>
  routesManager.getPath(name, parameter);
