import { Box, Typography } from '@mui/material';
import { ConversationModel } from '../../../../models/ConversationModel';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getCssVariable } from 'styles/getVariables';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

const isToday = (someDate: Date) => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

type Props = {
  conversation: ConversationModel;
  index: number;
  onDeleteClick: (conversation: any) => void;
  onClick: (conversation: any) => void;
};
const ConversationSidebarButton = ({
  conversation,
  index,
  onDeleteClick,
  onClick,
}: Props) => {
  const [text, setText] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const updateText = () => {
      let te = getText(conversation);
      setText(te);
    };
    updateText();

    const intervalId = setInterval(updateText, 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation]);

  const getText = (conversation: ConversationModel): string => {
    let lastAppended = new Date(conversation.lastAppended);
    const now = new Date();
    if (isToday(lastAppended)) {
      const startShiftTime = moment(now);
      const endShiftTime = moment(lastAppended);

      const duration = moment.duration(startShiftTime.diff(endShiftTime));

      if (duration.hours() < 1 && duration.minutes() < 60) {
        return duration.minutes() + ' min';
      } else if (
        duration.hours() < 1 &&
        duration.minutes() >= 20 &&
        duration.minutes() < 40
      ) {
        return '30 min';
      } else if (duration.hours() < 1 && duration.minutes() >= 40) {
        return '45 min';
      } else {
        return duration.hours() + ' ' + t('dashboard:time.hour');
      }
    } else {
      return format(conversation.lastAppended, 'dd.MM.yy');
    }
  };

  return (
    <>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        sx={{ '&:hover .hoverChild': { opacity: 1 } }}
      >
        <Box
          component={'button'}
          className="listItemSidebar"
          height={'35px'}
          display={'flex'}
          alignItems={'center'}
          alignContent={'center'}
          onClick={() => {
            onClick(conversation);
          }}
          sx={{
            background: getCssVariable('--navbar-color'),
            border: 'none',
            ':hover': {
              background: '#132034',
            },
            py: '4px',
          }}
          key={index}
          width={'78px'}
          justifyContent={'left'}
          marginRight={'5px'}
        >
          <Typography
            color={'lightgray'}
            className="listItemSidebar"
            width={'100%'}
            alignContent={'center'}
            sx={{
              fontSize: '16px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {text}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default ConversationSidebarButton;
