import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCssVariable } from 'styles/getVariables';
import NavLinks from './NavLinks';
import AppFullLogo from 'common/app-logos/components/app-full-logo/AppFullLogo';
import { PathType, routesManager } from 'routes/routes';
import styles from './styles.module.scss';
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Drawer,
  Container,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const PolishNavBar = () => {
  const [top, setTop] = useState(true);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  function handleClick() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        bgcolor: getCssVariable('--navbar-color'),
        maxWidth: '2000px',
        boxShadow: top ? 'none' : theme.shadows[3],
      }}
    >
      <Container maxWidth={false}>
        <Toolbar
          disableGutters
          sx={{ py: 1, display: 'flex', justifyContent: 'space-between' }}
        >
          <Box className={styles.logoContainer}>
            <AppFullLogo
              color="light"
              onClick={() => {
                navigate(routesManager.getPath(PathType.START_PAGE));
              }}
            />
          </Box>

          <Box>
            {isDesktop ? (
              <Box
                sx={{ display: 'flex', px: 2.5, gap: 2, alignItems: 'center' }}
              >
                <NavLinks />
              </Box>
            ) : (
              <IconButton
                color="inherit"
                aria-label="menu"
                onClick={handleClick}
                sx={{
                  p: 1,
                  color: 'white',
                  display: { xs: 'flex', lg: 'none' },
                }}
              >
                {isOpen ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            )}

            <Drawer
              anchor="top"
              open={isOpen && !isDesktop}
              onClose={() => setIsOpen(false)}
              sx={{
                zIndex: 1300,
                display: { xs: 'block', lg: 'none' },
                '& .MuiDrawer-paper': {
                  top: '70px',
                  boxShadow: theme.shadows[4],
                  bgcolor: 'white',
                  p: 3,
                  width: '100%',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'stretch',
                  width: '100%',
                  py: 3,
                  gap: 3,
                }}
              >
                <NavLinks mobile={true} onClose={() => setIsOpen(false)} />
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default PolishNavBar;
