import {
  Box,
  CircularProgress,
  IconButton,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { getCssVariable } from 'styles/getVariables';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InputAdornment from '@mui/material/InputAdornment';
import { ChangeEvent, useState, useRef } from 'react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';

type Props = {
  textfieldProps: TextFieldProps;
  onAskQuestion: (value: string) => void;
  onFileSelected?: (file: File) => void;
  disabled: boolean;
  uploadedFileName?: string;
  fileIsUploading?: boolean;
  onFiledDelete?: () => void;
  hideFileInput?: boolean;
};

const QuestionTextField = ({
  textfieldProps,
  disabled,
  onAskQuestion,
  onFileSelected,
  uploadedFileName,
  fileIsUploading,
  onFiledDelete,
  hideFileInput,
}: Props) => {
  const [currentQuestion, setCurrentQuestion] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation('dashboard');

  const handleSubmit = () => {
    if (currentQuestion.trim() && !disabled) {
      onAskQuestion(currentQuestion);
      setCurrentQuestion('');
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentQuestion(e.target.value);
  };

  const handleFileClick = () => {
    if (disabled) return;
    fileInputRef.current?.click();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && onFileSelected) {
      onFileSelected(file);
      // Reset the input value so the same file can be selected again
      e.target.value = '';
    }
  };

  return (
    <Box
      sx={{
        border: '1px solid var(--text-primary)',
        borderRadius: '4px',
        padding: '1px',
      }}
    >
      {uploadedFileName && (
        <Box
          sx={{
            border: '1px solid var(--text-primary)',
            display: 'inline-flex',
            padding: '4px',
            borderRadius: '4px',
            marginBottom: '4px',
            width: 'auto',
            alignItems: 'center',
            gap: '8px',
            marginTop: '4px',
            marginLeft: '4px',
          }}
        >
          {fileIsUploading ? (
            <CircularProgress size={16} />
          ) : (
            <InsertDriveFileIcon />
          )}
          <Typography fontSize="14px">{uploadedFileName}</Typography>
          <IconButton onClick={onFiledDelete} sx={{ padding: '2px' }}>
            <ClearIcon sx={{ fontSize: '18px' }} />
          </IconButton>
        </Box>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept=".doc,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
      />
      <TextField
        inputRef={textfieldProps.inputRef}
        value={disabled ? '' : currentQuestion}
        onKeyDown={handleKeyPress}
        onChange={handleOnChange}
        sx={{
          background: 'var(--background-color-primary)',
          fontSize: '14px',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none',
            },
            '&:hover fieldset': {
              border: 'none',
            },
            '&.Mui-focused fieldset': {
              border: 'none',
            },
          },
        }}
        placeholder={disabled ? '' : t('questionTextField.placeholder')}
        fullWidth
        multiline
        disabled={disabled}
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {hideFileInput !== true && (
                <AttachFileIcon
                  sx={{
                    color: disabled
                      ? getCssVariable('--text-disabled')
                      : getCssVariable('--text-primary'),
                    marginRight: '8px',
                    cursor: disabled ? 'default' : 'pointer',
                  }}
                  onClick={handleFileClick}
                />
              )}
              <SendIcon
                sx={{
                  color: disabled
                    ? getCssVariable('--text-disabled')
                    : getCssVariable('--text-primary'),
                  cursor: disabled ? 'default' : 'pointer',
                }}
                onClick={handleSubmit}
              />
            </InputAdornment>
          ),
        }}
        {...textfieldProps}
      >
        {textfieldProps.children}
      </TextField>
    </Box>
  );
};

export default QuestionTextField;
