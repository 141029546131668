/**
 * User role constants
 * These roles determine user permissions in the application
 */

/**
 * Admin role with full access to the admin panel
 * - Can view all users, conversations, set prices, and trial periods
 * - Can add new users manually or select existing users from dropdown
 * - Sees the price setting form by default, can toggle to view user list
 */
export const ROLE_ADMIN = 1134;

/**
 * Lexera role with restricted access to the admin panel
 * - Can only view and manage users with specific promo codes (lexera/n4zlex)
 * - Can set prices and trial durations for existing Lexera users via dropdown only
 * - Cannot view the full user list or add new users
 * - Cannot toggle between views - always sees the price setting form
 */
export const ROLE_LEXERA = 1135; 
// export const ROLE_MANAGER = 1135; 