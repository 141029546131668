import {
  collection,
  getDocs,
  query,
  doc,
  updateDoc,
  where,
  or,
  QuerySnapshot,
  DocumentData,
} from 'firebase/firestore';
import {
  Box,
  Button,
  Grid,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState, useRef } from 'react';
import { firestore } from '../../../configs/firebaseConfig';
import { UserElement } from '../admin-user-details-page/UserDetailsPanel';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/Auth';
import { PathType, routesManager } from 'routes/routes';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import styles from './styles.module.scss';
import GuardianSelector from './GuardianSelector';
import UserTableHeatMap from 'module-settings/components/organisms/user-table-heat-map/UserTableHeatMap';
import { ROLE_ADMIN, ROLE_LEXERA } from '../../../constants/roles';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid lightGray',
  borderRadius: '10px',
  boxShadow: 24,
  p: 2,
};

const AdminUsersPage = () => {
  const navigate = useNavigate();
  const { userRole } = useContext(AuthContext);
  const [users, setUsers] = useState<UserElement[]>([]);
  const [searchedText, setSearchedText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState('');
  const [userChosenId, setChosenUserId] = useState('');

  // const handleUserClick = (user: UserElement) => {
  //   navigate(routesManager.getPath(PathType.MNB_USERS_ID, user.id));
  // };

  // Check user authorization
  useEffect(() => {
    if (userRole !== ROLE_ADMIN && userRole !== ROLE_LEXERA) {
      navigate(routesManager.getPath(PathType.START_PAGE));
    }
  }, [userRole, navigate]);

  // Fetch users on component mount
  useEffect(() => {
    getUsersList();
  }, []);

  // Handle search text change
  useEffect(() => {
    if (users.length > 0) {
      getUsersList();
    }
  }, [searchedText]);

  const getUserQuery = () => {
    if (userRole === ROLE_ADMIN) {
      return query(collection(firestore, 'Users'));
    } else if (userRole === ROLE_LEXERA) {
      return query(
        collection(firestore, 'Users'),
        or(
          where('promoCode', '==', 'lexera'),
          where('promoCode', '==', 'n4zlex')
        )
      );
    }
    return null;
  };

  const mapQueryDataToUserElement = (querySnapshot: QuerySnapshot<DocumentData>) => {
    return querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        email: data.email || '',
        id: doc.id,
        name:
          (data.firstName || '') + ' ' + (data.lastName || '-'),
        trialEnd: data.trial_ended_at
          ? data.trial_ended_at.toDate()
          : null,
        stats: {
          numberOfConversations: data.conversations_count || 0,
          numberOfDocuments: data.documents_count || 0,
          firstLogin: data.createdAt
            ? data.createdAt.toDate()
            : null,
          lastActivity: data.latest_activity_date
            ? data.latest_activity_date.toDate()
            : null,
          numberOfEmailsSent: data.stats?.numberOfEmailsSent || 0,
          numberOfPhoneCalls: data.stats?.numberOfPhoneCalls || 0,
          numberOfLinkedinInteractions:
            data.stats?.numberOfLinkedinInteractions || 0,
          lastContact: data.stats?.date
            ? data.stats.date.toDate()
            : null,
        },
        promoCode: data.promoCode || '',
        guardian: data.guardian || '',
        description: data.description || '',
      };
    });
  };

  const filterUsersBySearchText = (usersList: UserElement[]) => {
    if (!searchedText) return usersList;
    return usersList.filter((user) =>
      user.email.toLowerCase().includes(searchedText.toLowerCase())
    );
  };

  const getUsersList = async () => {
    setIsLoading(true);
    try {
      const userQuery = getUserQuery();
      if (!userQuery) {
        setUsers([]);
        return;
      }

      const querySnapshot = await getDocs(userQuery);
      const usersList = mapQueryDataToUserElement(querySnapshot);
      const filteredUsers = filterUsersBySearchText(usersList);
      setUsers(filteredUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGuardianChange = async (userId: string, guardian: string) => {
    if (!userId) return;
    
    setIsLoading(true);
    try {
      const userRef = doc(firestore, 'Users', userId);
      await updateDoc(userRef, {
        guardian: guardian === '-' ? null : guardian,
      });
      await getUsersList();
    } catch (error) {
      console.error('Error updating guardian:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveDescription = async (userId: string, description: string) => {
    if (!userId) return;
    
    setIsLoading(true);
    try {
      const userRef = doc(firestore, 'Users', userId);
      await updateDoc(userRef, {
        description: description,
      });
      await getUsersList();
    } catch (error) {
      console.error('Error updating description:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalClose = () => setOpen(false);

  const handleModalSave = () => {
    saveDescription(userChosenId, description);
    handleModalClose();
  };

  const openDescriptionModal = (userId: string, currentDescription: string) => {
    setChosenUserId(userId);
    setDescription(currentDescription);
    setOpen(true);
  };

  const isPromoCodeHighlighted = (promoCode: string): boolean => {
    const code = promoCode?.toLowerCase().trim() || '';
    return code === 'lexera' || code === 'n4zlex';
  };

  const columns: GridColDef[] = [
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'name', headerName: 'Name', width: 140 },
    {
      field: 'trialEnd',
      headerName: 'Trial',
      width: 100,
      renderCell: (params) =>
        params.row.trialEnd ? format(params.row.trialEnd, 'dd.MM.yyyy') : '-',
    },
    {
      field: 'lastActivity',
      headerName: 'Last Activity',
      width: 130,
      renderCell: (params) =>
        params.row.stats.lastActivity
          ? format(params.row.stats.lastActivity, 'dd.MM.yyyy')
          : '-',
    },
    {
      field: 'numberOfConversations',
      headerName: 'convo',
      width: 80,
      renderCell: (params) => (
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {userRole === ROLE_ADMIN ? (
            <Link
              to={routesManager.getPath(PathType.MNB_USERS_ID, params.row.id)}
              className={styles.actionLink}
            >
              {params.row.stats.numberOfConversations}
            </Link>
          ) : (
            <Typography>{params.row.stats.numberOfConversations}</Typography>
          )}
        </Box>
      ),
    },
    {
      field: 'numberOfDocuments',
      headerName: 'docs',
      width: 80,
      renderCell: (params) => (
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>{params.row.stats.numberOfDocuments}</Typography>
        </Box>
      ),
    },
    {
      field: 'promoCode',
      headerName: 'Promo Code',
      width: 130,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <Typography
            sx={{
              color: isPromoCodeHighlighted(params.row.promoCode) 
                ? 'green' 
                : 'inherit',
            }}
          >
            {params.row.promoCode}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'lastContact',
      headerName: 'Heat Map',
      width: 130,
      renderCell: (params) => (
        <UserTableHeatMap
          userId={params.row.id}
          stats={params.row.stats}
          onSave={getUsersList}
        />
      ),
    },
    {
      field: 'guardian',
      headerName: 'Guardian',
      width: 130,
      renderCell: (params) => (
        <GuardianSelector
          guardian={params.row.guardian}
          onChange={(value) => handleGuardianChange(params.row.id, value)}
        />
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Tooltip
            title={
              params.row.description.length > 20
                ? params.row.description
                : undefined
            }
          >
            <Typography
              sx={{
                cursor: 'pointer',
                fontSize: '14px',
              }}
              onClick={() => openDescriptionModal(params.row.id, params.row.description)}
            >
              {params.row.description !== ''
                ? params.row.description
                : 'Dodaj opis'}
            </Typography>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Grid container sx={{ height: '100vh', paddingTop: '50px' }}>
      <Box
        sx={{
          position: 'absolute',
          top: '0',
          right: '0',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Button
          onClick={() => navigate(routesManager.getPath(PathType.MNB))}
        >
          admin
        </Button>
      </Box>

      <DataGrid
        rows={users.map((user) => ({
          ...user,
          numberOfConversations: user.stats?.numberOfConversations ?? 0,
          numberOfDocuments: user.stats?.numberOfDocuments ?? 0,
          lastActivity: user.stats?.lastActivity ?? null,
          lastContact: user.stats?.lastContact ?? null,
        }))}
        columns={columns}
        pageSizeOptions={[10, 25, 50]}
        disableRowSelectionOnClick
        loading={isLoading}
        sx={{
          width: '100%',
          '& .MuiDataGrid-main': { overflow: 'auto' },
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: 'white',
            color: 'black',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: 'white',
            color: 'black',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
        }}
      />

      <Modal open={open} onClose={handleModalClose}>
        <Box sx={modalStyle}>
          <Box width="500px">
            <Typography>Dodaj opis</Typography>
            <TextField
              value={description}
              multiline
              fullWidth
              rows={4}
              autoFocus
              onChange={(e) => setDescription(e.target.value)}
            />
          </Box>
          <Box display="flex" gap={2} justifyContent="center">
            <Button onClick={handleModalSave}>
              Zapisz
            </Button>
            <Button onClick={handleModalClose}>Anuluj</Button>
          </Box>
        </Box>
      </Modal>
    </Grid>
  );
};

export default AdminUsersPage;
